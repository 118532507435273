@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.Item {
  position: relative;
  width: 7.4rem;
  height: 9.4rem;
  align-items: center;
  justify-content: center;

  img {
    width: 7.4rem;
    height: 7.4rem;
    object-fit: cover;
    border-radius: 50%;
    border: 0.3rem solid @grey5;
    padding: 0.2rem;
  }

  &--small {
    height: 6.4rem;
    width: 6.4rem;

    img {
      width: 6.4rem;
      height: 6.4rem;
      border: unset;
      padding: 0;
    }
  }

  &__Name {
    .comment();
    .truncateWithEllipsis();
    height: 1.6rem;
    width: 8rem;
    margin-left: -0.2rem;
    display: block;
    min-width: 0;
    color: @grey1;
    text-align: center;
  }
}
