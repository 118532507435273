.FormContent {
  margin: 1.6rem 0;
  padding: 0 2.4rem;
}

.ComplexField {
  display: flex;
  gap: 0.8rem;

  &__Input {
    flex: 1;
  }

  &__Aside {
    display: flex;
    align-items: end;
  }
}
