@import 'styles/variables';
@import 'styles/typography';

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  min-width: 7.2rem;
  min-height: 4.4rem;
  height: 100%;
  border-radius: 1.2rem;
  padding: 0.2rem 0.4rem;
  color: @grey1;

  button {
    padding: 0;
  }

  span {
    .comment();
    text-align: center;
  }

  &--active {
    color: @white;
    background-color: @black;
    
    i {
      color: @white;
    }
  }
  
  &--last {
    min-width: 4.4rem;
  }
}
