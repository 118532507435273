@import 'styles/mixins.less';
@import 'styles/variables.less';
@import 'styles/typography.less';

.PlayerRankingsDetails {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(
    100vh - @bottomBarHeight - @smallDeeplinkBannerHeight - @headerHeight - @safeAreaBottom
  );

  @media @desktop {
    padding: 2.4rem 0;
    min-height: calc(100vh - @headerHeight);
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 1.6rem;

    @media @desktop {
      padding: 0 0 2.4rem;
    }
  }

  &__List {
    display: flex;
    width: 100%;
  }

  &__Item {
    height: 100%;
    padding: 0 1.6rem;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      border-bottom: 0.1rem solid @transparentLight3;
    }

    @media @desktop {
      padding-left: 0;
    }
  }
}

.LeftSide {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.6rem;

  &__Title {
    .h3();

    color: @grey1;
  }

  &__Date {
    .body();

    color: @grey3;
  }
}

.Avatars {
  display: grid;
  grid-template-rows: repeat(auto-fill, 6.8rem);
  align-items: center;

  @media @desktop {
    grid-template-rows: repeat(auto-fill, 8.8rem);
  }
}

.Info {
  .hideScrollbar();

  width: calc(100% - 8rem);
  display: flex;
  flex-direction: column;
  padding-bottom: 0.7rem;
  overflow-x: auto;
  align-items: flex-start;

  @media @desktop {
    width: calc(100% - 6.4rem);
  }
}

.CustomSection {
  display: flex;
  flex-direction: column;
  
  &__Title {
    .bodyBold();
  }
  
  &__Description {
    .comment();
    color: @grey3;
  }
}
