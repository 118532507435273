@import 'styles/variables.less';

.Col {
  min-width: 0;
  position: relative;

  &--mobile {
    &-1 {
      grid-column: 1;
    }

    &-start-1 {
      grid-column-start: 1;
    }

    &-end-1 {
      grid-column-end: 1;
    }

    &-2 {
      grid-column: 2;
    }

    &-start-2 {
      grid-column-start: 2;
    }

    &-end-2 {
      grid-column-end: 2;
    }

    &-3 {
      grid-column: 3;
    }

    &-start-2 {
      grid-column-start: 3;
    }

    &-end-2 {
      grid-column-end: 3;
    }
  }

  &--desktop {
    &-1 {
      @media @desktop {
        grid-column: 1;
      }
    }

    &-start-1 {
      @media @desktop {
        grid-column-start: 1;
      }
    }

    &-end-1 {
      @media @desktop {
        grid-column-end: 1;
      }
    }

    &-2 {
      @media @desktop {
        grid-column: 2;
      }
    }

    &-start-2 {
      @media @desktop {
        grid-column-start: 2;
      }
    }

    &-end-2 {
      @media @desktop {
        grid-column-end: 2;
      }
    }

    &-3 {
      @media @desktop {
        grid-column: 3;
      }
    }

    &-start-3 {
      @media @desktop {
        grid-column-start: 3;
      }
    }

    &-end-3 {
      @media @desktop {
        grid-column-end: 3;
      }
    }
  }
}
