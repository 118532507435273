@fontsDirectory: "assets/fonts/icons";

@font-face {
  font-family: 'icomoon';
  src: ~"url('@{fontsDirectory}/icomoon.eot')";
  src: ~"url('@{fontsDirectory}/icomoon.eot#iefix') format('embedded-opentype')",
  ~"url('@{fontsDirectory}/icomoon.ttf') format('truetype')",
  ~"url('@{fontsDirectory}/icomoon.woff') format('woff')",
  ~"url('@{fontsDirectory}/icomoon.svg#icomoon') format('svg')";
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon, serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
