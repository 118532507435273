@import 'styles/variables.less';
@import 'styles/mixins.less';

.Opponent {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;

  &__Code {
    .comment();

    color: @normal;
    padding-left: 0.5rem;
    width: 8.8rem;
  }
}
