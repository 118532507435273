@import 'styles/variables.less';
@import 'styles/mixins.less';

.Notifications {
  position: relative;
  z-index: 15;

  :global(.Toastify) {
    position: relative;
    z-index: 10;

    &__toast-container {
      bottom: calc(@bottomBarHeight + @verticalSpacingMobile + @safeAreaBottom);
      left: 50%;
      transform: translate(-50%);
      width: 31.2rem;

      @media @desktop {
        left: unset;
        bottom: 1rem;
        right: 2rem;
        transform: translate(0);
      }
    }

    &__toast {
      margin-bottom: 1rem;
    }
  }

  &--hidden-bottom-bar {
    :global(.Toastify__toast-container) {
      bottom: calc(1rem + @safeAreaBottom);
    }
  }

  &--shown-gallery {
    :global(.Toastify__toast-container) {
      bottom: calc(10rem + @safeAreaBottom);
    }
  }
}

.Toast {
  background: @grey5;
  width: 21.2rem;
  height: 100%;
  border: 0.1rem solid @grey5;

  &__Title {
    .bodyBold();
    color: @white;
  }

  &__Message {
    .comment();
    color: @grey3;
  }
}
