@import 'styles/variables.less';
@import 'styles/typography.less';

.CircularProgressBar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  circle {
    fill: none;
    stroke-linecap: round;
  }
  
  circle:first-of-type {
    stroke: @black;
  }
  
  circle:last-of-type {
    stroke: @normal;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  span {
    .bodyBold();
    
    position: absolute;
  }
}
