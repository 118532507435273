@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.GlobalSearchItem {
  height: 7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  cursor: pointer;

  button {
    width: 8.8rem;
  }

  &__MainSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

.Information {
  display: flex;
  flex-direction: column;
  padding-left: 1.2rem;
  width: 100%;
  max-width: calc(100% - 6rem);
  justify-content: flex-start;

  &__Name {
    .bodyBold();
    .truncateWithEllipsis();

    height: 2.2rem;
    color: @grey1;
  }

  &__Members {
    .comment();
    height: 1.6rem;
    color: @grey4;
  }

  &__Stats {
    height: 1.6rem;
    .comment();

    &--position {
      color: @grey2;
    }

    &--conference {
      color: @grey4;
      padding-left: 0.5rem;
    }

    &--scores {
      color: @grey4;
    }

    &--points {
      color: @grey2;
    }

    &--dot {
      color: @grey4;
      padding: 0 0.5rem;
    }

    &--bracket {
      color: @grey4;
    }
  }
}

.Text {
  .body();
  color: @grey1;
  width: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
