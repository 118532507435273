@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.LinkSet {
  display: flex;
  flex-direction: column;
  background: @grey8;
  border-radius: @borderRadius;
  padding: 0.8rem;
  gap: 0.8rem;
  width: fit-content;
  max-width: 100%;

  &--light {
    background: @grey6;
  }

  &__Header {
    .comment();

    color: @grey3;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  &__Items {
    .hideScrollbar();

    display: flex;
    gap: 0.8rem;
    overflow-x: scroll;
  }
}
