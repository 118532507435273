@import 'styles/variables.less';
@import 'styles/typography.less';

.Scores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 0.4rem;

  .Score {
    .bodyBold();

    color: @grey3;

    &__Team {
      &--winning {
        color: @white;
      }
    }

    &__Separator {
      width: 0.1rem;
      margin: 0 0.5rem;
    }
  }

  .Time {
    .comment();

    display: flex;
    color: @grey3;
    justify-content: center;
    align-items: center;
  }
}
