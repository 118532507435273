@import 'styles/variables.less';
@import 'styles/typography.less';

.Leaderboard {
  
  &__Title {
    .h4();
    color: @white;
    padding: 2.4rem 1.6rem 2rem 1.6rem;
    
    @media @desktop {
      padding: 2.6rem 0;
    }
  }
}
