@import 'styles/variables.less';
@import 'styles/mixins.less';

.Biography {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0 2.4rem 2.4rem;
  
  &__Header {
    .bodyBold();
    color: @white;
  }
  
  &__Content {
    .body();
    text-align: justify;
    color: @grey1;
  }
}

