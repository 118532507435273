@import 'styles/typography.less';

.WithNotifications {
  position: relative;

  &::after {
    @minWidth: 2rem;

    .commentBold();

    content: attr(data-numnotifications);
    position: absolute;
    top: (-1 * @minWidth / 3);
    right: (-1 * @minWidth / 3);
    background-color: @alert;
    border-radius: (@minWidth / 2);
    outline: 0.3rem solid @grey8;
    padding: 0.1rem 0.6rem 0.3rem;
    text-align: center;
  }
}
