@import 'styles/variables.less';
@import 'styles/mixins.less';

.Favorites {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  max-height: 80vh;
  padding-bottom: 3.2rem;
  
  &__Header {
    .bodyBold();
    
    padding: 1.6rem 0 1.6rem 1.6rem;
    color: @grey4;
  }
  
  &__Navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1.6rem;
    width: 100%;
    height: 3.2rem;
    align-items: center;
    
    button {
      padding: 0 !important;
    }
  }
  
  &__List {
    .hideScrollbar();
    overflow-y: scroll;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    width: 100%;
    height: 100%;
    padding: 1.6rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    min-height: 8.6rem;
    
    &--athlete {
      overflow: auto;
      .hideScrollbar();
    }
  }
  
  &__Item {
    width: 7.4rem;
    height: 8.6rem;
    overflow: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    img {
      border: 0.2rem solid @grey5;
      background-color: @black;
      border-radius: 50%;
    }
  }
  
  &__Name {
    .comment();
    .truncateWithEllipsis();
    height: 1.6rem;
    width: 8rem;
    margin-left: -0.2rem;
    display: block;
    min-width: 0;
    color: @grey1;
    text-align: center;
  }
  
  &__Button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    color: @white;
    border: 0.25rem solid @grey7;
  }
}
