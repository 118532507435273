@import 'styles/variables.less';

.Scoreboard {
  display: grid;
  
  &__Header {
    margin: 0 0 1.6rem;
    grid-row: 2;
    padding: 0 @verticalSpacingMobile;
    
    & > div {
      width: 100%;
    }
    
    @media @desktop {
      grid-row: 1;
      margin: 0 0 2.6rem;
      display: flex;
      justify-content: space-between;
      padding: @bigVerticalSpacingDesktop 0 0;
      
      & > div {
        width: @teamSelectWidth;
      }
    }
  }
  
  &__Weekbar {
    margin: 0 0 1.6rem;
    padding: 0 @verticalSpacingMobile;
    
    @media @desktop {
      margin: 0 0 2.6rem;
      padding: 0;
    }
  }
  
  &__Title {
    color: @grey1;
  }
  
  &__Games {
    position: relative;
    min-height: calc(100vh - @headerHeight * 2 - @smallVerticalSpacingDesktop * 3 - 16rem);
    
    @media @desktop {
      min-height: calc(
        100vh - @headerHeight * 2 - @bigVerticalSpacingDesktop * 2 - @smallVerticalSpacingDesktop -
        9.6rem
      );
      grid-row: 3;
    }
  }
}
