@import 'styles/variables.less';

.SearchContainer {
  display: flex;
  height: 100%;
  background-color: @black;
  flex-direction: column;
  max-width: 60rem;
  margin: auto;
  justify-content: center;
}
