@import 'styles/variables.less';

.LoaderWrapper {
  position: relative;
  height: 45rem;
  margin-top: 2.4rem;
}

.EmptyStateWrapper {
  height: 35rem;
  margin: 1.6rem 1.6rem 0;

  @media @desktop {
    margin: 2.4rem 0;
  }
}
