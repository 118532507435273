@import 'styles/variables.less';
@import 'styles/typography.less';

.DeeplinkSmallBanner {
  position: fixed;
  left: 0;
  bottom: calc(@bottomBarHeight + @safeAreaBottom);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.2rem 1.6rem;
  background-color: @black;
  border-bottom: 0.1rem solid @transparentLight3;
  transform: translateY(@transformMobileBottomBar) translateZ(0);

  &--bottom-bar-hidden {
    bottom: @safeAreaBottom;
    transform: translateY(0);
  }

  &__Text {
    .commentBold();

    color: @grey1;
  }
}
