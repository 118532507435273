@import 'styles/variables.less';
@import 'styles/typography.less';

.Headline {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.4rem;
  padding: 1.2rem 0;
  cursor: pointer;

  &__Source {
    flex: 0 0 auto;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    margin-right: 1.2rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__Text {
    .body();

    flex: 1 1 auto;
    color: @grey1;
  }

  &__Icon {
    width: fit-content;
    color: @grey4;
  }
}
