@import 'styles/variables.less';

.Wrapper {
  position: relative;
  height: calc(@mobileProfileThumbnailHeight + 17.5rem + @smallVerticalSpacingDesktop);

  @media @desktop {
    height: calc(@desktopProfileThumbnailHeight + 10.4rem + @smallVerticalSpacingDesktop);
  }
}
