@import 'styles/variables.less';
@import 'styles/mixins.less';

.EditForm {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media @desktop {
    padding-top: 0;
    height: calc(100% - 13.7rem);
  }
  
  & > div {
    margin-bottom: 0;
  }
  
  &__Label {
    .bodyBold();
    height: 3.2rem;
    color: @white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 2.4rem 0;
  }
}

.SubmitButtonWrapper {
  height: auto;
  flex: 1 1 auto;
  display: flex;
  align-items: end;
  width: 100%;
}
