@import 'styles/variables.less';
@import 'styles/mixins.less';

.SelectSlider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4rem;
  border-radius: 4.8rem;
  background: @grey7;
  margin: 0;
  max-width: 28rem;
  width: 100%;

  @media @desktop {
    height: 3.2rem;
  }

  &--full-width {
    max-width: unset;
  }

  &__Item {
    .bodyBold();

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 9.2rem;
    background-color: @grey7;
    color: @grey3;

    &--selected {
      color: @white;
      background-color: @grey5;
    }
  }
}
