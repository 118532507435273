@import 'styles/variables';

.CommentsWrapper {
  border-radius: @borderRadius;
  background-color: unset;

  @media @desktop {
    background-color: @grey8;
  }

  flex: 1;
  display: flex;
  flex-direction: column;
}

.CommentsList {
  padding: 0.8rem 1.6rem;

  @media @desktop {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    padding: 1.6rem 1.6rem 0;
  }
}

.CommentCreate {
  position: sticky;
  bottom: 0;
  z-index: 5;
  padding: 0.8rem 0 calc(1.2rem + @safeAreaBottom);
  background-color: @black;

  @media @desktop {
    padding: 1.6rem 0 calc(1.6rem + @safeAreaBottom);
  }
}

:global(.is-keyboard-open.ios-platform) {
  .CommentCreate {
    padding: 0.8rem 0 calc(0.8rem + @safeAreaBottom);

    @media @desktop {
      padding: 1.6rem 0 calc(1.6rem + @safeAreaBottom);
    }
  }
}

:global(.ios-platform) {
  .CommentCreate {
    padding: 0.8rem 0 @safeAreaBottom;
    @media @desktop {
      padding: 1.6rem 0 calc(1.6rem + @safeAreaBottom);
    }
  }
}

.CommentLoaderWrapper {
  position: relative;
  min-height: 10rem;
}
