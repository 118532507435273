@import 'styles/typography.less';
@import 'styles/variables.less';

.PlayerProfileHeader {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  justify-content: center;

  &--followed {
    .PlayerProfileHeader {
      &__Name {
        @media @desktop {
          margin-right: 0.8rem;
        }
      }
    }
  }

  &__Name {
    .bodyBold();

    color: @grey1;

    @media @desktop {
      margin-right: 2rem;
    }
  }
}
