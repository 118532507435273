@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.GroupPostSettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
  
  @media @desktop {
    gap: 0;
  }
  
  &__Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: @grey8;
    border-radius: @borderRadius;
    height: 4.8rem;
    padding-right: 1.2rem;
    
    li {
      min-width: 16rem ;
    }
    
    > div {
      height: 3.2rem;
    }
  }
}
