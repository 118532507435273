@import 'styles/variables.less';
@import 'styles/typography.less';

.ag-theme-leaderboard {
  
  .ag-root {
    @media @desktop {
      overflow: visible !important; // rewrite js styles
    }
  }
  
  .ag-body-viewport {
    @media @desktop {
      padding-bottom: 8rem;
    }
  }
  
  .ag-leaderboard-user-header.ag-header-cell {
    padding-left: 0;
  }
  
  .ag-body-vertical-scroll,
  .ag-body-vertical-scroll-viewport {
    display: none;
  }
  
  .ag-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ag-floating-bottom {
    .body();
    
    overflow: unset !important; // rewrite js styles
    font-weight: 400;
    
    @media @desktop {
      position: absolute;
      bottom: 2.4rem;
      border: 0.4rem solid @black;
      border-radius: 1.2rem;
      left: -1.2rem;
      box-sizing: content-box;
      width: calc(100% + 1.6rem);
      background: @grey7;
    }
    
    .ag-row {
      height: 100% !important;
    }
    
    .ag-floating-bottom-container {
      @media @desktop {
        width: 100% !important; // rewrite js styles
      }
    }
    
    .ag-pinned-left-floating-bottom {
      @media @desktop {
        border-top-left-radius: 1.2rem;
        border-bottom-left-radius: 1.2rem;
      }
      
      .ag-cell:first-of-type {
        padding-left: 1.6rem;
        width: auto !important; // rewrite js styles
      }
    }
    
    .ag-floating-bottom-viewport {
      @media @desktop {
        border-top-right-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
        padding-left: 0.8rem;
      }
    }
    
    .ag-row {
      background: @grey7;
    }
    
    .ag-cell {
      color: @grey3;
      
      @media @desktop {
        font-size: 1.5rem;
      }
      
      &--highlighted {
        color: @grey1;
      }
    }
  }
}
