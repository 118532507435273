@import 'styles/typography';
@import 'styles/variables';

.ReportSubmittion {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.6rem 2.4rem;

  &--modal-theme {
    .ReportSubmittion__Footer {
      padding-bottom: 0;
      margin-top: 0;

      button {
        position: absolute;
        right: 2.4rem;
        bottom: 2.4rem;
        width: fit-content;
        margin-left: auto;
      }
    }
  }

  &__Header {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-bottom: 2.5rem;

    i {
      color: @grey1;
    }
  }

  &__ReportName {
    .bodyBold();

    color: @grey1;
  }

  &__Body {
    color: @grey2;
  }

  &__Footer {
    width: 100%;
    margin-top: auto;
    padding-bottom: 3rem;

    button {
      width: 100%;
    }
  }
}
