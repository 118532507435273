@import 'styles/typography.less';
@import 'styles/variables.less';

.TeamStats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.6rem 0 2.4rem;

  .Heading {
    margin-bottom: 1.6rem;
    padding: 0 1.6rem;

    @media @desktop {
      margin-bottom: 2.4rem;
      padding: 0;
    }
  }

  .Filters {
    padding: 0 1.6rem;
    width: 100%;

    @media @desktop {
      padding: 0;
      width: auto;
    }
  }

  .SectionHeader {
    .bodyBold();

    padding: 2.4rem 0 0.8rem 1.6rem;

    @media @desktop {
      padding: 2.4rem 0 1.6rem;
    }
  }
}
