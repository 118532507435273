@import 'styles/variables';
@import 'styles/typography';
@import 'styles/mixins';

.MediaSet {
  display: flex;
  flex-direction: column;
  background: @grey8;
  border-radius: @borderRadius;
  max-width: 100%;
  padding: 0.8rem;
  gap: 0.8rem;
  margin-top: 0.8rem;

  &--light {
    background: @grey6;
  }

  &__Header {
    .comment();

    color: @grey3;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  &__Items {
    .hideScrollbar();

    display: flex;
    gap: 0.8rem;
    overflow-x: scroll;
    width: 100%;

    button {
      flex-shrink: 0;
    }
  }
}

.MediaSetMore {
  position: relative;

  img {
    opacity: 0.3;
  }

  &__Remaining {
    .bodyBold();

    color: @white;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__Video {
    position: relative;
  }
}
