@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ModalWrapper {
  .hideScrollbar();

  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  background-color: @black;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  @media @desktop {
    height: 100vh;
  }

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: calc(@safeAreaTop + 1rem) 1.6rem 1rem 1.6rem;

    @media @desktop {
      justify-content: flex-end;
      height: 6rem;
      padding: 1.6rem 2.4rem 0.8rem;
    }
  }

  &__Content {
    .hideScrollbar();

    z-index: 3;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    max-height: calc(100% - (@safeAreaTop + @safeAreaBottom + 15.2rem));
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-top: 0.1rem solid @transparentLight3;
    border-bottom: 0.1rem solid @transparentLight3;

    @media @desktop {
      border: none;
      overflow: scroll;
      max-height: calc(100vh - 16.4rem);
    }
  }

  &__Footer {
    width: 100%;
    height: calc(10.4rem + @safeAreaBottom);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: @safeAreaBottom;

    .Counter {
      .comment();

      height: 1.6rem;
      padding-top: 1.6rem;
      color: @grey3;
    }

    .ActionBar {
      display: grid;
      grid-template-columns: 20% 60% 20%;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 1.2rem 1.6rem;

      @media @desktop {
        padding: 1.6rem 2.4rem;
      }

      &__User {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        grid-column: 1;

        .Avatar {
          min-height: 4rem;
          min-width: 4rem;
        }

        .RealName {
          .bodyBold();
          .truncateWithEllipsis();

          padding-left: 1.2rem;
          color: @grey1;
        }
      }

      &__SmallImages {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 2;
      }

      &__Actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-column: 3;

        @media @desktop {
          button[data-id='zoom'] {
            margin: 0 1rem;
          }
        }
      }
    }
  }

  .FileName {
    .comment();

    display: flex;
    align-items: center;
    width: calc(100% - 2.8rem);
    color: @grey3;

    span {
      .truncateWithEllipsis();

      display: block;
      max-width: 60%;
      padding-left: 0.2rem;
    }

    @media @desktop {
      width: 100%;
      justify-content: center;

      span {
        max-width: none;
        overflow: unset;
      }
    }
  }
}

.Divider {
  height: 2.4rem;
  width: 0.1rem;
  margin: 0 1.2rem;
  background-color: @transparentLight3;

  @media @desktop {
    margin: 0 1rem;
  }
}

.NavigationLeftButton,
.NavigationRightButton {
  position: absolute;
  display: flex;
  z-index: 3;
  top: 50%;
  left: 2rem;

  &--hidden {
    display: none;
  }
}

.Overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.RootSlider {
  max-width: 6.5rem;
}

.LoaderWrapper {
  position: relative;
  min-width: 3.2rem;
  min-height: 3.2rem;
}
