@import 'styles/variables';
@import 'styles/typography';

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  button {
    padding: 0;
  }

  span {
    .comment();

    text-align: center;
    color: @grey3;
  }

  &--active {
    span {
      .commentBold();

      color: @grey1;
    }

    i {
      color: @grey1;
    }
  }
}
