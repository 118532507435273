@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.FieldWrapper {
  position: relative;
  margin: @inputVerticalSpacingMobile 0;

  @media @desktop {
    margin: 0 0 @InputVerticalSpacingDesktop 0;
  }

  &__Label {
    margin: 0 0 0.8rem;
  }

  &__ComplexField {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;

    @media @desktop {
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 0;
      gap: 0.8rem;
    }

    &__Aside {
      display: flex;
      align-items: center;
      order: 3;
      margin-top: 2.5rem;

      & > button {
        width: 100%;
      }

      @media @desktop {
        order: unset;
        margin-top: 0;

        & > button {
          width: auto;
        }
      }
    }
  }

  &__Field {
    position: relative;
    display: flex;
    order: 1;
    flex: 1;

    @media @desktop {
      order: unset;
    }

    &__LeftIcon,
    &__RightIcon,
    &__TextCounter {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &__LeftIcon {
      left: 0;
      width: @inputIconLeftWidth;
      background-color: @grey7;
      color: @grey3;
      border-radius: @inputBorderRadius 0 0 @inputBorderRadius;
      border: 0.1rem solid transparent;
      height: calc(100% - 0.2rem);
      margin-left: 0.1rem;
      margin-top: 0.1rem;
    }

    &__RightIcon {
      right: 0;
      width: @inputIconRightWidth;
      color: @grey3;
    }

    &__TextCounter {
      .comment();

      color: @grey5;
      right: 1.6rem;
    }

    &--invalid &__RightIcon {
      color: @alert;
    }
  }

  &__HelperText {
    order: 2;
    width: 100%;
    margin-top: 0.8rem;

    @media @desktop {
      order: unset;
      margin-top: 0;
    }
  }
}
