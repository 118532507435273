@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Footer {
  display: flex;
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;
  justify-content: flex-end;

  > button {
    width: 14.4rem;
  }
}
