@import 'styles/variables.less';
@import 'styles/typography.less';

.GameHeader {
  .comment();

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 1.2rem 0.3rem;
  border-bottom: 0.1rem solid @black;
  background-color: @grey8;

  &__Time {
    display: flex;
    gap: 0.4rem;
  }

  &__Status {
    color: @grey4;
  }

  &__Date {
    .commentBold();

    color: @grey1;
  }

  &__Live {
    .commentBold();

    padding: 0.1rem 0.4rem;
    border-radius: 0.4rem;
    text-transform: uppercase;
    background-color: @alert;
  }
}
