@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Preview {
  .hideScrollbar();

  display: flex;
  flex-direction: row;
  max-width: 48rem;
  height: 4rem;
  overflow-x: auto;
  overflow-y: hidden;

  &__Item {
    min-width: 4rem;
    min-height: 4rem;
    margin: 0 0.4rem;
    opacity: 0.4;
    border: 0.1rem solid black;
    border-radius: 0.8rem;
    overflow: unset;
    position: relative;

    &--selected {
      opacity: 1;
      border: 0.1rem solid @normal;
    }
  }
}
