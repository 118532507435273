@import 'styles/variables.less';

.Wrapper {
  &--processing {
    filter: blur(5px); // required for blur
  }
}

&:global(.is-keyboard-open) {
  .Wrapper {
    &--keyboard {
      margin-bottom: calc(@keyboardHeight - var(--cap-safe-area-bottom));
    }
  }
}

&:global(.is-keyboard-open.android-platform) {
  .Wrapper {
    &--keyboard {
      margin-bottom: @keyboardHeight;
    }
  }
}
