@import 'styles/variables.less';
@import 'styles/mixins.less';

.ContentEditable {
  .contentEditable();

  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - @createPostHeightWithoutEditor);

  & > p:last-of-type {
    margin-bottom: 0;
  }

  a {
    .truncateLink();
  }

  &--bottom-sheet-theme {
    min-height: 7.2rem;
    background-color: @grey7;
    padding: 1.6rem 1.2rem;
    border-radius: 0.8rem;
  }

  &--mobile {
    max-height: @mobileEditorMaxHeight;
  }

  &--full-height {
    height: calc(100vh - @createPostHeightWithoutEditor);
  }

  &--with-initial-height {
    min-height: @minTextareaHeight;
  }

  &--with-poll {
    min-height: @editorWithPollsMinHeight;
    max-height: calc(100vh - @editorWithPollsMaxHeight);
  }

  &--for-group-posts {
    min-height: @minTextareaHeight;
  }
}