@import 'styles/variables';

.FloatingButton {
  position: fixed;
  right: 1.2rem;
  bottom: calc(1.6rem + @bottomBarHeight + @safeAreaBottom);
  z-index: @floatingButtonZIndex;
  transform: translateY(@transformMobileFixedElement) translateZ(0);
  transition: transform @scrollSmoothDuration;

  &--has-smart-banner {
    bottom: calc(1.6rem + @bottomBarHeight + @smallDeeplinkBannerHeight);
  }

  button {
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    background-color: @normal;
    color: @white;
  }
}
