@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.LinkSetItem {
  display: grid;
  grid-template-columns: auto 4.8rem;
  grid-template-rows: 1.6rem 3.6rem;
  column-gap: 0.8rem;
  row-gap: 0.4rem;
  border: solid 0.1rem @grey5;
  border-radius: @borderRadius;
  padding: 0.6rem 1.2rem;
  text-decoration: none;
  color: @grey3;
  width: 23.2rem;
  height: 7rem;
  flex-shrink: 0;

  &--no-image {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .LinkSetItem__Title {
      max-width: 100%;
    }

    .LinkSetItem__Source {
      max-width: 20.8rem;
    }
  }

  &__Source {
    display: flex;
    gap: 0.4rem;
    max-width: 15.2rem;
    align-items: center;

    .LinkSetItemSource {
      &__Name {
        .comment();
        .truncateWithEllipsis();

        color: @grey3;
      }

      &__Logo {
        border-radius: 0.4rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  &__Title {
    .commentBold();
    .lineClamp(2);

    max-height: 3rem;
    max-width: 15.2rem;
    grid-column: 1;
    color: @grey1;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
  }

  &__Image {
    grid-column: 2;
    grid-row: 1 / span 2;
    width: 4.8rem;
    height: 4.8rem;
    object-fit: cover;
    border-radius: @borderRadius;
    place-self: center;
  }
}
