@import 'styles/variables.less';
@import 'styles/typography.less';

.Filters {
  display: grid;

  @media @desktop {
    grid-template-columns: @positionSelectWidth @teamSelectWidth;
    gap: 1.2rem;
  }

  &__TeamSelect {
    & > div {
      width: 100%;
    }
  }
}

.FiltersMobile {
  display: grid;

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(@safeAreaTop + 5.2rem);
    padding: calc(@safeAreaTop + 1rem) 1.6rem 1rem;
    border-bottom: 0.1rem solid @transparentLight3;
    background-color: @grey6;
  }

  &Header__Title {
    .bodyBold();

    color: @grey1;
  }

  &__Content {
    .bodyBold();

    padding: 2.1rem 1.6rem;

    & > div:nth-child(even) {
      margin-bottom: 1.6rem;
      width: 100%;
    }
  }

  &Content__Type {
    .bodyBold();

    color: @grey3;
    margin-bottom: 0.5rem;
  }

  &__Buttons {
    position: fixed;
    width: 100%;
    bottom: 0;
    border-top: 0.1rem solid @grey6;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.8rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.6rem calc(@safeAreaBottom + 0.8rem);
  }
}
