@import 'styles/variables.less';
@import 'styles/typography.less';

.GameEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1.6rem 0;
  min-height: calc(100vh - @bottomBarHeight - @smallDeeplinkBannerHeight);

  @media @desktop {
    min-height: calc(100vh - @headerHeight);
  }

  &__Image {
    max-width: 74.8rem;
    width: 100%;
    height: 44.4rem;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__Title {
    .h3();

    width: 100%;
    padding-top: 5.6rem;
    padding-bottom: 0.8rem;
    text-align: center;
    color: @grey3;

    @media @desktop {
      padding-top: 3.2rem;
    }
  }

  &__Text {
    .body();

    width: 100%;
    text-align: center;
    color: @grey3;
  }
}

:global(.ios-platform),
:global(.android-platform) {
  .GameEmpty {
    min-height: calc(100vh - @bottomBarHeight - @safeAreaTop - @safeAreaBottom);
  }
}
