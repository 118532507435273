@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/icon-variables.less';

.Scores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .Score {
    .bodyBold();

    @media @desktop {
      .h3();
    }

    color: @grey3;
    position: relative;

    &__Team {
      &--winning {
        color: @white;
      }

      &--win {
        position: relative;

        &::after {
          position: absolute;
          font-family: @icomoon-font-family;
          font-size: 2rem;
          content: @icon-marker-collapsed;
          top: -1.5rem;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        }
      }
    }

    &__Separator {
      width: 0.1rem;
      margin: 0 0.5rem;
    }
  }

  .Time {
    .comment();

    display: flex;
    color: @grey3;
    justify-content: center;
    align-items: center;
  }
}
