@import 'styles/variables.less';
@import 'styles/typography.less';

.PushNotificationsSettings {
  @media @desktop {
    width: 50%;
    padding-left: 1.6rem;
  }
}

.SettingsCard {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.2rem;
  border: 0.1rem solid @grey7;
  border-radius: 0.8rem;

  &__Content {
    flex: 1;
    padding-right: 1.2rem;
  }

  &__Title {
    .commentBold();

    padding-bottom: 0.2rem;
    color: @grey1;
  }

  &__Text {
    .comment();

    color: @grey3;
  }
}

.SettingsToggles {
  &__Title {
    .h4();

    padding: 1.6rem 0 0.8rem;
    color: @grey1;
  }

  &__Wrapper {
    position: relative;
  }

  &__Item {
    display: flex;
    align-items: center;
    padding: 0.4rem 0;
  }

  &__Text {
    .body();

    color: @grey3;
    margin-right: auto;
  }
}
