@import 'styles/variables.less';
@import 'styles/mixins.less';

.TeamRow {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 0 1.6rem;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
  gap: 0.8rem;
  border-radius: 0.4rem;

  @media @desktop {
    padding: 0 1rem;
  }

  &--home {
    background-color: var(--home-team-primary-color);
  }

  &--visitor {
    background-color: var(--visitors-team-primary-color);
  }

  &__TeamSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
  }

  .TeamName {
    padding-left: 1rem;
  }

  .Gradient {
    position: absolute;
    top: 0;
    left: 40%;
    width: 60%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, @black 100%);
  }
}
