@import 'styles/variables.less';
@import 'styles/mixins.less';

.Input {
  .basicInput();
  
  &--with-left-icon {
    padding-left: @inputLeftIconPadding;
  }

  &--with-right-icon {
    padding-right: @inputRightIconPadding;
  }

  &--with-text-count {
    padding-right: @inputWithCounterPadding;
  }

  &--with-max-length-count {
    padding-right: @inputWithMaxLengthPadding;
  }

  &--with-right-icon-and-count {
    padding-right: @inputWithMaxLengthAndRightIconPadding;
  }

  &--invalid {
    &,
    &:focus,
    &:hover {
      border: 0.1rem solid @alert;
    }
  }
}

.Counter {
  .comment();
  position: absolute;
  right: 1.6rem;
  top: 50%;
  color: @grey5;
  transform: translateY(-50%);

  .Input--with-right-icon + & {
    right: 4.6rem;
  }
}
