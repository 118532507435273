@import 'styles/variables.less';
@import 'styles/mixins.less';

.PlayerControl {
  display: flex;
  align-items: center;
  border-radius: 14.7rem;
  &__InnerWrapper {
    display: flex;
    background-color: @black;
    border-radius: 14.7rem;
    width: 100%;
    padding-left: 1.2rem;
  }
}
