@import 'styles/variables';

.ImagesCarouselPreview {
  position: relative;

  &__Viewport {
    overflow: hidden;
  }

  &__Container {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  &__Slider:hover .ImagesCarouselPreview__Arrows {
    display: block;
  }

  &__Slide,
  &__SlideAbsolute {
    position: relative;
    flex: 0 0 100%;
    min-width: 0;
    border-radius: 1.6rem;
    overflow: hidden;
  }

  &__SlideAbsolute {
    padding-bottom: 100%;
  }

  &__Button,
  &__ButtonAbsolute {
    width: 100%;
    border-radius: 1.6rem;
    overflow: hidden;
  }

  &__ButtonAbsolute {
    position: relative;
    padding-bottom: 100%;
  }

  &__Image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin-left: 0;
    object-fit: cover;
  }

  &__Arrows {
    display: none;
  }

  &__Dots {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0 0;

    @media @desktop {
      padding: 0;
      margin-top: 0.8rem;
    }
  }

  &__SlideVideo {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    margin-left: 0;
    border-radius: 1.6rem;
    overflow: hidden;

    video {
      object-fit: cover;

      &:fullscreen {
        object-fit: contain;
      }
    }
  }
}
