@import 'styles/typography';
@import 'styles/variables';

.Editor {
  &--nested-list-item {
    list-style: none;
  }
}

.EditorText {
  &--bold {
    font-weight: bold;
  }

  &--italic {
    font-style: italic;
  }

  &--strikethrough {
    text-decoration: line-through;
  }

  &--underline {
    text-decoration: underline;
  }

  &--underline-strikethrough {
    text-decoration: underline line-through;
  }
}

.EditorQuote {
  .body();

  margin: 0 0 0.8rem 2rem;
  color: @grey1;
  background: @transparentGradient;
  padding: 0.8rem;
  border-radius: @borderRadius;
  max-width: fit-content;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: url('assets/images/svg/quotes.svg');
    margin-right: 1rem;
  }
}
