@import 'styles/variables.less';
@import 'styles/typography.less';

.GameDetails {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: calc(100vh - 6.2rem - @bottomBarHeight - @safeAreaBottom);
  padding-bottom: @safeAreaBottom;

  @media @desktop {
    min-height: calc(100vh - @headerHeight - @tabsHeight - 4.8rem);
    margin-bottom: 0;
  }

  &--smart-banner {
    min-height: calc(
      100vh - 6.2rem - @bottomBarHeight - @safeAreaBottom - @smallDeeplinkBannerHeight
    );
  }

  &--empty {
    @media @desktop {
      padding-top: 2.4rem;
    }
  }
}
