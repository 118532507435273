@import 'styles/variables.less';

.Tooltip {
  display: flex;
  width: fit-content;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  z-index: @tooltipZIndex;

  &--no-shadow {
    box-shadow: none;
  }
}
