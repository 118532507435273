@import 'styles/variables.less';
@import 'styles/mixins.less';

&:global(.is-keyboard-open) {
  .Wrapper {
    &--keyboard {
      .hideScrollbar();
      overflow: auto;
      max-height: 100vh;
      height: 100%;
      padding-bottom: calc(@keyboardHeight - var(--cap-safe-area-bottom));
      padding-top: 4rem;
    }
  }
}

&:global(.is-keyboard-open.android-platform) {
  .Wrapper {
    &--keyboard {
      .hideScrollbar();
      overflow: auto;
      
      height: 100%;
      max-height: 100vh;
      padding-bottom: @keyboardHeight;
      padding-top: 4rem;
    }
  }
}

.SocialLinkHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 2.4rem;
  height: 3.6rem;

  &__Title {
    .h4();
    color: @white;
    flex-grow: 1;
    text-align: center;
    padding-left: 7rem;
  }

  &__Button {
    color: @alert;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    width: 7rem;
  }

  &__ButtonTitle {
    .commentBold();
  }
}

.ClearButton {
  color: @alert;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 7rem;
  
  &__ButtonTitle {
    .commentBold();
  }
}
