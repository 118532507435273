@import 'styles/variables.less';
@import 'styles/typography.less';

.HeaderRight {
  display: flex;
  gap: 0.5rem;
}

.AvatarWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  color: @grey1;
  cursor: pointer;
  
  img, i {
    z-index: 2;
  }
  
  i {
    color: @grey1;
  }
  
  &__Points {
    padding-left: 3.6rem;
    padding-right: 1rem;
    height: 3.2rem;
    border-radius: 3.6rem;
    width: fit-content;
    margin-left: -3.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: @grey8;
  }
  
  .Rating {
    .commentBold();
  }
}
