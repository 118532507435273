@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Hint {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-right: 2.4rem;
  padding-left: 2.4rem;

  &__Header {
    .h4();

    display: flex;
    justify-content: center;
    align-content: flex-start;
    padding-bottom: 2.3rem;
    border-bottom: 0.1rem solid @grey7;
    color: @grey1;
  }

  &__Message {
    .body();

    padding-bottom: 1rem;
    padding-top: 1.6rem;
    color: @grey3;
    text-align: justify;
  }

  &__Categories {
    display: flex;
    flex-direction: column;
    padding-left: 0.8rem;
  }
}

.Item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &__Title {
    .bodyBold();

    height: 2.4rem;
    padding-right: 0.8rem;
    text-align: start;
    color: @grey1;
  }

  &__Description {
    .body();

    color: @grey3;
  }
}
