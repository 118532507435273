@import 'styles/variables';

.Container {
  width: 100vw;
  height: 100vh;
  background-color: @black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SplashScreen {
  width: 9.6rem;
  height: 11.1rem;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);

  &__Gradient {
    animation: rotation 1.4s linear infinite;
    transform-origin: center;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
