@import 'styles/variables.less';
@import 'styles/typography.less';

.ItemLine {
  width: 100%;

  @media @desktop {
    padding: 0 0.4rem;
    border-radius: 0.8rem;
  }

  i {
    color: @grey3;
  }

  &__Button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.2rem 0;
  }

  &__Title {
    color: @grey1;
  }

  &__Arrow {
    display: block;
    margin-left: auto;
  }

  &--settings-theme {
    .ItemLine__Title {
      .button();

      padding-left: 0.8rem;
    }
  }

  &--reports-theme {
    .ItemLine__Button {
      .body();
    }
  }

  &--without-icon {
    .ItemLine__Title {
      @media @desktop {
        padding-left: 0;
      }
    }
  }

  &--active {
    @media @desktop {
      background-color: @grey8;
    }
  }
}
