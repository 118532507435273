@import 'styles/variables.less';
@import 'styles/mixins.less';

.Point {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 5.4rem;
  width: 10vw;
  overflow-x: hidden;

  &:last-of-type {
    min-width: auto;
    width: unset;
    padding-right: 1.6rem;
  }

  @media @desktop {
    min-width: 5.2rem;
    width: unset;

    &:last-of-type {
      padding-right: 0;
    }
  }

  &--special {
    :first-child {
      .h3();

      min-width: 4.9rem;
      width: 11.3vw;
      background: @gradient;
      background-clip: text;
      color: transparent;

      @media @desktop {
        width: unset;
      }
    }
  }

  &__Value {
    .h4();

    text-align: left;
    color: @grey1;
    font-weight: 700;

    &--positive {
      color: @success;
    }

    &--negative {
      color: @alert;
    }
  }

  &__Label {
    .comment();

    color: @grey3;
  }
}
