@import 'styles/variables.less';
@import 'styles/mixins.less';
@import 'styles/typography.less';

@transparentColor: rgba(255, 255, 255, 0);

.RichTextContent {
  position: relative;
  overflow: hidden;

  &__Button {
    display: flex;
    width: 100%;
  }

  &--shorter {
    position: static;
  }
}

.RichTextContentButton {
  .bodyBold();

  position: absolute;
  bottom: 0;
  right: 0;
  width: 11.2rem;
  color: @grey1;
  text-align: right;
  background: linear-gradient(90deg, @transparentColor 0%, @black 42%);

  &--grey-theme {
    background: linear-gradient(90deg, @transparentColor 0%, #222628 42%);
  }

  &--light-grey-theme {
    background: linear-gradient(90deg, @transparentColor 0%, @grey6 42%);
  }

  &--dark-grey-theme {
    background: linear-gradient(90deg, @transparentColor 0%, @grey8 42%);
  }

  &--highlighted-theme {
    background: linear-gradient(90deg, @transparentColor 0%, #14212d 42%);

    @media @desktop {
      background: linear-gradient(90deg, @transparentColor 0%, #222f3b 42%);
    }
  }

  &--toggled {
    position: static;
    width: unset;
    margin-top: 0.8rem;
    margin-left: auto;
    background: none;
  }

  &--shorter:not(&--toggled) {
    bottom: 0.4rem;
  }
}
