@import 'styles/typography';
@import 'styles/variables';

.Poll {
  &__Wrapper {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }

  &__Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      .comment();
      
      color: @grey4;
      line-height: 3.2rem;
    }
    
    & > div {
      display: flex;
      align-items: center;
    }
    
    &__Divider {
      background: @grey4;
      width: 0.4rem;
      height: 0.4rem;
      margin: 0 0.8rem;
      border-radius: 50%;
    }
  }
  
  &__AddComment {
    padding: 1.6rem 0 0.8rem 0;
  }
}
