@import 'styles/variables.less';
@import 'styles/typography.less';

.Helper {
  .comment();

  margin: 0;
  color: @grey4;
  
  &--error,
  &--weak {
    color: @alert;
  }

  &--medium {
    color: @gold;
  }

  &--strong {
    color: @success;
  }

  &--very-strong {
    color: @success;
  }

  &--regular {
    color: @grey5;
  }
}
