@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.BottomSheetSelect {
  .hideScrollbar();

  padding: 2.6rem 2.4rem calc(2.4rem + @safeAreaBottom);
  max-height: 80vh;
  overflow-y: auto;

  &__List {
    background: @grey7;
    border-radius: 1.6rem;
  }
}