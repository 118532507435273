@import 'styles/variables.less';
@import 'styles/mixins.less';

.PlayerProfileInfo {
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;
  
  &__Wrapper {
    display: grid;
    grid-template-columns: 1fr 16rem 1fr;
    margin-top: -6.8rem;
    justify-content: space-between;
    align-items: end;
    
    @media @desktop {
      margin-top: -8.8rem;
    }
  }
  
  &__Statistics {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.8rem;
  }
}


.Separator {
  .body();
  display: flex;
  color: @grey3;
  
  @media @desktop {
    padding: 0 1.6rem;
  }
}

.LeftSide {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  
  @media @desktop {
    flex-direction: row;
    gap: 0;
    .body();
    justify-content: flex-start;
  }
  
  &__Winner {
    .commentBold();
    
    @media @desktop {
      .bodyBold();
    }
  }
  
  &__Results {
    .comment();
    color: @normal;
    padding: 0 0.4rem;
    display: flex;
    gap: 0.4rem;
    @media @desktop {
      .body();
    }
  }
  
  &__Scores {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  &__Date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: @grey3;
    .comment();
    
    @media @desktop {
      .body();
    }
  }
}

.AvatarWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  &__Avatar {
    width: 15.2rem;
    border: solid 0.4rem @black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    @media @desktop {
      margin-bottom: 1.6rem;
      border: solid 0.8rem @black;
    }
  }
  
  &__Emoji {
    position: absolute;
    bottom: 0.8rem;
    left: 0.8rem;
    height: 3.2rem;
    width: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 2;
    
    img {
      width: 100%;
      height: 100%;
      margin: 0;
      object-fit: contain;
      object-position: center;
    }
  }
}

.RightSide {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  
  @media @desktop {
    flex-direction: row;
    gap: 0;
    justify-content: flex-end;
  }
  
  &__Fantasy {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  
  &__Info {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  
  &__Position,
  &__Points {
    .comment();
    color: @grey3;
    
    @media @desktop {
      .body();
    }
  }
  
  &__Jersey,
  &__Points > span {
    .commentBold();
    padding-right: 1rem;
    
    @media @desktop {
      .bodyBold();
    }
  }
  
  &__Jersey {
    color: @grey3;
    
    @media @desktop {
      color: @white;
    }
  }
  
  &__Points > span {
    color: @white;
  }
  
  &__Logo {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.NameSection {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &__Buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: fit-content;
    gap: 1.6rem;
  }
  
  &__Name {
    .truncateWithEllipsis();
    .h4();
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    margin: 0.4rem 0 1.2rem;
    font-weight: 700;
    color: @normal;
    
    i {
      color: @grey3;
    }
  }
}


.Separator {
  .body();
  padding: 0 1.6rem;
  display: none;
  color: @grey3;

  @media @desktop {
    display: flex;
  }
}
