@import 'styles/variables.less';

.LoaderWrapper {
  position: relative;
  height: calc(@mobileProfileThumbnailHeight + 10.8rem);

  @media @desktop {
    height: calc(@desktopProfileThumbnailHeight + 10.4rem + @smallVerticalSpacingDesktop);
  }
}

.PlayerProfileThumbnail {
  width: 100%;
  height: @mobileProfileThumbnailHeight;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  &--small {
    height: @mobileProfileThumbnailHeight;
  }

  @media @desktop {
    height: @desktopProfileThumbnailHeight;
  }
}
