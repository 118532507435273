@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.ScoreboardPlayer {
  position: relative;
  padding: 0.4rem 0.6rem 0.4rem 0.4rem;

  @media @desktop {
    display: grid;
    min-width: 25rem;
    grid-template-columns: 3.2rem 1fr;
    grid-column-gap: 0.8rem;
    padding: 0.8rem 0.8rem 0.8rem 0;
  }

  &__Avatar {
    position: relative;
    grid-column: 1;
  }

  &__Info {
    grid-column: 2;
  }

  .PlayerInfo {
    display: flex;
    flex-direction: column;

    &__Row {
      display: flex;
      justify-self: center;
    }
  }
}

.PlayerEntry {
  .comment();

  display: inline-block;

  & + &::before {
    content: ', ';
    color: @grey2;
  }

  &__Value {
    color: @grey2;

    &:after {
      content: ' ';
    }
  }

  &--name &__Value {
    .body();

    color: @grey1;
  }

  &__Abbr {
    color: @grey4;
  }
}
