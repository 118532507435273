@import 'styles/typography.less';
@import 'styles/variables.less';

.Scores {
  display: grid;
  grid-template-columns: 1fr;
  padding: @verticalSpacingMobile 0;

  @media @desktop {
    padding: 0;
  }
}
