@import 'styles/mixins.less';
@import 'styles/typography.less';

.ScoreboardTeam {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.8rem;
  align-items: center;
  padding: 1.2rem;
  text-align: inherit;
  max-width: 17.8rem;
  width: 100%;

  @media @tablet {
    max-width: unset;
  }

  &__Avatar {
    position: relative;
    grid-column: 1;
  }

  &__Background {
    position: absolute;

    &,
    & > svg {
      height: 100%;
      width: 100%;
    }
  }

  &__Info {
    position: relative;
    grid-column: 2;
  }

  &--home-horizontal {
    grid-template-columns: 1fr auto;
    justify-items: end;

    .TeamInfo {
      align-items: end;
    }
  }

  &--home-horizontal &__Avatar {
    grid-column: 2;
  }

  &--home-horizontal &__Background {
    svg {
      transform: rotate(180deg);
    }
  }

  &--home-horizontal &__Info {
    grid-column: 1;
    grid-row: 1;
  }

  .TeamInfo {
    display: flex;
    flex-direction: column;
    min-width: 0;
    color: @grey1;

    &__Name {
      .body();
      .truncateWithEllipsis();
    }

    &__Results {
      .comment();
    }
  }
}
