@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ProfileInfo {
  &__Content {
    padding: 0 @smallHorizontalSpacingMobile;
    display: grid;
    grid-template-columns: 1fr 16rem 1fr;
    margin-top: -6.4rem;
    justify-content: space-between;
    align-items: end;
    justify-items: center;

    &_Left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 5.6rem;
      overflow: hidden;
      max-width: 39rem;
      gap: 1.6rem;
      width: 100%;
    }

    &_Right {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5.6rem;
      overflow: hidden;
      max-width: 39rem;
      gap: 1.6rem;
      width: 100%;
      
      & > div {
        justify-content: center;
      }
    }
  }

  &__AvatarWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__Avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      border: 0.4rem solid @grey8;
    }

    &__Frame {
      position: absolute;
    }
    
    &__Badge {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      position: absolute;
      bottom: -0.5rem;
      right: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      z-index: 1;
      
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__HeaderThumbnail {
    position: relative;
    height: 12.8rem;
    width: 100%;
    overflow: hidden;
    
    button {
      background-color: @black;
    }
    
    @media @desktop {
      height: 24rem;
    }
  }

  &__HeaderButtons {
    position: absolute;
    display: flex;
    flex-direction: row;
    right: 0;
    top: 0.8rem;
    padding: 0 @smallHorizontalSpacingMobile;
    gap: @smallHorizontalSpacingMobile;
    
    @media @desktop {
      right: 10vw;
    }
  }

  &__Background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__Details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__Names {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 0.4rem;
    height: 4.8rem;

    h4 {
      .truncateWithEllipsis();
      .h4();
      color: @white;
    }

    span {
      .comment();
      .truncateWithEllipsis();

      color: @grey2;
      
      @media @desktop {
        .body();
      }
    }
  }
  
  &__TeamWrapper {
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Points {
    padding: 0 1.2rem;
    height: 3.2rem;
    border-radius: 3rem;
    background-color: @grey8;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: @grey1;
  }

  &__Rating {
    .commentBold();
  }
}
