@import 'styles/typography.less';
@import 'styles/variables.less';

.PollItem {
  display: flex;
  align-items: center;
  cursor: pointer;

  & > div {
    width: 100%;
  }
  
  &--inactive {
    cursor: default;
    
    .PollItem__BarWrapper, .PollItem__Bar {
      border-radius: @borderRadius;
      justify-content: flex-start;
      border: none;
    }
    
    .PollItem__BarWrapper {
      background-color: @grey8;
    }
    
    .PollItem__Bar {
      padding: 0.9rem 1.2rem;
      width: 100%;
    }

    .PollItem__Title {
      .body();

      color: @white;
      font-weight: 400;
      word-break: break-word;
      margin-right: 0.5rem;
    }
  }

  &--comment-theme {
    .PollItem__BarWrapper {
      background-color: @grey7;
    }
  }

  &__Percentage {
    .comment();

    flex: 0 0 4rem;
    color: @grey2;
  }
  
  &__BarWrapper,
  &__Bar {
    display: flex;
    align-items: center;
    border-radius: @borderRadius;
    overflow: hidden;
  }
  
  &__BarWrapper {
    border: 0.1rem solid @normal;
  }
  
  &__NumberMarker {
    .comment();
    
    text-align: center;
    color: @grey1;
    background: @grey8;
    max-width: 2.4rem;
    width: 100%;
    padding: 1.1rem 0;
  }

  &__Bar {
    position: relative;
    justify-content: center;
    width: 100%;
    padding: 0.8rem 1.2rem;
  }
  
  &__Progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: @dark;
    border-radius: @borderRadius;
  }

  &__Title {
    .bodyBold();

    position: relative;
    color: @grey3;
  }

  &__Icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    border-radius: 50%;
    background-color: @white;
    color: @normal;

    &--expired {
      display: none;
    }
  }

  &__Counter {
    .comment();

    position: relative;
    margin-left: auto;
    color: @grey1;
  }
}
