@import 'styles/typography.less';
@import 'styles/variables.less';

.ScoreboardTable {
  position: relative;
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 4fr 8.5rem;
  grid-column-gap: 4rem;

  &--live,
  &--halftime {
    grid-template-columns: 1fr;
  }

  @media @desktop {
    grid-template-columns: 10fr 1fr;
  }

  &--expanded {
    background-color: @grey8;
  }

  &--with-header {
    padding-top: 0.8rem;
    grid-template-rows: 2.5rem 0.1rem auto;
  }

  &__Main {
    grid-column: 1;
    grid-row: 1 / -1;
  }

  hr {
    grid-row: 2;
    grid-column: 1 / -1;
  }

  &__Button {
    grid-column: 2;
    place-self: center;
  }

  &--with-header &__Button {
    grid-row: 3;
  }

  &__Details {
    grid-column: 1 / -1;
  }

  &__Time {
    .commentBold();

    color: @grey1;
    white-space: nowrap;
  }

  &__Links {
    grid-column: 2;
    grid-row: span 2;
    place-self: center;
    border-left: solid 0.1rem @transparentLight3;
    width: 100%;

    @media @desktop {
      border: none;
    }
  }

  &--with-header &__Links {
    grid-row: 3;
  }
}
