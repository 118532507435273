@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.RadioButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__Title {
    .bodyBold();
    color: @grey3;
    margin-bottom: 1.4rem;
    padding: 0 1.6rem;
  }

  &__Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1.6rem;
    width: 100%;

    &:hover {
      background-color: @transparentLight2;
    }
  }

  &__Label {
    .body();
    color: @grey3;
    padding: 0.7rem 0 0.7rem 0.8rem;
    cursor: pointer;
    width: 100%;
  }

  &__Input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.15rem solid @grey5;
    border-radius: 50%;
    position: relative;
    flex-shrink: 0;

    &:hover:not(:disabled) {
      border: 0.15rem solid @normal;

      &::after {
        .radioButtonAfter();
      }
    }

    &:focus {
      outline: @normal;
    }

    &:checked {
      border: 0.15rem solid @normal;

      &::after {
        .radioButtonAfter();
      }

      &:hover {
        border: 0.15rem solid @normal;

        &::after {
          .radioButtonAfter();
        }
      }
    }
    
    &:disabled {
      border-color: @grey7;
    }
  }
}
