@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.AuthorCardDetails {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0 1.2rem;

  &__Status {
    color: @grey1;
  }

  &__Names {
    display: flex;
    flex-direction: column;

    h4 {
      .truncateWithEllipsis();
    }

    span {
      .comment();
      .truncateWithEllipsis();

      color: @grey3;
    }
  }

  &__Socials {
    display: flex;
    gap: 0.8rem;
    padding-bottom: 0.8rem;
  }
}
