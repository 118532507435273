@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ThirdPartyServiceLogin {
  padding: 0 1.6rem;

  &__Separator {
    .comment();

    display: block;
    width: 100%;
    padding-bottom: 1.6rem;
    color: @grey3;
    text-align: center;
  }

  &__Wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    button:not(:disabled) {
      width: 100%;
      color: white;
    }

    button {
      i {
        font-size: 2.4rem;
      }
    }

    @media @desktop {
      flex-direction: row;
      justify-content: center;
      padding-bottom: 2.4rem;
      gap: 1.2rem;
    }
  }
}
