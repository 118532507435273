@import 'styles/variables.less';
@import 'styles/typography.less';

.Predictions {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  min-height: 45vh;
  background-color: @black;
  
  &__Image {
    margin-bottom: 0.8rem;
  }

  &__Title {
    .bodyBold();
    color: @white;
    padding-bottom: 0.4rem;
    text-align: center;
  }

  &__Message {
    .body();
    color: @grey3;
    text-align: center;
    text-wrap: balance;
  }
}
