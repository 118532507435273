@import 'styles/variables.less';

.Bookmarks {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: @safeAreaBottom;

  &::-webkit-scrollbar {
    display: none;
  }

  &__Types {
    padding-top: 1rem;
  }
}
