@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ModalWrapper {
  .hideScrollbar();

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: @grey6;
  margin: auto;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: @safeAreaTop 0 @safeAreaBottom;

  @media @desktop {
    height: 62.4rem;
    width: 56rem;
  }

  &--mobile {
    width: 100%;
    height: 100%;
  }

  &__Image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__Slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 10rem;
    width: 100%;
    max-width: 70%;
    max-height: 5.6rem;
  }

  &__Buttons {
    border-top: 0.1rem solid @transparentLight3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.6rem;
    width: 100%;

    & > button:first-child {
      margin-right: 1.6rem;
    }
  }
}

.Editor {
  display: flex;
  z-index: 999;
  position: relative;
}

.RootSlider {
  max-width: 70%;
}

.Cropper {
  width: 100%;
  height: 100%;
}
