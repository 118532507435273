@import 'styles/variables.less';
@import 'styles/typography.less';

.Wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.GameStatsRow {
  display: grid;
  grid-template-columns: 35% 30% 35%;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;

  &__Title {
    .comment();

    text-align: center;
    color: @grey2;

    &--primary {
      .bodyBold();

      color: @grey1;
    }
  }
}
