@import 'styles/mixins.less';
@import 'styles/variables.less';

.SelectWrapper {
  position: relative;

  .Label {
    .bodyBold();

    color: @grey1;
    margin-bottom: 0.8rem;

    &--inner {
      position: absolute;
      top: 0.6rem;
      left: 1.2rem;
    }
  }
}

:global(.Select) {
  &__placeholder {
    color: @grey4;
  }

  &__control {
    .basicInput();
    .comment();

    padding: 0 0 0 1.2rem;

    // use !important due to library restrictions
    min-height: unset !important;
    height: 3.2rem;
    border-radius: 0.4rem;
    border-color: @grey5;
    background: @black;
    
    &:hover:not(:disabled) {
      background: @transparentLight2;
    }
    
    &--is-focused {
      border: 0.1rem solid @normal;
    }
  }

  &__menu {
    .comment();
    
    margin: 0.5rem 0;
    background: @grey7;
    outline: solid 0.1rem @grey6;
    width: 100%;
    border-radius: 0.4rem;
    padding: 0.8rem 0;
    min-width: fit-content;
    background: @black;

    // use !important due to library restrictions
    z-index: @selectMenuZIndex !important;

    &-list {
      .hideScrollbar();
    }
  }

  &__option {
    padding: 0.8rem;
    height: 3.2rem;
    color: @grey3;
    white-space: nowrap;

    &:hover {
      background: @transparentLight2;
    }

    &--is-selected,
    &--is-selected:hover {
      background: @transparentNormal;
    }
  }
}

:global(.Select--inner-label) {
  :global(.Select__control) {
    padding-left: 4.7rem;

    :global(.Select__value-container) {
      .comment();

      color: @grey3;

      @media @desktop {
        .body();
      }
    }
  }
}
