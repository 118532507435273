@import 'styles/variables.less';
@import 'styles/typography.less';

.NotificationPermission {
  background-color: @grey8;
  padding-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &--with-top-space {
    margin-top: 3.2rem;
  }
}

.GameCardsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 29.6rem;
  width: 100%;
  max-width: 37rem;
  margin: 0 3.2rem 2.4rem;
  border-top-left-radius: 3.2rem;
  border-top-right-radius: 3.2rem;
  background-image: linear-gradient(to bottom, @transparentLight4, @transparentLight7);

  &__Buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 3.2rem;
    width: 100%;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  &__Left {
    display: flex;
    justify-content: flex-start;
    column-gap: 0.2rem;
    align-items: center;
  }

  &__GameCard {
    padding-top: 2.4rem;
  }

  &__SmallCircles {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 0.6rem;
    justify-content: center;
    align-items: center;
  }
}

.MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 37rem;

  &__Title {
    .h3();
    color: @white;
    padding-bottom: 1.6rem;
    text-align: center;
  }

  &__Message {
    .body();
    color: @grey2;
    text-align: center;
    padding: 0 2.4rem;
    max-width: 35rem;
  }

  &__Buttons {
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }
}

.BigCircle {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  background-color: @grey2;
}

.SmallCircle {
  height: 0.2rem;
  width: 0.2rem;
  border-radius: 50%;
  background-color: @grey2;
}
