@import 'styles/variables.less';
@import 'styles/mixins.less';

.EditorBottomSheet {
  max-height: calc(100vh - 3.8rem - @safeAreaTop);
  padding: 0 1.6rem calc(1.6rem + @safeAreaBottom);
  color: @white;
  transition-duration: 200ms;
  
  &--processing {
    filter: blur(5px); // required for blur
  }
  
  &--keyboard {
    padding: 0 1.6rem calc(1.6rem + @keyboardHeight);
  }
}

&:global(.android-platform) {
  .EditorBottomSheet--keyboard {
    padding: 0 1.6rem calc(1.6rem + 4rem + @keyboardHeight);
  }
}
