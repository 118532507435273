@import 'styles/variables.less';
@import 'styles/mixins.less';

.PlayerSingleValue {
  .truncateWithEllipsis();
  .commentBold();

  font-weight: 700;
  color: @grey1;
  white-space: nowrap;
  max-width: 8rem;
  width: 100%;
}
