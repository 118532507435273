@import 'styles/variables.less';

.VideoPostModal {
  position: relative;
  display: flex;
  height: 100vh;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: var(--cap-safe-area-bottom);
  user-select: none;
  -webkit-user-select: none;
  
  &--blur {
    filter: blur(5px); // required for blur
  }
}

.Video {
  width: 100%;
  height: 100%;

  &--flip {
    transform: scaleX(-1);
  }
}

.Actions {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(12rem, 1fr));
  padding: 2.4rem;
}

.Recording {
  display: flex;
  width: 8rem;
  height: 8rem;
  pointer-events: none;
}

.RecordingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
