@import 'styles/variables.less';

.IonBottomSheet {
  --backdrop-opacity: 1;
  --overflow: unset;

  &::part(content) {
    width: 100%;
    background-color: @grey8;
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
  }

  &::part(backdrop) {
    background-color: @black;
    -webkit-tap-highlight-color: transparent;
  }

  &::part(handle) {
    position: relative;
    top: 0;
    width: 100%;
    height: 2rem;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    z-index: 10;

    &:before {
      top: 1.2rem;
      width: 4rem;
      height:0.6rem;
      border-radius: 1.6rem;
      background-color: @grey5;
      transform: translateX(-50%);
      padding: 0;
    }
  }

  &--auto-height {
    --height: auto;

    &::part(backdrop) {
      background-color: @transparentMobileModal;
    }
  }

  &--overflow-visible {
    &::part(content) {
      overflow: visible;
    }
  }

  &--custom-swipe-line {
    &::part(handle) {
      top: -8rem;
      position: inherit;

      &::before {
        width: 4rem;
        height: 0.6rem;
        border-radius: 2.7rem;
        background-color: @grey3;
      }
    }
  }

  &--without-handle-element {
    &::part(handle) {
      display: none;
    }
  }

  &__Wrapper {
    margin-top: 0;
  }
}