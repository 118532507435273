@import 'styles/variables.less';
@import 'styles/mixins.less';

.scrollableSection {
  & > :first-child {
    margin-left: 1.6rem;
  }

  & > :last-child {
    margin-right: 1.6rem;
  }

  @media @desktop {
    & > :first-child {
      margin-left: 0;
    }

    & > :last-child {
      margin-right: 0;
    }
  }
}

.AboutTheTeamSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
  margin: 0 1.6rem;

  @media @desktop {
    gap: 1.6rem;
    margin: 0;
  }
}

.TopPlayersSection {
  .hideScrollbar();
  .scrollableSection();

  display: grid;
  grid-template-columns: minmax(20.4rem, 1fr) repeat(2, minmax(18.8rem, 1fr)) minmax(20.4rem, 1fr);
  gap: 1.6rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.RecentGamesSection {
  @media @desktop {
    & > div {
      padding: 0;
    }
  }
}

.StatsSection {
  z-index: 1;
}
