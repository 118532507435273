@import 'styles/variables.less';
@import 'styles/mixins.less';

.ContentEditable {
  .contentEditable();

  height: 100%;

  & > p:last-of-type {
    margin-bottom: 0;
  }

  a {
    .truncateLink();
  }
}
