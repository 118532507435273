@import 'styles/typography.less';

.Team {
  padding: 0;
  height: 100%;

  &--home {
    svg {
      right: 0;
      position: absolute;
      transform: rotate(180deg);
    }
  }

  &--visitor {
    svg {
      position: absolute;
      left: 0;
    }
  }

  &__Items {
    .comment();

    position: relative;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    height: 8rem;
  }
}

.Team--home .Team__Items {
  justify-content: end;
  padding: 2rem;

  & > svg {
    width: 100%;
    height: 100%;
  }
}

.Team__Name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;

  @media @desktop {
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.8rem;
  }
}

.Team--home .Team__Name {
  @media @desktop {
    flex-direction: row-reverse;
  }
}

.Team--home .Team__Avatar {
  align-self: flex-end;
}

.Team--visitor .Team__Items {
  justify-content: start;
  padding: 2rem;

  & > svg {
    width: 100%;
    height: 100%;
  }
}

.TeamStaticInfo {
  display: flex;
  flex-direction: column;
  color: @white;

  &__Name {
    .commentBold();

    z-index: 2;
  }
}
