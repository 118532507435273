@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ListItem {
  height: 3.2rem;
  padding: 0.8rem 2.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &--full-width {
    width: 100%;
  }

  &__Input {
    -webkit-appearance: none;
    appearance: none;
    background-color: inherit;
    margin: 0;

    color: @grey5;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.15rem solid @grey5;
    border-radius: 0.2rem;

    display: grid;
    place-content: center;

    &:disabled {
      background: @grey6;
    }
    
    &--big {
      height: 2.8rem;
      width: 2.8rem;
      border-radius: 0.8rem;
    }
  }

  &__Image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.4rem;
    width: 2.4rem;
  }

  &__Label {
    .typography();

    width: 100%;
    align-items: center;
    position: relative;
    color: @grey3;
    display: grid;
    grid-template-columns: 1.6rem auto;
    gap: 0.8rem;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }

    & > i {
      position: absolute;
      font-size: 1.6rem;
    }

    &--big {
      grid-template-columns: 3.8rem auto;

      & > i {
        font-size: 2.6rem;
      }
    }

    & > span {
      .truncateWithEllipsis();
    }

    &--with-avatar {
      grid-template-columns: 1.6rem 2.4rem auto;
    }

    &--with-avatar-big {
      gap: 1.2rem;
      grid-template-columns: 3.8rem 2.4rem auto;
    }
  }

  &--selected &__Input {
    background-color: @normal;
    border: none;
  }
  
  &--selected &__Input:disabled {
    background-color: @grey5;
  }
  
  &--selected i {
    color: @white;
  }
  
  &--disabled i {
    color: @grey3;
  }
  
  &:hover {
    background-color: @transparentLight2;
  }

  &--disabled-hover:hover {
    background-color: initial;
  }
}
