@import 'styles/variables.less';
@import 'styles/typography.less';

.ProfileThumbnailInput {
  width: 100%;
  height: calc(@mobileProfileThumbnailHeight + @safeAreaTop);
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media @desktop {
    height: calc(@desktopProfileThumbnailHeight + @safeAreaTop);

    &:hover &__Button {
      visibility: visible;
    }
  }

  &__Button {
    @media @desktop {
      .bodyBold();

      position: absolute;
      top: 2.4rem;
      left: 2.4rem;
      z-index: 3;
      display: flex;
      align-items: center;
      background-color: @grey8;
      gap: 0.8rem;
      padding: 0.5rem 0.8rem 0.7rem 1.1rem;
      border-radius: 0.4rem;
      color: @grey1;
      cursor: pointer;
      visibility: hidden;
    }
  }

  &__ButtonWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &--is-edit-modal {
    height: calc(@mobileProfileThumbnailHeight);
  }
}

.ProfileThumbnail {
  width: 100%;
  height: 100%;

  &__Background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__Logo,
  &__Nickname {
    position: absolute;
    z-index: 2;
  }

  &__Nickname {
    top: 0.7rem;
    left: 1.3rem;
    width: 8rem;

    @media @desktop {
      left: 4rem;
      width: 24rem;
    }
  }

  &__Logo {
    bottom: 0;
    right: 0;
    width: 11.1rem;

    @media @desktop {
      width: 32rem;
    }
  }
}

:global(.ios-platform),
:global(.android-platform) {
  .ProfileThumbnail {
    &__Nickname {
      top: calc(@safeAreaTop - 0.8rem);
    }
  }

  .ProfileThumbnailInput {
    &--is-edit-modal {
      .ProfileThumbnail__Nickname {
        top: 0.7rem;
      }
    }
  }
}
