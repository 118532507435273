@import 'styles/variables.less';
@import 'styles/typography.less';

.StatsLeadersCard {
  min-width: 32.8rem;

  @media @desktop {
    background-color: @grey8;
    border-radius: @borderRadius;
  }

  &__Header {
    .commentBold();

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem 1.6rem;
    border-bottom: 0.1rem solid @grey6;
    color: @grey3;
  }

  &__Chart {
    height: 13.6rem;
    padding: 1.2rem 1.6rem;

    @media @desktop {
      padding: 0.8rem 1.2rem;
    }
  }

  &__List {
    margin: 0;
    padding: 0;
  }

  &__Item {
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;
    counter-increment: counter;

    &:nth-child(even) {
      background-color: @transparentLight2;
    }

    &Value {
      .bodyBold();

      color: @grey3;
    }

    &Button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__AvatarAndName {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: @normal;

    &::before {
      .comment();

      color: @grey3;
      content: counter(counter);
      margin-right: 0.8rem;
    }
  }

  &__Button {
    padding: 0.8rem 1.6rem 1.6rem;
  }
}
