@import 'styles/variables.less';
@import 'styles/typography.less';

.Slider {
  display: flex;
  height: 0.6rem;
  width: 100%;
  position: relative;
  
  &__Section {
    height: 100%;
  }

  &__Divider {
    height: 100%;
    background-color: @grey2;
    width: 0.2rem;
  }
}
