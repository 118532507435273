@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.RecentSearchItem {
  display: flex;
  height: 4.8rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  color: @grey3;

  &__Label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 80%;

    @media @desktop {
      width: 18rem;
    }
  }
}

.Text {
  .body();
  .truncateWithEllipsis();

  color: @grey1;
  width: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
