@import 'styles/mixins.less';
@import 'styles/variables.less';
@import 'styles/typography.less';

.TeamAvatar {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: @gameRowDefaultHeight;

  &--compact {
    width: 6.8rem;
    height: @gameRowCompactHeight;

    .TeamAvatar__Image {
      width: 4rem;
      height: 4rem;
    }
  }

  &--recent-splits-theme {
    width: unset;
    padding-right: 1.6rem;

    .Info {
      left: 6.4rem;
    }
  }

  &__Image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    user-select: none;

    & > img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.Logo {
  height: 4.8rem;
  width: 4.8rem;

  &--compact {
    height: 4rem;
    width: 4rem;
  }
}

.Home {
  position: absolute;
  bottom: -0.2rem;
  left: -0.1rem;
  width: 1.8rem;
  height: 1.8rem;
  background-color: @black;
}

.Result {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  border-radius: 50%;
  background-color: @black;
  padding: 0.1rem;

  & > img {
    border-radius: 50%;
    object-fit: cover;
  }

  &--compact {
    right: -0.4rem;
    bottom: -0.5rem;
    width: 2rem;
    height: 2rem;
  }
}

.Info {
  .hideScrollbar();

  position: absolute;
  left: 8rem;
  top: 0.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 0.8rem;
  width: calc(100vw - 8rem);
  padding-right: 1.6rem;
  overflow-x: auto;
  overflow-y: hidden;

  &--compact {
    left: 6.8rem;
    top: 0.6rem;

    @media @desktop {
      top: 0.3rem;
    }
  }

  &__Group {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  &__PlayerAvatar {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    object-fit: cover;
  }

  &__Location {
    .comment();

    white-space: nowrap;
    color: @grey3;
  }

  &__Opponent {
    .comment();

    white-space: nowrap;
    color: @normal;
  }

  &__Splitter {
    .comment();

    color: @grey3;
  }

  &__Points {
    .comment();

    display: flex;
    gap: 0.4rem;
    padding: 0 0.4rem;
    white-space: nowrap;
    color: @normal;

    @media @desktop {
      .body();
    }
  }

  &__Date {
    .comment();

    white-space: nowrap;
    color: @grey3;
  }
}

.Winner {
  .commentBold();

  @media @desktop {
    .bodyBold();
  }
}
