@import 'styles/variables.less';
@import 'styles/typography.less';

.Tab {
  .bodyBold();

  border-radius: @borderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;

  @media @desktop {
    height: 3.2rem;
  }

  color: @grey1;
  background-color: @grey8;

  &:hover {
    color: @grey1;
    background-color: @transparentLight3;
  }

  &--active,
  &--active:hover {
    color: @black;
    background-color: @grey1;
  }

  &--disabled {
    pointer-events: none;
    color: @grey5;
    background-color: @grey8;
  }
}
