@import 'styles/variables.less';
@import 'styles/typography.less';

.Header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 2.4rem 0;

  &__Title {
    .bodyBold();
    color: @grey1;
    width: fit-content;
    padding-right: 3rem;

    @media @desktop {
      .h3();
    }
  }

  &__Date {
    .bodyBold();
    color: @grey1;

    @media @desktop {
      .body();
      color: @grey3;
      font-weight: 400;
    }
  }

  & > button {
    margin-left: auto;
  }
}
