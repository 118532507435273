@import 'styles/variables.less';
@import 'styles/typography.less';

.StatsHeader {
  margin-top: 1.6rem;

  @media @desktop {
    margin-top: 2.4rem;
  }

  &__Title {
    margin-bottom: 2.4rem;
    display: flex;
    gap: 1.6rem;
    color: @grey1;
    height: 3.2rem;
  }

  &__Selects {
    display: grid;
    grid-template-columns: 1fr;

    @media @desktop {
      grid-template-columns: @seasonsSelectWidth;
    }

    &--multiple {
      grid-template-columns: 1fr auto;
      gap: 0.8rem;

      @media @desktop {
        grid-template-columns: @seasonsSelectWidth auto;
        gap: 1.2rem;
      }
    }
  }

  &__Filters {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0 1.6rem;
    row-gap: 1.6rem;

    @media @desktop {
      padding: 0;
      flex-direction: row;
    }
  }

  &__Switcher {
    width: 100%;

    @media @desktop {
      width: 19.2rem;
    }
  }
}
