@import 'styles/typography.less';
@import 'styles/variables.less';

.SwitcherItem {
  .comment();

  position: relative;
  color: @grey3;
  flex-grow: 1;
  max-height: 3.2rem;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__Button {
    background: none;
    color: inherit;
    width: 100%;
  }

  &:not(:last-child):not(&--active)::after {
    content: '';
    display: inline-block;
    height: 1.9rem;
    width: 0.1rem;
    position: absolute;
    right: 0;
    background: @transparentLight3;
  }

  &--active {
    color: @grey1;
    border: 0.2rem solid @normal;
    border-radius: 0.4rem;
  }

  &--disabled {
    color: @grey4;
    cursor: not-allowed;
  }
}
