@import 'styles/variables.less';
@import 'styles/typography.less';

.FeedListBoard {
  width: 24rem;
  background-color: @grey8;
  border-radius: @borderRadius;

  &__Teams {
    display: flex;
    flex-direction: column;
  }

  &__Header {
    .bodyBold();

    padding: 0.9rem 1.2rem;
    border-bottom: 0.1rem solid @transparentLight3;
    color: @grey3;
  }

  &__Footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 4rem;
    padding-left: 0.6rem;
    border-top: 0.1rem solid @transparentLight3;
  }
}
