@import 'styles/variables.less';
@import 'styles/typography.less';

.GameInfo {
  min-width: 14.4rem;
  height: 100%;
  text-align: inherit;
  color: inherit;
  border-radius: 0.8rem;

  &__Content {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    background-color: @grey7;

    & > :first-of-type {
      padding-top: 0.3rem;
    }

    & > :last-of-type {
      padding-bottom: 0.3rem;
    }
  }
}
