@import 'styles/variables.less';

.MainSettings {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media @desktop {
    width: 50%;
    height: calc(100% + 6.8rem);
    margin-top: -6.8rem;
    padding: 1.6rem 1.6rem 0.8rem 0;
    border-right: 0.1rem solid @grey5;
  }

  &__Social {
    margin-top: auto;
  }

  &__Buttons {
    margin-top: 1.6rem;
    display: flex;
    gap: 1.6rem;
    height: 4.8rem;
  }
}
