@import 'styles/variables';
@import 'styles/mixins';

.Comment {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media @desktop {
    background-color: @grey7;
    border-radius: @borderRadius;
    margin-bottom: 1.6rem;
  }

  &__Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.8rem 1.6rem;
    position: relative;

    @media @desktop {
      align-items: unset;
    }

    .TopSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 1.2rem;
      width: calc(100% - 6.5rem);
      gap: 0.4rem;

      @media @desktop {
        width: 80%;
      }

      &__UserNick {
        .truncateWithEllipsis();
        .commentBold();

        padding-right: 0.8rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }

      &__Date {
        .comment();

        display: flex;
        color: @grey4;
        line-height: 1.6rem;
        white-space: nowrap;
      }

      &__FirstRow {
        display: flex;
        flex-direction: row;
      }
    }
  }

  &__HeaderTooltip {
    height: fit-content;
    margin-left: auto;
  }

  &__Main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.8rem 1.6rem 0 5.2rem;
    position: relative;

    @media @desktop {
      padding: 0 1.6rem 0 4.8rem;
    }
  }

  &__Content {
    .body();
    display: flex;
    width: 100%;
    color: @grey3;
    text-align: left;
  }

  &__Date {
    .comment();
    display: flex;
    color: @grey4;
    line-height: 2.2rem;
  }

  &__Overlay {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
