@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.TextArea {
  .basicInput();

  height: 100%;
  resize: none;
  padding-right: 6rem;
  overflow: hidden;

  &--with-left-icon {
    padding-left: @inputLeftIconPadding;
  }

  &--with-right-icon {
    padding-right: @inputRightIconPadding;
  }

  &--invalid {
    &,
    &:focus,
    &:hover {
      border: 0.1rem solid @alert;
    }
  }

  &--without-counter {
    .hideScrollbar();
    
    padding-right: 1.6rem;
    overflow: auto;
    max-height: 24rem;
  }
}

.Counter {
  .comment();
  position: absolute;
  right: 1.6rem;
  top: 50%;
  color: @grey5;
  transform: translateY(-50%);
}
