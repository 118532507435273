@import 'styles/variables';
@import 'styles/typography';

.PollForm {
  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
    color: @grey1;

    &__Toggle {
      display: flex;
      align-items: center;

      span {
        .comment();

        margin-right: 0.8rem;
      }
    }
  }

  &__Button {
    margin-right: 1.6rem;
  }

  &__Tip {
    flex: 1 1 0;
    font-size: 1.2rem;
    color: @grey4;
  }

  &__Footer {
    display: flex;
    align-items: center;
    margin-top: 1.2rem;
    padding-bottom: 5.5rem;
  }

  &--bottom-sheet-theme {
    margin-top: 0.8rem;
  }
}
