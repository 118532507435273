@import 'styles/variables.less';
@import 'styles/mixins.less';

.ag-theme-players-stats {
  height: 100%;
  width: 100%;
  .hideScrollbar();

  &--with-colors {
    .ag-header-row {
      border: none;
    }

    .ag-header {
      background-color: var(--team-primary-color);

      @media @desktop {
        border-top-left-radius: @borderRadius;
        border-top-right-radius: @borderRadius;
      }
    }

    .ag-header-cell-sortable .ag-cell-label-container {
      border-bottom: 0.1rem solid var(--team-secondary-color);
    }

    .ag-header-cell-sortable:not([aria-sort='none']) .ag-header-cell-label {
      color: var(--team-secondary-color);
    }

    .ag-header-cell-text {
      color: var(--team-secondary-color);
    }
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    text-align: left;
  }

  .ag-cell-sorted {
    // use !important due to library restrictions
    background-color: @transparentLight3 !important;
    color: @grey1;
  }

  .ag-players-stats-left-first {
    padding-left: 1.6rem;
    text-align: start;
    display: flex;

    @media @desktop {
      padding-left: 1.6rem;
    }
  }

  .ag-players-stats-left {
    text-align: start;
    display: flex;
  }

  .ag-players-stats-header-name.ag-header-cell {
    padding-left: 0;
  }

  .ag-players-stats-header-name-first:first-child .ag-header-cell-label,
  .ag-players-stats-header-name:first-child .ag-header-cell-label {
    justify-content: start;
  }
}
