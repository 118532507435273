@import '@ionic/react/css/core.css';

@import './mixins';
@import './typography';
@import './icons';
@import './icon-classes';

:root {
  --vh: 1vh;

  // These values are default ones, which are subsequently overwritten.
  --cap-safe-area-top: 0;
  --cap-safe-area-right: 0;
  --cap-safe-area-bottom: 0;
  --cap-safe-area-left: 0;

  --keyboard-height: 0rem;

  --scroll-top-right: 2.5rem;

  --transform-trending-panel: 100%;
  --transform-container: 0;
  --nav-arrow-smooth-duration: 80ms;

  --sidebar-smooth-duration: 150ms;
  --trending-sidebar-smooth-duration: 150ms;

  --mobile-backdrop-opacity: 0;
  --mobile-backdrop-visibility: hidden;

  --transform-mobile-header: 0;
  --transform-mobile-bottom-bar: 0;
  --transform-mobile-fixed-element: 0;
  --scroll-smooth-duration: 150ms;
  --opacity-mobile-header: 1;

  --dot-border-color: @black;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 62.5%;
}

body {
  .body();

  background-color: @black;
  color: @white;
}

h1 {
  .h1();
}
h2 {
  .h2();
}
h3 {
  .h3();
}
h4 {
  .h4();
}

button {
  .truncateWithEllipsis();
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  outline: none;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: transparent;
}

a,
label,
[role='button'],
[role='presentation'] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

hr {
  margin: 0;
  border: none;
  border-top: 0.1rem solid @transparentLight3;
}

p {
  margin: 0 0 1em;
}

.hidden {
  visibility: hidden;

  &:before {
    content: '';
  }
}

.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

#root {
  position: fixed;
}

#modal {
  position: relative;
  z-index: @modalZIndex;
}

#sidebar {
  position: relative;
  z-index: @sidebarPanelZIndex;
}

#bottom-sheet {
  position: relative;
  z-index: @bottomSheetZIndex;
}

ul {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding: 0;
}

.error-toast-message {
  background: @grey5;
  width: 31.2rem;
  min-height: 6.2rem;
  border: 0.1rem solid @grey5;
  border-radius: @borderRadius;
}

.ReactVirtualized__Grid {
  .hideScrollbar();
}

.disabled-scrolling {
  height: 100vh;
  overflow: hidden;
}

ion-content {
  --background: transparent;
  --color: inherit;
}

ion-refresher {
  width: 100%;
  opacity: 0;
  transition: opacity 300ms;
  z-index: -1;

  &.refresher-active {
    display: flex;
  }

  &.refresher-pulling,
  &.refresher-refreshing,
  &.refresher-ready {
    opacity: 1;
  }
}

#marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  bottom: calc(1rem + @safeAreaBottom);
  left: 1rem;
  z-index: 9999999999999;
  background-color: red;
  display: none;
}

// use !important due to library restrictions
.rsme-spinner {
  width: 1.2rem !important;
  height: 1.2rem !important;
  border: 0.3rem solid @grey3 !important;
  border-right-color: transparent !important;
}

.ion-page {
  contain: unset;
  position: unset;
  display: block;
  overflow: unset;
}

// use !important due to library restrictions
body .rsme-embed .twitter-tweet,
.rsme-tiktok-embed,
.rsme-twitter-embed > div {
  margin: 0 auto !important;
}
