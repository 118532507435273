@import 'styles/typography.less';
@import 'styles/variables.less';

.truncateWithEllipsis() {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scrollTopContainer() {
  position: absolute;
  pointer-events: none;
  right: var(--scroll-top-right);
  height: 100%;
  bottom: 0;
}

.basicInput() {
  .body();

  color: @grey1;
  box-sizing: border-box;
  border-radius: @inputBorderRadius;
  padding: 1.3rem @inputHorizontalPadding;
  transform: translateZ(0);
  background-color: @grey7;
  border: 0.1rem solid @grey7;
  width: 100%;
  transition: background-color, outline-color, color 100ms ease-in-out;
  
  &::placeholder {
    color: @grey4;
  }
  
  &:hover:not(:disabled) {
    background-color: @grey6;
  }

  &:focus {
    outline: none;
    border: 0.1rem solid @normal;
    background-color: @grey7;
  }

  &:disabled {
    border: 0.1rem solid @grey6;
    cursor: not-allowed;
    background: transparent;
    color: @grey5;
    
    &::placeholder {
      color: @grey5;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: @grey1;
    -webkit-box-shadow: 0 0 0 100rem @grey8 inset;
    box-shadow: 0 0 0 100rem @grey8 inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: @grey1;
  }

  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 100rem @grey8 inset;
    box-shadow: 0 0 0 100rem @transparentLight2 inset;
  }
}

.header() {
  height: 5.2rem;
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  background: @grey8;

  p {
    .bodyBold();
  }
}

.avatar() {
  .bodyBold();

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: @gradient;
  user-select: none;
  flex-shrink: 0;

  & > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.avatarAfter () {
  outline: 0.1rem solid @black;
  border-radius: 50%;
}

.search() {
  padding-left: 3.6rem;
  margin: 0;

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  height: 3.2rem;
}

.hideScrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar() {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.radioButtonAfter() {
  content: '';
  display: block;
  border-radius: 50%;
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: @normal;
}

.contentEditable() {
  .hideScrollbar();

  position: relative;
  display: block;
  border: 0;
  tab-size: 1;
  outline: 0;

  :focus-visible {
    outline: none;
  }

  br + br {
    display: none;
  }

  p {
    .body();

    margin-bottom: 0.8rem;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0.8rem;
    padding-left: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    li::before,
    li::marker {
      color: @grey4;
    }
  }

  ul {
    list-style: disc;

    ul {
      list-style-type: circle;

      ul {
        list-style-type: square;
      }
    }
  }

  hr {
    display: none;
  }

  ol ol {
    list-style-type: upper-alpha;

    & ol {
      list-style-type: lower-alpha;

      & ol {
        list-style-type: upper-roman;

        & ol {
          list-style-type: lower-roman;
        }
      }
    }
  }

  * {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  a {
    text-decoration: none;
  }
}

.lineClamp(@lines) {
  display: -webkit-box;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncateLink() {
  .truncateWithEllipsis();

  color: @normal;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  text-decoration: auto;
  height: 2.2rem;
}

.breakIntoColumns(@columns) {
  -webkit-column-count: @columns;
  -moz-column-count: @columns;
  column-count: @columns;
}
