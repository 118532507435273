@import 'styles/variables.less';

.Wrapper {
  background-image: url('assets/images/404-background.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: @grey4;

  &__Heading {
    font-size: 11.2rem;
    line-height: 11.2rem;
  }

  &__Message {
    line-height: 2.2rem;
  }
}
