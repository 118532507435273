@import 'styles/variables.less';

.PageWrapper {
  position: relative;
  margin-right: 0;
  transition: margin-right @popupsDisappearTime @animationEasing;
  
  &--shifted {
    @media @desktopWithSidebar {
      margin-right: @sidePanelWidthDesktop;
      transition: margin-right @popupsAppearTime @animationEasing;
    }
  }
}
