@import 'styles/variables.less';
@import 'styles/typography.less';

.PostCardActionsBar {
  padding-top: 0.8rem;
  width: 100%;
  height: 5.2rem;
  padding-bottom: 1.2rem;

  @media @desktop {
    padding-top: 1.2rem;
  }

  &__Actions {
    height: 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__Item {
    display: flex;
    align-items: center;
    position: relative;
    color: @grey1;

    &:last-of-type {
      min-width: unset;
    }

    &:not(&:first-of-type) {
      margin-left: 1.6rem;

      @media @desktop {
        margin-left: 2.4rem;
      }
    }
  }

  &__Number {
    padding-left: 0.2rem;
    
    &--athlete-like {
      padding-left: 1.2rem;
    }
    
    &--disabled {
      opacity: 0.3;
    }
  }

  &__Timelime {
    .comment();

    width: 100%;
    padding-top: 0.8rem;
    color: @grey3;
  }
}

.LikeWrapper {
  position: relative;
  min-width: 2.8rem;
  min-height: 2.8rem;
  cursor: pointer;
}

.UserLiked {
  position: absolute;
  top: 0.4rem;
  left: 1.3rem;
  z-index: 5;
  width: 2.4rem;
  height: 2.4rem;
  
  @media @desktop {
    left: 1.5rem;
  }
  
  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
