@import 'styles/variables.less';

.AuthorCardHeader {
  position: relative;
  width: 100%;
  margin-bottom: 4.8rem;

  &__Avatar {
    position: absolute;
    bottom: 0;
    left: 1.2rem;
    border-radius: 50%;
    border: 0.4rem solid @grey7;
    transform: translateY(50%);
  }
}

.AuthorCardHeaderThumbnail {
  position: relative;
  height: 9.6rem;
  width: 100%;
  overflow: hidden;

  &__Background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__Nickname {
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    width: 6.1rem;
  }

  &__Logo {
    position: absolute;
    bottom: 3rem;
    right: -2rem;
    width: 8.2rem;
  }
}
