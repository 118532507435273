@import 'styles/variables.less';
@import 'styles/typography.less';

.Scores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .Score {
    .bodyBold();
  }

  .Time {
    .comment();

    display: flex;
    gap: 0.4rem;
    color: @grey3;
  }
}
