@import 'styles/variables.less';
@import 'styles/typography.less';

.Header {
  width: 100%;
  min-height: calc(5.2rem + @safeAreaTop);
  display: flex;
  flex-direction: row;

  .bodyBold();

  background-color: @grey7;
  color: @grey3;
  align-items: center;
  justify-content: flex-start;
  padding-top: @safeAreaTop;
  border-bottom: 0.1rem solid @grey6;

  @media @desktop {
    min-height: 5.2rem;
  }

  &__Filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__Main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0.6rem 0 1.2rem;
    align-items: center;
    width: 100%;
  }
}

.Title {
  .bodyBold();
  color: @grey3;
  width: 15.6rem;
}

.Actions {
  width: 11.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__Search {
    &--active {
      background-color: @grey6;
      border-radius: 0.4rem;
      border: 0.1rem solid @grey6;
    }
  }
}

.FilterIcon {
  width: 100%;
  display: flex;
  background-color: @normal;
  align-items: center;
  justify-content: center;
  color: white;
  padding-right: 0.7rem;
  border-radius: 0.4rem;
}

.HeaderProfile {
  display: flex;
  height: 7.2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: @black;
  padding: 0 1.6rem;

  @media @desktop {
    padding: 0;
  }

  &__Label {
    color: @grey1;
    font-weight: 700;

    .h3();
  }

  &__Filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
