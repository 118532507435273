@import 'styles/variables.less';

.CommentsWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  padding: 0.8rem 1.6rem 1.2rem;
  
  @media @desktop {
    border-radius: @borderRadius;
    background-color: @grey8;
    margin-top: @bigVerticalSpacingDesktop;
  }

  &__ShowMore {
    margin: 0 1.6rem 1.6rem;
  }

  &__EmptyState {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > div {
      border-radius: 0;
      background-color: unset;

      @media @desktop {
        border-radius: 0.8rem;
        background-color: @grey8;
      }
    }
  }
}

.CommentCreate {
  position: sticky;
  bottom: 0;
  z-index: 5;
  padding: 1.6rem 0 calc(1.6rem + @safeAreaBottom);
  background-color: @black;
}

.CommentLoaderWrapper {
  position: relative;
  min-height: 18rem;
  
  @media @desktop {
    min-height: 0;
  }
}
