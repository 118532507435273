@import 'styles/variables';

.SlideDot {
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.4rem;
  border: 0.1rem solid @grey3;
  border-radius: 50%;

  &--selected {
    background-color: @grey3;
  }
}
