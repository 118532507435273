@import 'styles/variables.less';
@import 'styles/mixins.less';

.SearchInput {
  position: relative;
  border-radius: 0.4rem;
  border-color: @grey8;

  @media @desktop {
    border-color: @grey5;
  }

  input {
    .search();

    height: 4rem;

    @media @desktop {
      height: 3.2rem;
    }
  }

  &>i {
    color: @grey1;
    position: absolute;
    top: calc(50% - 1rem);
    left: 1rem;
  }

  p {
    display: none;
  }
}
