@import 'styles/variables.less';
@import 'styles/typography.less';

.CountdownTimer {
  .bodyBold();

  color: @grey3;
  display: flex;
  gap: 1.1rem;
  align-items: center;
  padding: 0;

  @media @desktop {
    padding: 0 2.4rem;
  }
}
