@import 'styles/variables.less';

.Search {
  height: 4.8rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: @grey7;
  padding: 0 0.8rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid @grey6;

  &__Form {
    width: 23.2rem;
  }

}
