@import 'styles/variables.less';

.RosterSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.2rem;
  row-gap: 1.6rem;
  margin: 0 1.6rem;

  @media @desktop {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.6rem;
    margin: 0;
  }
}
