@import 'styles/variables.less';

.AuthHeader {
  display: flex;
  justify-content: flex-end;
  padding: 0 @smallVerticalSpacingDesktop;

  &__Button {
    margin: 0 0 0 0.8rem;
  }
}
