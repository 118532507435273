@import 'styles/variables.less';
@import 'styles/typography.less';

.SearchWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__Title {
    .bodyBold();
    color: @grey3;
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
  }
  &__Select {
    min-height: 3.2rem;
  }
}

// we need important to override default background and border styles of React select
:global(.Multi) {
  &__control {
    min-height: 3.2rem !important;
    background-color: @grey8 !important;
    border: 0.1rem solid @grey5 !important;
    color: @grey4;

    &:hover {
      border: 0.1rem solid @grey5 !important;
      background-color: @transparentLight2 !important;
    }

    &--is-focused {
      border: 0.1rem solid @grey5 !important;
    }

    &--menu-is-open {
      background-color: @transparentLight2 !important;
      border: 0.1rem solid @normal !important;
      color: @grey4;
    }
  }

  &__menu {
    background-color: @grey8 !important;
    border: 0.1rem solid @grey6 !important;
    z-index: 5 !important;

    > div {
      max-height: 29.2rem;
    }
  }

  &__dropdown-indicator {
    color: @grey3 !important;
    padding: 0 !important;
    height: 100%;
  }

  &__indicator-separator {
    background-color: @transparentLight3 !important;
    max-height: 2.4rem !important;
    height: 100%;
    align-self: auto !important;
  }

  &__clear-indicator {
    color: @grey3 !important;
    padding: 0.2rem 0 0.2rem 0.2rem !important;
  }

  &__multi-value {
    background-color: @grey6 !important;
    padding: 0 !important;
    &__label {
      padding: 0 !important;
    }
  }

  &__multi-value__remove {
    background-color: initial !important;
    color: initial;
    width: 2.4rem;
  }

  &__option {
    padding: 0;

    &:active {
      background-color: @grey8 !important;
    }

    &--is-focused {
      background-color: @grey8 !important;
    }
  }

  &__input {
    .comment() !important;
    color: @grey1 !important;
  }

  &__input-conatiner {
    .comment() !important;
    color: @grey1 !important;
  }

  &__indicators {
    min-height: 100%;
  }
}
