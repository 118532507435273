@import 'styles/variables.less';

.PublicUserFeedToggle {
  max-width: 70.8rem;
  width: 100%;
  padding: 0.8rem 1.6rem;
  margin: auto;
  
  @media @desktop {
    padding: 1.6rem;
  }
}
