@import "styles/variables";

.ConfirmEmailCountdownTimer {
  position: relative;
  min-width: 14rem;
  width: 100%;

  &--loading {
    margin-bottom: 2rem;
    margin-top: 1.5rem;

    .CountdownTimerWrapper {
      display: none;
    }
  }

  &--is-timer-expired {
    width: auto;
  }

  .CountdownTimerWrapper {
    & > button {
      width: 100%;
    }
  }

  @media @desktop {
    width: auto;

    &--loading {
      margin: 0;
    }

    .CountdownTimerWrapper {
      & > button {
        width: auto;
      }
    }
  }
}
