@import 'styles/variables.less';

// Important! it's temporary solution

// For the reason that the library does not provide the ability to change the placeholder theme and classes,
// it was necessary to define styles by tags and selectors

// use !important due to library restrictions
.EmbedWrapperCard {
  width: 100%;

  &--is-youtube {
    aspect-ratio: 16/9;

    & div,
    & iframe {
      height: 100%;
    }
  }

  & > div > div,
  blockquote > div,
  blockquote > div:first-of-type > div {
    border: none !important;
  }

  & > div {
    background-color: @black !important;
    border: none !important;
  }

  & > div iframe {
    margin: 0 !important;
    min-width: 32rem !important;
    background-color: transparent !important;
    border: none !important;
  }

  a > div,
  a > div > div {
    background-color: @black !important;
  }

  a > div:first-of-type > div > div > div:first-of-type,
  a > div:first-of-type > div > div > div:last-of-type > div,
  a > div:last-of-type > div > div div {
    background-color: @grey8 !important;
  }

  a > div:last-of-type > div > div:nth-child(2) > div:last-of-type {
    border-top: 0.2rem solid transparent !important;
    border-left: 0.6rem solid @grey8 !important;
    border-bottom: 0.2rem solid transparent !important;
  }

  a > div:last-of-type svg path {
    fill: @grey8 !important;
  }

  a > div:nth-child(2) > div > div:last-of-type {
    color: @grey3 !important;
  }
}
