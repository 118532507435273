@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.PlayerRankingsCategory {
  width: 100%;
  padding: 0.9rem 0 1.3rem;

  &__Items {
    .hideScrollbar();

    display: flex;
    gap: 2.4rem;
    padding: 0 1.6rem;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: flex-end;

    @media @desktop {
      width: 100%;
      padding: 0;
      gap: 0;
      justify-content: space-between;
      overflow: unset;
    }
  }

  &__Header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1.6rem 1rem;

    @media @desktop {
      padding: 0 0 1.2rem;
    }
  }

  &__Title {
    .h4();

    font-weight: 700;
    color: @white;
    width: fit-content;
    padding-right: 1rem;
    white-space: nowrap;

    @media @desktop {
      .bodyBold();
    }
  }

  &__SeeMore {
    margin-left: auto;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }

  &__SeeMoreLabel {
    .commentBold();

    color: @normal;
  }
}
