@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.Item {
  position: relative;
  width: 7.4rem;
  height: 9.4rem;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  img {
    min-height: 7.4rem;
    width: 7.4rem;
    height: 7.4rem;
    object-fit: cover;
    border-radius: 50%;
    border: 0.3rem solid @grey5;
    padding: 0.2rem;
  }

  &--is-selected {
    img {
      border: 0.3rem solid @transparentLight6;
    }
  }

  &__PlusIcon,
  &__SelectMark {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
  }

  &__SelectMark {
    height: 6.4rem;
    width: 6.4rem;
    left: 0.5rem;
    top: 0.5rem;
    background-color: @transparentLight6;
  }

  &__PlusIcon {
    height: 3.2rem;
    width: 3.2rem;
    right: -0.2rem;
    bottom: 1.5rem;
    border: 0.2rem solid @grey7;
    background-color: @normal;
  }

  &__Name {
    .comment();
    .truncateWithEllipsis();
    height: 1.6rem;
    width: 8rem;
    margin-left: -0.2rem;
    display: block;
    min-width: 0;
    color: @grey1;
    text-align: center;
    
    @media @desktop {
      .body();
      line-height: 1.4rem;
    }
  }
}

@keyframes bounceAnimation {
  from,
  to {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
}
