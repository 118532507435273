@import 'styles/variables.less';

.ag-theme-global-stats {
  width: 100%;
  height: 100%;

  .ag-cell-sorted {
    // use !important due to library restrictions
    background-color: @transparentLight3 !important;
  }

  .ag-theme-base-table .ag-pinned-left-header .ag-header-cell-comp-wrapper,
  .ag-theme-base-table .ag-pinned-left-header .ag-header-cell-label {
    color: unset;
  }

  .ag-global-stats-header-name.ag-header-cell {
    padding-left: 0;
  }

  &--with-color {
    .ag-header-row {
      border: none;
    }

    .ag-header {
      background-color: var(--selected-team-color);
    }

    .ag-header-cell-sortable .ag-cell-label-container {
      border-bottom: 0.1rem solid var(--selected-team-text-color);
    }

    .ag-header-cell-sortable:not([aria-sort='none']) .ag-header-cell-label {
      color: var(--selected-team-text-color);
    }

    .ag-header-cell-text {
      color: var(--selected-team-text-color);
    }
  }
}
