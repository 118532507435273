@import 'styles/variables.less';
@import 'styles/typography.less';
@import "styles/mixins.less";

.Value {
  .comment();
  .truncateWithEllipsis();
  width: 6.1rem;
  background-color: @grey6;
  color: @grey1;
}
