@import 'styles/variables.less';
@import 'styles/mixins.less';

.Player {
  display: grid;
  grid-template-columns: 3.2rem 1fr auto;
  align-items: center;
  width: calc(100% - 1.6rem);

  &__Name {
    .body();
    .truncateWithEllipsis();

    color: @normal;
    padding-left: 1rem;
    text-align: start;
  }

  &__Position {
    .comment();

    color: @grey4;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
}

.Reason {
  padding-left: 1rem;
}

.PlusMinus {
  color: @grey3;

  &--negative {
    color: @alert;
  }

  &--positive {
    color: @success;
  }
}
