@import 'styles/variables.less';

.Rank {
  color: @grey3;
  &--up {
    color: @success;
  }

  &--down {
    color: @alert;
  }
}
