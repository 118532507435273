@import 'styles/variables.less';

.HeaderTabs {
  display: flex;
  height: 100%;

  &__Item {
    display: flex;
    align-items: center;
    @border: 0.1rem solid @transparentLight3;

    padding: 0.6rem;
    border-left: @border;
    &--active {
      color: @grey1;
      background-color: @grey7;
    }
  }
}
