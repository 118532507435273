@import 'styles/variables.less';

.SearchHeader {
  display: flex;
  min-height: 5.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: @grey3;
  padding: 0.6rem 1.6rem 0.6rem 1.2rem;
  gap: 1.2rem;
}
