@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.TextItem,
.ImageItem {
  .body();

  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 1rem 2rem;
  border-bottom: 0.1rem solid @transparentLight3;
  color: @grey1;

  &--active {
    .bodyBold();

    color: @white;
  }

  &:last-child {
    border-bottom: none;
  }
}