@import 'styles/variables.less';
@import 'styles/typography.less';

.TextContent {
  .comment();

  background: @black;
  padding: 0.9rem 1.2rem;
  color: @grey1;
  border-radius: @borderRadius;
  border: 0.1rem solid @grey6;
  position: relative;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &::after {
    z-index: 1;
  }

  &::before {
    z-index: 0;
  }

  &--top,
  &--bottom {
    &::after,
    &::before {
      left: 50%;
    }

    &::after {
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
    }

    &::before {
      border-left: 0.7rem solid transparent;
      border-right: 0.7rem solid transparent;
    }
  }

  &--top {
    &::after,
    &::before {
      transform: translate(-50%, 100%);
      bottom: 0;
    }

    &::after {
      border-top: 0.6rem solid @black;
    }

    &::before {
      border-top: 0.8rem solid @grey6;
    }
  }

  &--bottom {
    &::after,
    &::before {
      transform: translate(-50%, -100%);
      top: 0;
    }

    &::after {
      border-bottom: 0.6rem solid @black;
    }

    &::before {
      border-bottom: 0.8rem solid @grey6;
    }
  }

  &--left,
  &--right {
    &::after,
    &::before {
      top: 50%;
    }

    &::after {
      border-top: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
    }

    &::before {
      border-top: 0.7rem solid transparent;
      border-bottom: 0.7rem solid transparent;
    }
  }

  &--left {
    &::after,
    &::before {
      transform: translate(100%, -50%);
      right: 0;
    }

    &::after {
      border-left: 0.6rem solid @black;
    }

    &::before {
      border-left: 0.8rem solid @grey6;
    }
  }

  &--right {
    &::after,
    &::before {
      transform: translate(-100%, -50%);
      left: 0;
    }

    &::after {
      border-right: 0.6rem solid @black;
    }

    &::before {
      border-right: 0.8rem solid @grey6;
    }
  }
}
