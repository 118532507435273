@import 'styles/variables.less';
@import 'styles/typography.less';

.PaginationPanel {
  display: flex;
  gap: 0.8rem;

  &__Item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.8rem;
    width: 4.8rem;
    color: @grey3;
    background-color: @grey8;
    border-radius: 0.8rem;
    flex-shrink: 0;

    &--active {
      .bodyBold();

      background-color: @normal;
      color: @white;
    }
  }
}
