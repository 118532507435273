@import 'styles/variables.less';
@import 'styles/mixins.less';

.TeamSingleValue {
  .commentBold();
  display: flex;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
  color: @grey1;
  width: 100%;
  &__Img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 50%;
  }
  &__Text {
    margin-left: 0.8rem;
  }
}
