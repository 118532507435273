@import 'styles/typography.less';
@import 'styles/icon-variables.less';

.StatisticsTeam {
  padding: 0 1.2rem;

  &--winning {
    .StatisticsTeam__TeamCode {
      .commentBold();
    }

    .StatisticsTeam__Points {
      .commentBold();

      margin-left: auto;

      &::before {
        content: @icon-marker-collapsed;
        position: absolute;
        left: 6rem;
        font-family: @icomoon-font-family;
        font-size: 2rem;
        transform: rotate(180deg);
      }
    }
  }

  &--scheduled {
    .StatisticsTeam__Points {
      color: @grey4;
    }
  }

  &__Items {
    .comment();

    position: relative;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__Points {
    margin-left: auto;
  }
}
