@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Statistics {
  .hideScrollbar();

  display: flex;
  width: 100%;
  padding: 0 1.6rem;
  gap: 0.4rem;
  min-height: @statisticsHeight;
  overflow-y: hidden;
  overflow-x: auto;
  transition: all 0.2s ease-in-out;

  &--for-arena {
    min-height: unset;
    padding: 0;

    @media @desktop {
      padding: 1.2rem 2.4rem 0;
    }
  }

  &--for-shortcut {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    opacity: 0;
    transition-duration: @shortcutDuration;
    transition-timing-function: @shortcutTimingFunction;
    pointer-events: none;
  }

  &--for-expanded-shortcut {
    padding: 0;
    opacity: 1;
    padding-inline-end: 1.6rem;
    pointer-events: unset;

    @media @desktop {
      padding-inline-end: 2.4rem;
      padding-inline-start: 2.4rem;
    }
  }

  &--scrollbar-windows {
    scrollbar-width: initial;

    &::-webkit-scrollbar {
      display: block;
      height: 1.5rem;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @grey5;
      border: 0.5rem solid @grey8;
      border-radius: 1rem;
    }
  }
}

.StatisticsEmptyState {
  width: 100%;

  &__List {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100%;

    & > :last-child {
      border: none;
    }
  }

  &__Message {
    .comment();

    color: @grey4;
    place-self: center;
    text-wrap: nowrap;
  }
}
