@import 'styles/variables';
@import 'styles/typography';
@import 'styles/mixins';

.Loader {
  border-radius: 50%;
  background-color: @black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @white;
}

.AvatarInput {
  .h4();
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border: 0.4rem solid @black;
  border-radius: 50%;
  pointer-events: none;

  .HoverTextBlock:hover {
    opacity: 1;
  }

  &__Image {
    width: 100%;
    height: 100%;
  }

  &__ButtonWrapper {
    position: absolute;
    bottom: -1.1rem;
    right: -1.5rem;
    pointer-events: auto;
  }

  @media @desktop {
    pointer-events: auto;
    border: unset;
    border-radius: unset;
  }
}

.HoverTextBlock {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s linear;
  text-align: center;

  &__Text {
    top: 50%;
    left: 50%;
    .button();
  }
}
