@import 'styles/variables.less';

.Loader {
  position: relative;
  min-height: calc(100 * var(--vh) - @headerHeight * 2 - @bigVerticalSpacingDesktop * 2);

  @media @desktop {
    margin-top: 2.4rem;
  }
}
