@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.WelcomeContent {
  &__Header {
    margin-top: -0.6rem;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 1.6rem 1.6rem 0 0;

    &--with-background {
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media @desktop {
      margin-top: 0;
      margin-bottom: 0.8rem;
    }
  }

  &__Main {
    padding: 0 2.4rem calc(2.2rem + @safeAreaBottom);

    @media @desktop {
      padding: 0 4.8rem 3.2rem;
    }
  }

  &__Thumbnail {
    margin-top: -6rem;
    height: 30rem;

    @media @desktop {
      margin-top: 0;
    }
  }

  &__Title {
    .h3();

    text-align: center;
    color: @white;
    margin-bottom: 1.6rem;
  }

  &__Text {
    .body();

    color: @grey2;
  }

  &__List {
    margin: 1.6rem 0 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__Item {
    display: flex;
    gap: 1.6rem;
  }

  &__ItemTitle {
    .bodyBold();

    color: @white;
  }

  &__Icon {
    align-self: flex-start;
    background-color: @transparentNormal;
    color: @normal;
    padding: 1.2rem;
    border-radius: 5.6rem;
  }
}
