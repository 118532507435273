@import 'styles/variables.less';
@import 'styles/typography.less';

.MultiCheckbox {
  display: flex;
  flex-direction: column;

  &__Title {
    .bodyBold();

    color: @grey3;
    padding: 0 1.6rem 1.3rem;
  }

  &__List {
    display: flex;
    flex-direction: column;
    max-height: 30rem;
    overflow-y: hidden;
    padding: 0;
  }
}
