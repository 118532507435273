@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ModalContent {
  .body();
  padding: 1.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;
  color: @grey2;
}

.ModalFooter {
  display: grid;
  column-gap: 1.6rem;
  grid-template-columns: 1fr 1fr;
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;
  justify-content: space-between;

  &--single {
    grid-template-columns: 1fr;
  }
}
