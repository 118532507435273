@import 'styles/variables.less';
@import 'styles/typography.less';

.ContentCards {
  padding: 2rem 1.6rem 0;

  @media @desktop {
    padding: 0 0 1.6rem;
  }

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  &__Title {
    .bodyBold();

    display: block;
    color: @white;
    padding-bottom: 1.6rem;
  }
}
