@import 'styles/variables.less';
@import 'styles/mixins.less';

.AvatarStatus {
  .avatarAfter();

  position: absolute;
  height: 0.8rem;
  width: 0.8rem;
  right: 0;
  bottom: 0;

  &--online {
    background-color: @success;
  }

  &--away {
    background-color: @gold;
  }

  &--do-not-disturb {
    background-color: @alert;
  }

  &--invisible {
    background-color: @extra1;
  }

  &--size-s,
  &--size-m,
  &--size-l,
  &--size-xl {
    outline-width: 0.2rem;
  }

  &--size-xxl,
  &--size-xxxl {
    outline-width: 0.3rem;
  }

  &--size-mega {
    outline-width: 0.4rem;
  }

  &--size-l,
  &--size-xl {
    height: 1rem;
    width: 1rem;
  }

  &--size-xxl,
  &--size-xxxl {
    height: 1.6rem;
    width: 1.6rem;
  }

  &--size-mega {
    height: 2.4rem;
    width: 2.4rem;
    color: @black;
  }

  &--size-s {
    right: 0.1rem;
    bottom: 0.1rem;
  }

  &--size-m {
    right: 0.2rem;
    bottom: 0.2rem;
  }

  &--size-l,
  &--size-xl {
    right: 0.3rem;
    bottom: 0.3rem;
  }

  &--size-xxl {
    right: 0.4rem;
    bottom: 0.4rem;
  }

  &--size-xxxl {
    right: 0.7rem;
    bottom: 0.7rem;
  }

  &--size-mega {
    right: 0.8rem;
    bottom: 0.8rem;
  }
}
