@import 'styles/typography.less';
@import 'styles/variables.less';

.VerticalRowsTableHeader {
  .comment();

  color: @grey3;

  .TopPerformer {
    min-width: 25rem;
  }

  &__Cell {
    padding: 0 0 0.8rem;
    border-bottom: 0.1rem solid transparent;

    &:first-child {
      padding-left: 0.8rem;
    }

    &--underlined {
      border-bottom: 0.1rem solid @transparentLight3;
    }
  }
}
