@import 'styles/variables.less';
@import 'styles/typography.less';

.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__LeftSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  &__Reactions {
    margin-top: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
  }

  &__EmotionsList {
    margin-top: 1.2rem;
    width: 100%;

    & > div {
      padding: 0;
      height: auto;
    }
  }

  &__EmotionsList {
    margin-top: 1.2rem;
    width: 100%;

    & > div {
      padding: 0;
      height: auto;
    }
  }
}

.Event {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 0.1rem solid transparent;
  border-radius: @borderRadius;
  background-color: @grey1;
  color: @black;
  padding: 1.2rem;
  overflow: hidden;
  width: 100%;

  &--home {
    background-color: var(--home-team-primary-color);
    color: @white;
  }

  &--visitors {
    background-color: var(--visitors-team-primary-color);
    color: @white;
  }

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 2.4rem;
    margin-bottom: 1rem;
  }

  &__Emotions {
    margin-top: 1.2rem;
    width: 100%;

    & > div {
      padding: 0;
      height: auto;
    }
  }

  .LeftSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__Team {
      margin-right: 0.8rem;
    }

    &__Timing {
      .commentBold();
    }
  }

  .RightSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &__Score {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 0.8rem;
      border-radius: 5rem;
      background-color: @transparentLight4;
      padding: 0.4rem 0.8rem;
    }
  }

  &__Message {
    width: 100%;
  }

  &__Stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 0.7rem;
    width: 100%;
  }

  .Points {
    .comment();

    &--isBold {
      .commentBold();
    }
  }

  .Divider {
    .comment();
    padding: 0 0.3rem;
  }

  .PointsLabel {
    width: 2.9rem;
    height: 2.4rem;
    border-radius: 5.2rem;
    padding: 0.4rem 0.8rem 0.4rem 0.6rem;
    border: 0.1rem solid @grey7;
    display: flex;
    align-items: center;
    justify-content: center;
    .commentBold();
    color: @black;

    &--simple {
      background-color: @success;
    }

    &--equal {
      background-color: @gold;
    }

    &--newLeader {
      background-color: @extra1;
      color: @white;
    }
  }
}

.Time {
  .comment();
  color: @grey3;
}
