@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ModalContentWrapper {
  min-height: 14.4rem;
}

.ModalContent {
  padding: 1.6rem 2.4rem 1.6rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 1.6rem;
    left: 2.4rem;
    bottom: 1.6rem;
    width: 0.4rem;
    border-radius: 0.2rem;
    background: @extra1;
  }

  &__Author {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    & :first-child {
      flex-shrink: 0;
    }

    &__Username {
      .commentBold();
      .truncateWithEllipsis();

      color: @grey1;
    }

    &__Realname,
    &__Time {
      .comment();
      .truncateWithEllipsis();

      color: @grey4;
    }
  }
}

.SharedContent {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  &__Title {
    .bodyBold();

    color: @grey1;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  :last-child {
    .comment();

    max-height: 1.6rem;
    overflow: hidden;
    margin: 0;
    color: @grey3;

    ul,
    ol {
      list-style-position: inside;
      padding-left: 0;
    }

    p {
      .lineClamp(1);
    }

    blockquote:first-child {
      max-height: 2.8rem;
    }

    &:has(blockquote:first-child) {
      max-height: 2.8rem;
    }
  }

  &--two-lines {
    :last-child {
      max-height: 3rem;

      p {
        .lineClamp(2);
      }

      blockquote:first-child {
        max-height: 4.2rem;
      }

      &:has(blockquote:first-child) {
        max-height: 4.2rem;
      }
    }
  }

  &--three-lines {
    :last-child {
      max-height: 5rem;

      p {
        .lineClamp(3);
      }

      blockquote:first-child {
        max-height: 6rem;
      }

      &:has(blockquote:first-child) {
        max-height: 6rem;
      }
    }
  }
}

.SharedContentAttachments {
  .comment();

  display: flex;
  gap: 2.4rem;
  color: @grey3;
  margin-top: 0.8rem;

  &__Media,
  &__Files {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
}
