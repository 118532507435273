@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.8rem;

  &__Name {
    .body();
    .truncateWithEllipsis();
    padding-left: 0.8rem;
    color: @grey3;
  }
}
