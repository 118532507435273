@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ReputationPointsCategories {
  .hideScrollbar();
  
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 0.8rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  
  @media @desktop {
    max-height: 50vh;
  }
  
  &--without-padding {
    padding: 0;
  }
  
  &__ReputationHeader {
    height: 5.6rem;
    padding: 0 1.6rem;
    margin-top: 0.8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: @borderRadius;
    background-color: @grey6;
    color: @white;
  }
  
  &__TotalRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    gap: 0.8rem;
  }
  
  &__TotalLabel {
    .comment();
    
    color: @grey1;
    text-align: left;
  }
  
  &__TotalWrapper {
    .h3();
    
    display: flex;
    justify-items: flex-end;
    gap: 1.2rem;
    
    &--total {
      color: @gold;
    }
  }
  
  &__Value {
    color: @white;
    
    &--total {
      color: @gold;
    }
    
    &--predictionPositive {
      color: @success;
    }
    
    &--predictionNegative {
      color: @alert;
    }
  }
  
  &__CategoryRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 0.8rem;
    height: 5.6rem;
    
    &--no-rank {
      padding-right: 6.8rem;
    }
  }
  
  &__Info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: @grey7;
    padding: 0 1.6rem;
    height: 5.6rem;
    border-radius: @borderRadius;
  }
  
  &__CategoryLabel {
    .body();
    
    color: @white;
    text-align: left;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    gap: 1.2rem;
  }
  
  &__CategoryPoints {
    .body();
    
    color: @white;
    text-align: right;
    display: flex;
    justify-items: flex-end;
    gap: 0.8rem;
  }
  
  &__Wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 1.2rem;
    border-bottom: 0.1rem solid @transparentLight3;
  }
  
  &__GlobalHeader {
    .h4();
    height: 3.6rem;
    width: 100%;
    color: @white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    &--with-close-button {
      width: calc(100% - 3.2rem);
    }
  }
}
