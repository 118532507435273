.ScoreboardTableGrid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 4rem;
  align-items: center;

  &__Time {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 0.8rem;
    white-space: nowrap;
  }
}
