@import 'styles/variables.less';

.SwitcherList {
  display: flex;
  align-items: center;
  background: @grey7;
  border-radius: 0.4rem;
  margin: 0;
  padding: 0;
  list-style: none;
}
