@import 'styles/variables.less';
@import 'styles/typography.less';

.ArenaGameItem {
  padding: 1rem 0.8rem;

  &--selected {
    border-bottom: solid 0.2rem @normal;
  }

  &__Header {
    .comment();

    grid-column: span 5;
    color: @grey3;
    text-align: center;

    &--live::before,
    &--halftime::before {
      content: '';
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: @alert;
      margin-right: 0.4rem;
    }
  }

  &__Teams {
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  &__Points {
    .commentBold();

    color: @grey3;

    &--highlighted {
      color: @grey1;
    }
  }

  &__Separator {
    .commentBold();

    color: @grey3;
  }
}
