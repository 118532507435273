@import 'styles/variables.less';
@import 'styles/typography.less';

.CalendarWrapper {
  width: 28rem;
  background: @grey8;
  outline: solid 0.1rem @grey6;
  border-radius: 0.8rem;
  padding: 0.8rem 1.2rem;
}

:global(.react-calendar__month-view__days) {
  background: @grey7;
  margin-bottom: 0.8rem;
}

.Tile {
  .comment();

  background: none;
  height: 3.2rem;
  color: @grey2;

  &:global(.react-calendar__month-view__days__day--neighboringMonth) {
    color: @grey4;
    background: @grey8;
  }

  &:global(.react-calendar__tile--active) {
    .commentBold();

    background: @grey5;
    border-radius: 0.4rem;
  }
}

:global(.react-calendar__navigation) {
  display: flex;
  margin-bottom: 0.8rem;

  &__label span {
    .bodyBold();

    color: @grey1;
  }

  button {
    height: 3.2rem;
    background: none;
    color: @grey3;
  }
}

:global(.react-calendar__month-view__weekdays) {
  height: 3.2rem;
  margin-bottom: 0.8rem;

  &__weekday {
    text-align: center;

    abbr[title] {
      .comment();

      color: @grey4;
      text-decoration: none;
    }
  }
}

&:global(.react-calendar__century-view .react-calendar__tile) {
  font-size: 0;

  span {
    .comment();
  }
}
