@import 'styles/typography.less';
@import 'styles/variables.less';

.TeamPlayerLabel {
  .commentBold();

  color: @grey1;
  line-height: unset;
  display: flex;
  height: 2rem;
  gap: 0.1rem;
  position: relative;

  &--no-color {
    width: fit-content;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 14.7rem;
      padding: 0.1rem;
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  &__Team {
    padding: 0 0.8rem 0.2rem 0;
    border-radius: 14.7rem;
    display: flex;
    gap: 0.4rem;
    white-space: nowrap;
    z-index: 2;
    width: 5.4rem;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;

    img {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
    }
  }

  &__Team:not(:only-child) {
    box-shadow: 0.1rem 0 0 0 @grey6;
    position: relative;
    z-index: 1;
  }

  &__Separator {
    z-index: 2;
    position: absolute;
    left: 4.8rem;
    top: 0;
    width: 0.8rem;
    height: 2rem;
    -webkit-mask-image: url(assets/images/svg/team-player-separator.svg);
    mask-image: url(assets/images/svg/team-player-separator.svg);
  }

  &__Player {
    padding: 0 0.8rem 0 0.8rem;
    border-top-right-radius: 14.7rem;
    border-bottom-right-radius: 14.7rem;
    white-space: nowrap;
    position: absolute;
    left: 0;
    z-index: 1;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;

    &--single {
      border-radius: 14.7rem;
    }
  }

  &__Team + &__Player {
    position: relative;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
