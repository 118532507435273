@import 'styles/variables.less';
@import 'styles/mixins.less';

.PlayerProfileInfoStats {
    display: grid;
    column-gap: 1.2rem;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 1.6rem;

    @media @desktop {
      column-gap: 1.6rem;
      margin: 0;
    }
}
