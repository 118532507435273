@import 'styles/variables.less';
@import 'styles/typography.less';

.Video {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;

  &__Iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
