@import 'styles/variables.less';

.wrapper {
  display: flex;
  flex-direction: row;
  height: 2.4rem;
  width: 9.3rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  border: 0.1rem solid @grey6;
  background-color: @grey6;
}
