@import 'styles/variables.less';

.ContextMenuItem {
  color: @grey3;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.4rem 1.6rem;
  font-size: inherit;
  font-family: inherit;
  width: 100%;

  &:hover {
    background-color: @transparentLight2;
  }

  &--delete {
    color: @alert;
  }

  &--selected {
    color: @normal;
  }

  &--disabled {
    cursor: not-allowed;
    color: @grey5;
  }

  &--active {
    color: @normal;
  }
}

.Text {
  color: @grey3;

  &--delete {
    color: @alert;
  }

}
