@import 'styles/typography.less';
@import 'styles/variables.less';

.HeaderDesktop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: @headerHeight;
  background-color: @grey8;

  &__Left,
  &__Right {
    height: 100%;
    display: flex;
    align-items: center;
    gap: @smallHorizontalSpacingDesktop;
  }

  &__Arrows {
    align-self: stretch;
    border-right: 0.1rem solid @transparentLight3;
  }

  &__Center {
    align-self: center;
    min-width: 20rem;
  }

  &__Title {
    .bodyBold();

    margin: 0 0 0 @smallVerticalSpacingDesktop;
    color: @grey1;
  }

  &__AuthButton {
    height: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;

    &__ContactUs {
      padding-right: 0.5rem;
      border-right: 0.1rem solid @grey7;
    }
  }
}

.AvatarWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  color: @grey1;
  cursor: pointer;
  
  &--athlete {
    min-width: unset;
  }
  
  img, i {
    z-index: 2;
  }
  
  i {
    color: @grey1;
  }
  
  &__Points {
    padding-left: 3.6rem;
    padding-right: 1rem;
    height: 3.2rem;
    border-radius: 3.6rem;
    width: fit-content;
    margin-left: -3.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: @grey7;
  }
  
  &__Rating {
    .commentBold();
  }
}
