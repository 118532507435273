@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.StatsCell {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  height: 100%;
  width: 100%;

  &__Name {
    .truncateWithEllipsis();
    .body();

    color: @normal;
  }
}
