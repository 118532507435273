@import 'styles/variables.less';
@import 'styles/mixins.less';

.ag-theme-total-stats {
  .commentBold();
  .hideScrollbar();

  .ag-theme-base-table {
    --ag-background-color: @transparentLight2;
    --ag-odd-row-background-color: @black;
  }

  color: @grey3;
  height: 100%;
  width: 100%;

  .ag-header-row {
    border: none;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    text-align: left;
  }

  .ag-pinned-left-cols-container {
    :first-child {
      border-right: 0.1rem solid @transparentLight3;
    }
  }

  .ag-cell-value[col-id='threePoints']:has(> div) {
    // need to overwrite inline styles
    width: 10rem !important;
  }

  .ag-header-cell-sortable:not([aria-sort='none']) {
    color: @grey3;
  }

  .ag-cell-sorted {
    // use !important due to library restrictions
    background-color: @transparentLight3 !important;
    color: @grey1;
  }

  .ag-header-cell-text {
    color: @grey3;
  }

  .ag-players-stats-left {
    padding-left: 0.5rem;
    text-align: start;
    display: flex;
    justify-content: flex-start;
  }

  .ag-players-stats-header-name:first-child .ag-header-cell-label {
    justify-content: start;
    padding-left: 0.5rem;
  }
}
