.EmojiPickerReact.epr-dark-theme {
  --epr-bg-color: #282c30;
  --epr-category-label-bg-color: #282c30;
  --epr-search-input-bg-color: #282c30;
  --epr-search-input-bg-color-active: #282c30;
  --epr-search-border-color: #3391cc;
  --epr-emoji-fullsize: 30px;
}

.EmojiPickerReact .epr-search-container input.epr-search {
  border-color: #323a40;
}
