@import 'styles/variables.less';

.LoaderWrapper {
  @media @desktop {
    position: relative;
    width: 100%;
    height: calc(100vh - 4rem - @tabsHeight - @headerHeight);
    margin-top: 2.4rem;
  }
}
