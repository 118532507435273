@import 'styles/typography.less';
@import 'styles/variables.less';

.ScoreboardTableHeader {
  .comment();

  color: @grey3;

  &__Cell {
    padding: 0 0 0.8rem;
    border-bottom: 0.1rem solid transparent;

    &:first-child {
      padding-left: 0.8rem;
    }
  }

  &__Time {
    grid-column: 2;
  }
}
