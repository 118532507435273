@import 'styles/mixins.less';

.Suggestion {
  .hideScrollbar();

  height: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  position: relative;
  padding-bottom: @safeAreaBottom;

  @media @desktop {
    min-height: 28rem;
    max-height: 28rem;
    position: absolute;
    top: @headerHeight;
    background-color: @grey8;
    width: 26rem;
    height: initial;
    z-index: 1;
  }

  &--small {
    min-height: 7rem;
    height: 7rem;
    align-items: center;
    justify-content: center;
    background-color: @grey7;
    border: 0.1rem solid @grey5;
    border-radius: 0.4rem;
  }

  &--center {
    justify-content: center;
  }
}

.EmptyState {
  width: 100%;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;

  &__Text {
    &--label {
      .body();
      color: @grey3;
    }

    &--query {
      .truncateWithEllipsis();
      .bodyBold();

      max-width: 30%;
      color: @grey1;
    }
  }
}

&:global(.is-keyboard-open) {
  .Suggestion {
    margin-bottom: calc(@keyboardHeight);
    padding-bottom: 1.2rem;
  }
}
