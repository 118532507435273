@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Rank {
  .commentBold();
  align-items: center;
  justify-content: center;
  display: flex;
  color: @white;
  
  &--square {
    width: 6rem;
    height: 5.6rem;
    min-width: 6rem;
    border-radius: @borderRadius
  }
  
  &--rectangle {
    min-width: 3.4rem;
    height: 2rem;
    width: fit-content;
    padding: 0 0.6rem 0.2rem 0.6rem;
    border-radius: 0.4rem;
  }
  
  &--level8 {
    background-color: rgb(40, 44, 48);
  }
  
  &--level7 {
    background-color: rgb(41, 65, 54);
  }
  
  &--level6 {
    background-color: rgb(41, 87, 61);
  }
  
  &--level5 {
    background-color: rgb(42, 108, 67);
  }
  
  &--level4 {
    background-color: rgb(43, 130, 74);
  }
  
  &--level3 {
    background-color: rgb(44, 151, 80);
  }
  
  &--level2 {
    background-color: rgb(44, 173, 87);
  }
  
  &--level1 {
    background-color: rgb(45, 194, 93);
  }
}
