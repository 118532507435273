.PlayerProfileFeedBoard {
  padding-top: 2.4rem;
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
}

.LoaderWrapper {
  position: relative;
  margin-top: 2.4rem;
  height: 60vh;
}
