.AvatarWrapper {
  width: 8rem;
  margin: -5.5rem 0 0 1.6rem;
}

.ImagesWrapper {
  position: relative;
  z-index: 1;
  height: 17.2rem;
}
