@import 'styles/variables.less';

.TabsSwitcherSlider {
  display: flex;
  align-items: center;
  background: @grey7;
  border-radius: 6.4rem;
  margin: 0;
  padding: 0;
  list-style: none;
}
