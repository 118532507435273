@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ModalContentWrapper {
  min-height: 14.4rem;
}

.ModalContent {
  padding: 1.6rem 2.4rem 1.6rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;
  width: fit-content;

  &::before {
    content: '';
    position: absolute;
    top: 1.6rem;
    left: 2.4rem;
    bottom: 1.6rem;
    width: 0.4rem;
    border-radius: 0.2rem;
    background: @extra1;
  }
}

.Section {
  display: flex;
  border: 0.1rem solid @grey5;
  border-radius: @borderRadius;
  align-items: center;
  padding: 0.8rem 1.2rem;

  &__Avatar {
    display: flex;
    position: relative;

    > img {
      display: flex;
      position: absolute;
      right: -0.4rem;
      bottom: -0.4rem;
      background: @grey8;
      border-radius: 50%;
    }
  }

  &__Stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.6rem;
    gap: 0.4rem;
  }
}

.GameInfo {
  .comment();
  display: flex;
  justify-content: flex-start;
  color: @grey3;
  height: 1.6rem;

  &__Opponent {
    padding-left: 0.4rem;
    color: @normal;
  }

  &__Score {
    color: @normal;
  }

  &__Dot {
    .comment();
    padding: 0 0.8rem;
  }
}

.PlayerInfo {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.6rem;

  > div:last-child {
    display: none;
  }

  @media @smallMobile {
    grid-template-columns: repeat(6, 1fr);

    > div:last-child {
      display: flex;
    }
  }
}
