@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.PlayerContent {
  display: flex;
  width: 100%;
  gap: 1.2rem;
  padding: 1.2rem;
  border-radius: 0.8rem;
  background-color: @grey8;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }

  &--detail-theme {
    .PlayerContent__Points {
      grid-template-columns: repeat(6, 1fr);
      gap: 0.4rem;

      @media @smallMobile {
        gap: 0.8rem;
      }
    }

    .PlayerContentPoint {
      &:last-of-type {
        display: flex;
      }
    }
  }

  &__Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.4rem;
  }

  &__More {
    .commentBold();

    color: @normal;
  }

  &__Content {
    width: calc(100% - 6.4rem);
  }

  &__Points {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    gap: 0.8rem;

    @media @mobile {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

.PlayerContentAvatar {
  position: relative;
  margin-bottom: 0.7rem;
  border-radius: 50%;
  background-color: @grey1;

  &__Icon {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
  }
}

.PlayerContentName {
  display: flex;
  align-items: center;
  padding-bottom: 0.8rem;

  &__Name {
    .bodyBold();

    color: @white;
    margin-right: 0.8rem;
  }

  &__Date {
    .comment();

    display: block;
    margin-left: auto;
    color: @grey4;
  }
}

.PlayerContentPoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  background-color: var(--player-content-primary-color);

  &:last-of-type {
    display: none;
  }

  @media @mobile {
    &:last-of-type {
      display: flex;
    }
  }

  &__Value {
    .h4();

    font-weight: 700;
    color: var(--player-content-text-color);
  }

  &__Label {
    .comment();

    color: @white;
  }
}
