@import 'styles/typography.less';
@import 'styles/mixins.less';
@import 'styles/variables.less';

.SplitMonth {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 0.1rem;

  @media @desktop {
    margin-bottom: 0.2rem;
  }

  &--opened {
    margin-bottom: 1.6rem;
  }

  &--compact {
    .SplitMonth__SecondColumn {
      &::before {
        height: @gameRowCompactHeight;
      }
    }

    .SplitMonth__Toggle {
      top: 0.3rem;
    }
  }

  &__FirstColumn {
    display: grid;
    align-items: center;
    z-index: 1;
  }

  &__SecondColumn {
    .hideScrollbar();

    display: grid;
    align-items: flex-end;
    column-gap: 1rem;
    width: 100%;
    overflow-x: auto;
    overscroll-behavior-x: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: @gameRowDefaultHeight;
      background: var(--player-primary-color);
      z-index: -1;
    }

    & > div:nth-child(@{gameParamsCount}) {
      padding-right: 1rem;
    }
  }

  &__Toggle {
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    top: 0.2rem;
  }
}

.Label {
  .comment();

  color: var(--player-secondary-text-color);
}
