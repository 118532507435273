.SplitsGamesFeed {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SplitsLoaderWrapper {
  position: relative;
  margin: 2.4rem 0;
}
