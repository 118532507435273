@import 'styles/variables.less';
@import 'styles/typography.less';

.GameStats {
  display: flex;
  flex-direction: column;
  padding: @verticalSpacingMobile @smallHorizontalSpacingMobile;

  @media @desktop {
    padding: 2rem 0 0 0;
  }

  &__Label {
    .bodyBold();
    color: @white;
  }
}
