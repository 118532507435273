@import 'styles/variables.less';

.IconFont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;

  &--size-extraBig {
    font-size: @iconSizeXL;
  }

  &--size-big {
    font-size: @iconSizeM;
  }

  &--size-small {
    font-size: @iconSizeS;
  }

  &--size-extra-small {
    font-size: @iconSizeXS;
  }
}
