@import 'styles/mixins.less';

.NoResults {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Search {
  width: 30rem;
  height: 3.2rem;
  margin-bottom: 1rem;

  div {
    display: none;
  }
}

.AllGifs {
  .hideScrollbar();
  height: 38rem;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.Suggestion {
  width: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

