@import 'styles/variables.less';
@import 'styles/typography.less';

.Countdown {
  display: flex;
  flex-direction: row;
  padding: 0.8rem 2.4rem;
  justify-content: center;
  align-items: center;
  height: 5.6rem;
  gap: 0.8rem;
  align-self: center;

  @media @desktop {
    height: 7.6rem;
    gap: 1.2rem;
  }

  &__GameWillStart {
    width: 100%;
    .commentBold();
    color: @grey1;

    @media @desktop {
      .bodyBold();
    }
  }

  &__Label {
    .commentBold();
    color: @grey1;
    width: 6rem;

    @media @desktop {
      .bodyBold();
      width: 7.1rem;
    }

    &--for-polls {
      width: 10rem;

      @media @desktop {
        .bodyBold();
        width: 12rem;
      }
    }
  }

  &__Items {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;

    @media @desktop {
      gap: 1.2rem;
    }
  }
}
