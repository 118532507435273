@import 'styles/variables.less';

@gamesBehindColIndex: 5;
@conferenceColIndex: 9;
@differentialColIndex: 12;

@games10ptColIndex: 7;
@vs500belowColIndex: 9;

@southeastColIndex: 9;

.ag-theme-standings {
  width: 100%;
  height: 100%;

  .ag-cell-sorted {
    // use !important due to library restrictions
    background-color: @transparentLight3 !important;
  }

  .ag-standings-team-header.ag-header-cell {
    padding-left: 0;
  }

  .ag-cell-diff-positive {
    color: @success;
  }

  .ag-cell-diff-negative {
    color: @alert;
  }

  [aria-colindex='@{gamesBehindColIndex}'] {
    &.ag-cell,
    &.ag-header-cell {
      border-right: solid 0.1rem @grey6;
    }
  }
}

.ag-theme-standings--mode-conference {
  // in conference mode we need border-bottom on 5th and 9th rows to visually separate teams qualified for playoffs
  [row-index='5'],
  [row-index='9'] {
    border-bottom: solid 0.1rem @grey6;
  }
}

.ag-theme-standings--type-standings {
  [aria-colindex='@{conferenceColIndex}'],
  [aria-colindex='@{differentialColIndex}'] {
    &.ag-cell,
    &.ag-header-cell {
      border-right: solid 0.1rem @grey6;
    }
  }
}

.ag-theme-standings--type-expanded {
  [aria-colindex='@{games10ptColIndex}'] {
    &.ag-cell,
    &.ag-header-cell {
      border-right: solid 0.1rem @grey6;
    }
  }
}

.ag-theme-standings--type-vs-division {
  [aria-colindex='@{southeastColIndex}'] {
    &.ag-cell,
    &.ag-header-cell {
      border-right: solid 0.1rem @grey6;
    }
  }
}
