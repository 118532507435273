@import 'styles/variables.less';
@import 'styles/mixins.less';

.BaseSidePanel {
  &__Main {
    .hideScrollbar();

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: @sidePanelWidthDesktop;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform @animationEasing @rightPanelDisappearTime;
    background-color: @grey8;

    @media @desktop {
      top: calc(@headerHeight + @safeAreaTop);
    }
  }

  &--open &__Main {
    transform: translateX(0);
    transition: transform @animationEasing @rightPanelAppearTime;
  }

  &--full-screen &__Main {
    width: 100%;

    @media @desktop {
      width: @sidePanelWidthDesktop;
    }
  }
}
