@import 'styles/variables';
@import 'styles/mixins';

.CreatePostWrapper {
  position: fixed;
  right: 1.2rem;
  bottom: calc(1.6rem + @bottomBarHeight + @safeAreaBottom);
  z-index: @floatingButtonZIndex;
  transform: translateY(@transformMobileFixedElement) translateZ(0);
  transition: transform @scrollSmoothDuration;
  
  @media @desktop {
    right: 5%;
  }
  
  &--has-smart-banner {
    bottom: calc(1.6rem + @bottomBarHeight + @smallDeeplinkBannerHeight);
  }

  button {
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    background-color: @normal;
    color: @white;
  }
}

.CreatePostButtonItem {
  .body();
  
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.6rem;
  
  button {
    background-color: @white ;
    color: @normal;
  }
  
  &--active {
    button {
      background-color: @normal;
      color: @white;
    }
  }
}

.OpenItemsContainer {
  &--open {
    transform: translateY(100%);
    animation: slide-in 0.5s ease forwards;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.BlurContainer {
  &--open {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 12;
  }
}
