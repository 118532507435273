@import 'styles/variables.less';

.SwipeNavigation {
  flex: 1;
  display: flex;
  flex-direction: column;

  &--disabled {
    pointer-events: none;
  }

  &__LeftArrow,
  &__RightArrow {
    position: fixed;
    top: calc(50% - 6.4rem);
    display: flex;
    align-items: center;
    width: 12.8rem;
    height: 12.8rem;
    border-radius: 50%;
    background-color: @transparentLight5;
    z-index: @swipeNavigationArrowZIndex;
    transition: transform @navArrowSmoothDuration;
  }

  &__LeftArrow {
    left: -6.4rem;
    justify-content: flex-end;
    padding-right: 1.6rem;
    transform: translateX(-6.4rem) translateZ(0);
  }

  &__RightArrow {
    right: -6.4rem;
    justify-content: flex-start;
    padding-left: 1.6rem;
    transform: translateX(6.4rem) translateZ(0);
  }
}
