@import 'styles/typography.less';
@import 'styles/variables.less';

.TeamProfile {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 1.6rem;
  
  @media @desktop {
    margin-top: 1.6rem;
  }

  &__Section {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__Header {
    .bodyBold();

    padding: 0.5rem 0 0.5rem 1.6rem;

    @media @desktop {
      padding: 0.5rem 0;
    }
  }

  &__HeaderWrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding-right: 1.6rem;

    @media @desktop {
      padding-right: 0;
    }
  }
}
