.SlideArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;

  &__Prev {
    left: 1.6rem;
  }

  &__Next {
    right: 1.6rem;
  }

  &--withIndex {
    z-index: 5;
  }

  &--hidden {
    display: none;
  }
}
