@import 'styles/variables.less';
@import 'styles/typography.less';

.Settings {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: @black;
  z-index: @settingsZIndex;

  @media @desktop {
    position: static;
    max-width: 120rem;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 5.2rem);
  }
}

.SettingsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.6rem 1.6rem;

  @media @desktop {
    padding: 0;
  }
}

.SettingsHeader {
  display: flex;
  align-items: center;
  padding: calc(1rem + @safeAreaTop) 0 1rem;

  @media @desktop {
    max-width: 50%;
    width: 100%;
    padding: 1.4rem 0;
    margin-left: auto;
    margin-top: 1.6rem;
  }

  &__Title {
    .button();

    padding-left: 0.8rem;
    color: @grey1;

    @media @desktop {
      .h4();

      padding-left: 2rem;
    }
  }
}

.SettingsContent {
  flex: 1;
  padding-top: 0.8rem;

  @media @desktop {
    display: flex;
    padding: 0;
  }
}

.SettingsFooter {
  padding: 1.6rem 0 calc(0.8rem + @safeAreaBottom);

  @media @desktop {
    width: 50%;
    padding: 0.8rem 0 calc(4rem + @safeAreaBottom);
    border-right: 0.1rem solid @grey5;
  }

  &__Text {
    .comment();

    color: @grey4;
  }
}
