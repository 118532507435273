@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Glossary {
  padding: 2.4rem 0;
  width: 100%;

  &__Title {
    .comment();

    color: @grey2;
    border-bottom: 0.1rem solid @transparentLight3;
    padding: 0 1.6rem 1.6rem;
    margin-bottom: 1.6rem;

    @media @desktop {
      .body();

      padding: 0 0 1.6rem;
    }
  }

  &__Content {
    .breakIntoColumns(2);

    margin: 0 1.6rem;

    @media @desktop {
      .breakIntoColumns(3);

      margin: 0;
    }
  }

  &__Item {
    .comment();

    color: @grey4;
    padding-bottom: 0.8rem;
    break-inside: avoid-column;

    &--icon {
      display: flex;
      align-items: center;
    }
  }

  &Item__Left {
    .commentBold();

    color: @grey2;
    margin-right: 0.4rem;
  }
}
