@import 'styles/variables.less';
@import 'styles/mixins.less';

.Game {
  display: flex;
  padding: 0.8rem 1.6rem;
  align-items: center;
  cursor: pointer;

  &__Name {
    .body();

    color: @grey3;
    margin-left: 0.8rem;
  }

  &:hover {
    background: @transparentNormal;
  }
}
