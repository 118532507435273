@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ModalContentWrapper {
  min-height: 19.2rem;
}

.ModalContent {
  padding: 1.6rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;
}

