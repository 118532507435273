@import 'styles/mixins';
@import 'styles/variables.less';
@import 'styles/mixins.less';

.UserAchievements {
  display: flex;
  flex-direction: column;
  padding: 0 @smallHorizontalSpacingMobile;
  
  @media @desktop {
    margin-bottom: 2.4rem;
    padding: 0;
  }
}
