@import 'styles/typography.less';
@import 'styles/variables.less';
@import 'styles/mixins.less';

.StandingsTeam {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  height: 100%;

  & > div {
    flex-shrink: 0;
  }

  &__Data {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  .Name {
    .body();
    .truncateWithEllipsis();

    color: @grey1;
  }
}
