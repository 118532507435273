@import 'styles/variables.less';
@import 'styles/typography.less';

.DaysStreakAchievementBadge {
  position: relative;
  max-width: 7.3rem;
  
  img {
    vertical-align: middle;
  }
  
  &__Info {
    min-width: 3.8rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5rem;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    
    h4, span:first-of-type {
      color: @black;
    }
    
    h4 {
      .h4();
      
      text-align: center;
    }
  }
  
  &__InfoDays,
  &__InfoMax {
    text-align: center;
    text-transform: uppercase;
  }
  
  &__InfoDays {
    .commentBold();
    
    margin-top: -0.4rem;
  }
  
  &__InfoMax {
    background: @black;
    color: @white;
    padding: 0 0.4rem;
    border-radius: 0.4rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6rem;
  }
}
