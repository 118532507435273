@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.BoardSimpleItem {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.2rem;
  gap: 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: @transparentLight2;
  }

  &__Body {
    display: flex;
    flex-direction: column;
  }

  &__Name {
    .commentBold();
    .truncateWithEllipsis();

    color: @grey1;
  }

  &__Followings {
    .comment();

    color: @grey4;
  }
}
