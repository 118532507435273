@import 'styles/typography';
@import 'styles/mixins';
@import 'styles/variables';

.Reports {
  .hideScrollbar();

  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  &__Header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 4.7rem;
    padding: 0.4rem 1.6rem 1rem;
    border-bottom: 0.1rem solid @transparentLight3;
  }

  &__Title {
    .h4();

    width: 100%;
    text-align: center;
    color: @white;
  }

  &__BackButton {
    position: absolute;
    left: 1.6rem;
    top: 0.4rem;
  }

  &__Container {
    flex: 1;
  }
}
