@import 'styles/mixins.less';

.SearchResult {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-height: 75vh;

  &__Items {
    .hideScrollbar();

    overflow-y: scroll;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.LoaderWrapper {
  position: relative;
  padding: 2.4rem;
}
