@import 'styles/variables.less';
@import 'styles/typography.less';

.ConfirmEmailCheckbox {
  display: grid;
  grid-template-columns: 1.6rem 1fr;
  align-items: center;
  gap: 1.2rem;
  height: 4rem;

  li {
    padding: 0;
    height: auto;
  }

  &__Label {
    .comment();

    display: flex;
    flex-wrap: wrap;
    column-gap: 0.4rem;
    color: @grey3;
  }

  &__Link {
    color: @normal;
    text-decoration: none;
  }
}
