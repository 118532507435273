@import 'styles/variables.less';
@import 'styles/mixins.less';

.CollaborationImagesList {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.6rem;
  
  &__Header {
    display: flex;
    height: 3.2rem;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  &__MediaItems {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 0.8rem;
    justify-content: center;
  }
  
  &__TextItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.8rem;
    color: @grey1;
  }
}

.CollaborationItem {
  width: 100%;
  padding-top: 100%;
  position: relative;
  
  &--selected {
    border-radius: @borderRadius;
    border: 0.2rem solid @normal;
  }
  
  &--text {
    gap: 1.2rem;
    background-color: @transparentLight3;
    height: 100%;
    border: 0.4rem solid @normal;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
  }
}

.CollaborationItemVertical {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.TextItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  height: 2.4rem;
  
  &__Username {
    .comment();
    color: @grey3;
  }
}

.TextItemFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5rem;
  height: 2rem;
  
  &__Section {
    .comment();
    
    color: @grey3;
    gap: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Attachment {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.6rem;
}

.TotalItems {
  .comment();
  color: @grey3;
}

.LabelSelected {
  color: @white;
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  z-index: 1;
  background-color: @normal;
  width: 2rem;
  height: 2rem;
  border-bottom-left-radius: @borderRadius;
  border-top-right-radius: @borderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoLabel {
  display: flex;align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.2rem;
  height: 3.2rem;
  opacity: 0.8;
  background-color: @grey8;
  border-radius: 50%;
  color: @white;
}

.UserAvatar {
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Likes {
  .comment();
  
  color: @white;
  gap: 0.4rem;
  position: absolute;
  bottom: 1.2rem;
  left: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Comments {
  .comment();
  
  color: @white;
  gap: 0.4rem;
  position: absolute;
  bottom: 1.2rem;
  right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Shadow {
  height: 5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.TextContent {
  padding: 0.6rem 1.2rem;
  height: 7.4rem;
  max-height: 7.4rem;
  overflow: hidden;
}

.CardWrapper {
  background-color: @transparentLight3;
  height: 100%;
  border-radius: @borderRadius;
  border: 0.2rem solid @normal;
}
