@import 'styles/variables.less';
@import 'styles/typography.less';

.ModalTitle {
  padding: 1.6rem 2.4rem 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.ModalContent {
  padding: 0 2.4rem 0;
  border-bottom: 0.1rem solid @transparentLight3;
}

.ModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1.6rem 2.4rem 2.4rem;

  button:first-of-type {
    width: 14.4rem;
  }
}
