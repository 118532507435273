@import 'styles/variables.less';

.SearchInput {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  form {
    width: 100%;
    height: 4rem;
    align-items: center;
    background-color: @grey8;
    border-radius: 0.4rem;

    @media @desktop {
      height: 3.2rem;
      background-color: transparent !important;
      border-color: @grey3;
    }

    input {
      padding-right: 2.8rem;
      border-radius: 0.4rem;
      border-color: @grey8;
      -webkit-appearance: none;

      @media @desktop {
        background-color: transparent !important;
        border-color: @grey3;

        &::placeholder,
        &:focus::placeholder {
          color: @grey3;
        }
      }
    }
  }

  > button {
    // due to nesting restriction
    margin-left: 1.2rem !important;
  }
}
