@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.MonthHeaderItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: @gameRowDefaultHeight;
  background-color: var(--player-primary-color);

  &--compact {
    width: 6.8rem;
    height: @gameRowCompactHeight;

    .Circle {
      height: 4rem;
      width: 4rem;
    }

    .Arrow {
      bottom: -0.4rem;
    }
  }
}

.Circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border: 0.15rem solid var(--player-text-color);
  border-radius: 50%;
  background-color: var(--player-text-color);
  color: var(--player-primary-color);

  &--close {
    border: 0.15rem solid var(--player-text-color);
    background-color: var(--player-primary-color);
    color: var(--player-text-color);
  }
}

.Text {
  .h4();

  font-weight: 700;
}

.Arrow {
  position: absolute;
  bottom: -0.3rem;
  left: 50%;
  transform: translateX(-50%);
}

.Info {
  .hideScrollbar();

  position: absolute;
  left: 8rem;
  top: 0.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 0.8rem;
  width: calc(100vw - 8rem);
  padding-right: 1.6rem;
  color: var(--player-secondary-text-color);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  @media @desktop {
    width: unset;
  }

  &--compact {
    left: 6.8rem;
    top: 0.5rem;
  }

  &__Text {
    .comment();
  }

  &__Splitter {
    .comment();

    color: @grey3;
  }
}
