@import 'styles/variables.less';

.LeaderboardFilters {
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
  display: flex;
  gap: 0.8rem;
  
  & > div {
    width: 100%;
  }
  
  @media @desktop {
    padding: 0;
    
    & > div {
      min-width: 25rem;
      width: auto;
    }
  }
}
