@import 'styles/variables.less';
@import 'styles/mixins.less';

.ContentEditable {
  .contentEditable();
  .lineClamp(1);

  a {
    .truncateLink();
  }
}
