@import 'styles/variables';
@import 'styles/typography.less';

.QuoteContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1.2rem;
  
  button {
    .commentBold();
    
    margin-top: 0.7rem;
    color: @normal;
  }
  
  .QuoteContent {
    padding: 1.2rem;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    color: @white;
    position: relative;
    border: 0.1rem solid transparent;
    border-radius: @borderRadius;
    background: @grey8;
    width: 100%;
    
    &__Gradient {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -0.2rem;
      border-radius: inherit;
    }
  }
}

.QuoteSign {
  rotate: 180deg;
}



