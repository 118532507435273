@import 'styles/variables.less';
@import 'styles/typography.less';

.StatisticsDayItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: 0 0.8rem;
  border: 0.1rem solid @grey7;
  border-radius: 0.8rem;

  &__Day {
    .commentBold();

    color: @grey1;
    text-transform: uppercase;
  }

  &__Date {
    .comment();

    color: @grey2;
    white-space: nowrap;
  }
}
