@import 'styles/variables.less';
@import 'styles/mixins.less';

.GlobalStats {
  &__Columns {
    .hideScrollbar();

    padding: 1.2rem 1.6rem 1.6rem;
    height: 18rem;
    overflow-x: auto;

    & > div > div:last-child {
      padding-right: 1.6rem;

      @media @desktop {
        padding-right: 0;
      }
    }

    @media @desktop {
      padding: 1.2rem 0 1.6rem;
    }
  }

  &__TeamRow {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.8rem;
    width: 100%;
    gap: 0.8rem;
    border-top-right-radius: @borderRadius;
    border-top-left-radius: @borderRadius;
    background-color: var(--selected-team-color);
    color: inherit;
  }

  &__TeamName {
    color: var(--selected-team-text-color);
  }
}
