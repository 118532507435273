@import 'styles/variables.less';

.Selectors {
  display: flex;
  gap: 1.6rem;
  padding: 0 1.6rem;

  & > div {
    width: calc(50% - 0.8rem);
  }

  @media @desktop {
    padding: 0;

    & > div {
      width: fit-content;
    }
  }
}

.SeasonSection {
  min-height: 14rem;

  :global(.ag-theme-base-table) {
    :global(.ag-pinned-left-header) {
      :global(.ag-header-cell:last-child) {
        padding: 0;

        :global(.ag-header-cell-label) {
          justify-content: center;
        }
      }
    }
  }

  :global(.ag-theme-players-stats .ag-players-stats-left-first) {
    padding-left: 0;

    &:first-child {
      padding-left: 0.5rem;
    }
  }
}
