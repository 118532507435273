@import 'styles/variables.less';
@import 'styles/mixins.less';

.Result {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__Points {
    .comment();
    color: @normal;
    padding: 0 0.4rem;
    display: flex;
    gap: 0.4rem;

    @media @desktop {
      .body();
    }
  }

  &__Matcher {
    .comment();
    color: @grey3;
    padding-right: 0.4rem;
  }
}

.Winner {
  .commentBold();

  @media @desktop {
    .bodyBold();
  }
}
