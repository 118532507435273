@import 'styles/typography.less';

.ScoreboardLineScoreRow {
  color: @grey3;
  transition: color @smallElementDisappearTime @animationEasing;

  &--is-winner {
    color: @grey1;
    transition: color @smallElementAppearTime @animationEasing;
  }

  &__Quarter {
    .commentBold();
  }

  &__Total {
    .bodyBold();

    position: relative;

    @media @desktop {
      .h4();
    }
  }

  &__Arrow {
    transform: rotate(180deg);
    position: absolute;
    top: 0rem;
    right: -2.8rem;
  }
}
