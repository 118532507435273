@import 'styles/mixins.less';
@import 'styles/variables.less';
@import 'styles/typography.less';

.Avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  user-select: none;
  flex-shrink: 0;

  & > img {
    border-radius: 50%;
    object-fit: cover;
  }

  &__Image {
    object-fit: cover;
  }

  &--size-80 {
    width: 8rem;
    height: 8rem;
  }

  &--size-56 {
    width: 5.6rem;
    height: 5.6rem;
  }

  &--size-48 {
    width: 4.8rem;
    height: 4.8rem;
  }
}

.Rank {
  .commentBold();

  position: absolute;
  color: @white;
  top: -0.8rem;
  left: 0;
  -webkit-text-stroke: 0.05rem @black;

  &--first,
  &--second,
  &--third {
    top: -0.6rem;
  }

  &--first {
    color: @gold;
  }

  &--second {
    color: @silver;
  }

  &--third {
    color: @bronze;
  }
}

.Cup {
  position: absolute;
  bottom: -0.4rem;
  left: -0.4rem;
  height: 2rem;
  width: 2rem;
}

.Team {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  border: 0.2rem solid @black;
  border-radius: 50%;

  & > img {
    border-radius: 50%;
    object-fit: cover;
  }

  &--shifted {
    right: 0;
  }
}

.Info {
  .hideScrollbar();

  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-right: 1.6rem;
  overflow-x: auto;
  overflow-y: hidden;
  align-self: flex-start;
  position: absolute;
  left: 6.5rem;
  top: -0.5rem;

  @media @desktop {
    top: -1.4rem;
  }

  &__Name {
    .comment();

    white-space: nowrap;
    color: @grey1;
  }

  &__Jercey {
    .comment();

    white-space: nowrap;
    color: @grey3;
  }

  &__Position {
    .comment();

    white-space: nowrap;
    color: @grey3;
  }

  &__Win {
    .commentBold();

    color: @success;
  }

  &__Lose {
    .commentBold();

    color: @alert;
  }

  &__Splitter {
    .comment();

    color: @grey3;
  }

  &__LiveGamePart {
    .comment();

    display: flex;
    gap: 0.4rem;
    color: @grey3;
  }
}

.Splitter {
  .comment();

  color: @grey3;
}
