@import 'styles/variables.less';
@import 'styles/typography.less';

.LeaderboardRank {
  &__bronze, &__silver, &__gold {
    .bodyBold();
  }
  
  &__bronze {
    color: @bronze;
  }
  
  &__silver {
    color: @silver;
  }
  
  &__gold {
    color: @gold;
  }
}
