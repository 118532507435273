@import 'styles/variables.less';
@import 'styles/typography.less';

.ColumnChart {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: end;

  &__Value {
    .comment();

    text-align: center;
    color: @grey3;
    margin-bottom: 0.4rem;
  }

  &__Background {
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
  }

  &:nth-child(even) {
    .ColumnChart__Background {
      opacity: 0.5;
    }
  }

  &__Avatar {
    display: flex;
    justify-content: center;
    background-color: @transparentLight2;
    padding: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }
}
