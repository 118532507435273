@import 'styles/variables.less';
@import 'styles/typography.less';

.ScoreboardTableRow {
  cursor: pointer;

  & + & {
    margin: 0.1rem 0 0;
  }

  &__Teams {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;

    @media @desktop {
      grid-template-columns: 17.8rem 1fr 17.8rem;
    }
  }

  &__Scores {
    display: grid;
    place-items: center;
  }

  &--scheduled &__Teams {
    grid-template-columns: 1fr;

    @media @desktop {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__Time {
    .comment();

    grid-column: 2;
    color: @grey3;
    white-space: nowrap;
  }
}
