@import 'styles/variables.less';
@import 'styles/typography.less';

.HeadlinesFeed {
  max-width: 70.8rem;
  position: relative;
  margin: 0 auto;
  min-height: calc(
    100vh - @headerHeight - @bottomBarHeight - @shortcutBarHeight -
      @safeAreaTop - @safeAreaBottom
  );

  @media @desktop {
    min-height: calc(
      100vh - @headerHeight - @tabsHeight - @shortcutBarHeight - @toggleTabsHeight - 1.6rem
    );
  }

  &__Item {
    border-bottom: 0.1rem solid @grey6;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__EmptyState {
    min-height: inherit;

    & > div {
      min-height: inherit;
    }
  }
}
