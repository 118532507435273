@import 'styles/variables.less';
@import 'styles/mixins.less';

.IonContent {
  display: block;
  position: relative;
  height: 100%;

  --padding-top: calc(5.2rem + @safeAreaTop);
  --padding-bottom: calc(@bottomBarHeight + @safeAreaBottom);

  &::part(scroll)::-webkit-scrollbar {
    display: none;
  }

  &::part(scroll) {
    right: -1.5rem;
    padding-right: 1.5rem;
    scroll-padding-top: calc(5.2rem + @safeAreaTop);
    scroll-padding-bottom: calc(@bottomBarHeight + @safeAreaBottom);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &--disabled-header {
    --padding-top: var(--cap-safe-area-top);

    &::part(scroll) {
      scroll-padding-top: var(--cap-safe-area-top);
    }
  }

  &--without-safe-area-top {
    --padding-top: 0;

    &::part(scroll) {
      scroll-padding-top: 0;
    }
  }

  &--disabled-mobile-scroll {
    --overflow: hidden;
  }

  &--strict-disabled-scroll {
    --overflow: hidden;
  }

  &--has-navigation {
    --padding-top: calc(10.5rem + @safeAreaTop);

    &::part(scroll) {
      scroll-padding-top: calc(10.5rem + @safeAreaTop);
    }
  }

  &--disabled-bottom-bar {
    --padding-bottom: 0;

    &::part(scroll) {
      scroll-padding-bottom: 0;
    }
  }

  &--disabled-bottom-bar.IonContent--has-navigation {
    --padding-top: calc(10.5rem + @safeAreaTop);

    &::part(scroll) {
      scroll-padding-top: calc(10.5rem + @safeAreaTop);
    }
  }

  &--disabled-bottom-bar:not(&--disabled-header):not(&--has-navigation) {
    --padding-top: calc(5.2rem + @safeAreaTop);

    &::part(scroll) {
      scroll-padding-top: calc(5.2rem + @safeAreaTop);
    }
  }

  &--disabled-bottom-bar.IonContent--has-smart-banner {
    --padding-bottom: @smallDeeplinkBannerHeight;

    &::part(scroll) {
      scroll-padding-bottom: @smallDeeplinkBannerHeight;
    }
  }

  &--has-smart-banner:not(&--disabled-bottom-bar) {
    --padding-bottom: calc(@smallDeeplinkBannerHeight + @bottomBarHeight);

    &::part(scroll) {
      scroll-padding-bottom: calc(@smallDeeplinkBannerHeight + @bottomBarHeight);
    }
  }

  @media @desktop {
    --padding-top: 0;
    --padding-bottom: 0;

    &::part(scroll) {
      scroll-padding-top: 0;
      scroll-padding-bottom: 0;
    }

    &--disabled-mobile-scroll:not(&--strict-disabled-scroll) {
      --overflow: auto;
    }
  }
}

.Refresher {
  top: calc(@headerHeight + @safeAreaTop);
  display: flex !important; // override native ion-refresher styles
  align-items: center;
  height: 12rem;

  &--disabled-header {
    top: 0;
  }

  &--has-navigation,
  &--disabled-bottom-bar:has(&--has-navigation) {
    top: calc(@mobileFullHeaderHeight + @safeAreaTop);
  }

  &--disabled-bottom-bar:not(&--has-navigation) {
    top: calc(@headerHeight + @safeAreaTop);
  }

  &--top-position:not(&--has-navigation) {
    top: @safeAreaTop;
  }
}

.Lottie {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4rem;

  &--bounce-zoom {
    animation: bounce-zoom 400ms ease-out;
  }
}

@keyframes bounce-zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
