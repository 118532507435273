@import 'styles/typography.less';
@import 'styles/variables.less';

.ToggleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  gap: 0.4rem;
  cursor: pointer;

  &__Label {
    .comment();

    color: @grey1;
  }
}

.Toggle {
  position: relative;
  overflow: hidden;
  border-radius: 1.8rem;
  width: 3.2rem;
  height: 2.4rem;
  margin: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__Label {
    display: none;
  }

  &__Input {
    display: none;
  }

  &__Button {
    position: relative;
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;

    &:has(.Toggle__Input)::before {
      transition: @smallElementAppearTime;
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
      border-radius: 50%;
    }

    &:has(.Toggle__Input:checked)::before {
      transform: translateX(0);
    }

    &:has(.Toggle__Input--left)::before {
      transform: translateX(100%);
      box-shadow: 0 0 0 1.6rem @grey3;
    }

    &:has(.Toggle__Input--right)::before {
      transform: translateX(-100%);
    }

    &:has(.Toggle__Input--right:checked)::before {
      box-shadow: 0 0 0 1.6rem @normal;
    }
    
    &--feed {
      width: 1.4rem;
      height: 1.4rem;
      
      &:has(.Toggle__Input)::before {
        background: url('/public/star-filled.svg') no-repeat center;
        background-size: contain;
        background-color: @grey3;
      }
      
      &:has(.Toggle__Input--right:checked)::before {
        box-shadow: 0 0 0 1.6rem @gold;
        background-color: @gold;
      }
    }
  }
}
