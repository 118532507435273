@import 'styles/typography';
@import 'styles/variables';

.Label {
  .body();

  color: @grey3;
  
  &--theme-disabled {
    color: @grey5;
  }
}
