@import 'styles/variables.less';
@import 'styles/mixins.less';

.Player {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  &__Name {
    .body();
    .truncateWithEllipsis();
    color: @normal;
    padding-left: 0.5rem;
    width: calc(@firstPlayersStatsColumnWidth - 2.4rem);
    text-align: start;
  }
}
