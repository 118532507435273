@import 'styles/variables.less';

.StandingsTeamAchievement {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;

  &--star {
    color: @gold;
  }

  &--green-filled {
    background-color: @success;
  }

  &--green {
    border: 0.2rem solid @success;
  }

  &--purple-filled {
    background-color: @extra1;
  }

  &--purple {
    border: 0.2rem solid @extra1;
  }

  &--blue-filled {
    background-color: @normal;
  }

  &--red-filled {
    background-color: @alert;
  }

  &--red {
    border: 0.2rem solid @alert;
  }
}
