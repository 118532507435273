@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Scoreboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 11.5rem;
  height: 4.8rem;
  width: 100%;
  padding: 0.4rem;
  border-radius: 0.8rem;
  background-color: @black;

  &__Scrore {
    .h4();

    font-weight: 700;
    color: @grey1;
    white-space: nowrap;
  }

  &__Separator {
    .bodyBold();

    margin: 0 0.4rem;
    color: @grey3;
  }

  &__Status {
    .comment();

    display: flex;
    align-items: center;
    color: @grey3;
  }

  &__Marker {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.8rem;
    border-radius: 50%;
    background-color: @alert;
  }
}
