@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.SmallAttachments {
  .hideScrollbar();

  position: relative;
  z-index: 2;
  display: flex;
  gap: 0.8rem;
  overflow-x: scroll;
  font-size: 0;

  @media @desktop {
    margin-top: 0;
  }

  &__Poll {
    width: 100%;
    padding: 1.2rem;

    @media @desktop {
      max-width: 40rem;
    }
  }

  &__Images {
    .hideScrollbar();

    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    flex-shrink: 0;
  }

  &__VideoItem {
    display: block;
    width: 11.2rem;
    height: 11.2rem;
    border-radius: @borderRadius;
    object-fit: cover;
    position: relative;

    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
}

.ImageButton {
  display: flex;
  padding-top: 0.8rem;

  &__BadgeWrapper {
    position: relative;
  }

  &:only-child {
    width: 23.2rem;
    height: 12.8rem;

    img {
      width: 100%;
      height: 100%;
    }

    .SmallAttachments__VideoItem {
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }

  &:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.BigAttachments {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-top: 0.8rem;
  position: relative;
  z-index: 2;
}

.Section {
  position: relative;
}

.Badge {
  position: absolute;
  bottom: 2rem;
  left: 0.8rem;

  @media @desktop {
    bottom: 0.8rem;
  }
}
