@import 'styles/variables.less';

.AttachmentsImageItem {
  position: relative;
  width: 100%;
  height: 100%;

  .GifIcon {
    display: flex;
    position: absolute;
    bottom: 5%;
    left: 5%;
    width: 2.8rem;
    height: 1.5rem;
    z-index: 1;
    color: @white;
    background-color: @black;
    opacity: 0.8;
    border-radius: @borderRadius;
    align-items: center;
    justify-content: center;
    background-image: url('assets/images/svg/gif-label.svg');
  }
}

.AttachmentsImage {
  border-radius: @borderRadius;

  &--theme-default {
    width: 6.4rem;
    height: 6.4rem;
    object-fit: cover;
  }

  &--theme-attachments {
    display: block;
    width: 11.2rem;
    height: 11.2rem;
    object-fit: cover;
    position: relative;
  }
  
  &--theme-medium-preview {
    object-fit: contain;
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;
  }
  
  &--theme-small-preview {
    object-fit: contain;
    width: 3.8rem;
    height: 3.8rem;
    cursor: pointer;
  }
}
