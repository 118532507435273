@import 'styles/variables.less';
@import 'styles/mixins.less';

.GameDetailsTopHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  border-top: 0.1rem solid @black;
  height: 6.3rem;

  @media @desktop {
    height: unset;
  }

  &__Statistics {
    .hideScrollbar();

    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  &__Loader {
    @media @desktop {
      height: 8rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__BackButton {
    height: 100%;
    min-width: 5.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
