@import 'styles/variables.less';
@import 'styles/typography.less';

.AuthorCard {
  width: 24rem;
  background: @grey7;
  border-radius: 0.8rem;

  @media @desktop {
    margin-top: 2.4rem;
  }

  &__Title {
    .bodyBold();

    padding: 0.8rem 1.3rem;
    color: @grey3;
  }
}
