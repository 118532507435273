@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ContactUs {
  .hideScrollbar();

  grid-column: 1 / -1;
  background-color: @black;
  z-index: @contactUsZIndex;

  @media @desktop {
    height: calc(100vh - @headerHeight);
    grid-column: 3/-3;
  }

  &__Header {
    display: flex;
    align-items: center;
    padding: 1rem 0;

    @media @desktop {
      padding-top: calc(2.4rem + @safeAreaTop);
    }

    &__Text {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }

    &__Title {
      .button();

      padding-left: 0.8rem;
      color: @grey1;

      @media @desktop {
        .h1();

        color: @white;
        padding: 0 2.4rem;
      }
    }

    &__SubTitle {
      .h4();

      font-weight: 700;
      padding-bottom: 1.6rem;

      @media @desktop {
        color: @grey2;
        padding: 0 2.4rem;
      }
    }
  }

  &__Image {
    display: flex;
    justify-content: center;
  }
}

.ContactUsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.6rem 1.6rem;
}

.ContactUsContent {
  padding: 0.8rem 0 1.6rem;
}

&:global(.is-keyboard-open) {
  .ContactUs {
    &--keyboard {
      margin-bottom: calc(@keyboardHeight - var(--cap-safe-area-bottom));
    }
  }
}

&:global(.is-keyboard-open.android-platform) {
  .ContactUs {
    &--keyboard {
      margin-bottom: @keyboardHeight;
    }
  }
}
