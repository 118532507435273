@import 'styles/variables';
@import 'styles/mixins';

.Comment {
  display: flex;
  padding: 0.8rem 1.6rem;

  &--hover {
    background-color: @transparentLight1;

    @media @tablet {
      border-radius: @borderRadius;
    }

    .Comment__Body:not(:hover):before,
    .Comment__Body:not(:hover):after {
      display: none;
    }
  }

  &:not(&--sub-comment):not(&:last-of-type) {
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
    border-bottom: 0.1rem solid @grey6;
  }

  .LeftSide {
    display: grid;
    gap: 0.4rem;
    width: 100%;
  }

  &--sub-comment {
    padding: 0.8rem;

    &:last-of-type {
      padding-bottom: 0;
    }

    .Comment__Body {
      padding: 0;
      background-color: unset;

      &::after,
      &::before {
        display: none;
      }
    }

    .Comment__UserAvatar {
      margin-right: 0.8rem;
    }

    .Comment__Footer {
      margin-top: 0;
    }
  }

  &--sub-comment-with-highlighted {
    padding: 0.8rem;
    border-radius: @borderRadius;
    background-color: @transparentNormal;

    .Comment__Body {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      background-color: unset;

      &::after,
      &::before {
        display: none;
      }
    }

    .Comment__UserAvatar {
      margin-right: 0.8rem;
    }

    .Comment__UserInfo {
      .TopSection {
        &__UserName {
          .truncateWithEllipsis();

          max-width: 10rem;
          font-size: 1.2rem;
          color: @grey3;
        }
      }
    }
  }

  &__UserAvatar {
    position: relative;
    margin-right: 1.2rem;
    z-index: 1;
  }

  &__Main {
    width: 100%;
    min-width: 0;
  }

  &__Body {
    position: relative;
    width: 100%;
    padding: 1.2rem;
    margin-right: 1.2rem;
    background-color: @grey6;
    border-radius: @borderRadius;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -0.8rem;
      width: 0;
      height: 0;
      border-left: @borderRadius solid transparent;
      border-right: @borderRadius solid @grey6;
      border-top: @borderRadius solid @grey6;
      border-bottom: @borderRadius solid transparent;
    }

    &:hover:before {
      display: none;
    }

    &:hover:after {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1.8rem;
      top: 0;
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      background-color: @black;
      border-radius: 50%;
    }

    &--highlighted {
      background-color: @transparentNormal;

      &::after,
      &::before {
        display: none;
      }
    }

    @media @desktop {
      &:after {
        background-color: @grey8;
      }
    }
  }

  &__Content {
    color: @grey1;

    .ImageButton {
      display: flex;
      padding-right: 0.8rem;
    }
  }

  &__UserInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.6rem;

    .TopSection {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      &__UserNick {
        .truncateWithEllipsis();

        max-width: 15rem;
        margin-right: 0.8rem;
        font-weight: 600;

        @media @desktop {
          max-width: 60%;
        }
      }

      &__UserName {
        .truncateWithEllipsis();

        max-width: 15rem;
        font-size: 1.2rem;
        color: @grey3;

        @media @desktop {
          max-width: 35%;
        }
      }
    }
  }

  &__AttachedImages {
    .hideScrollbar();

    display: flex;
    flex-wrap: nowrap;
    padding-top: 0.4rem;
    overflow-x: scroll;
  }

  &__AttachedImageItem {
    position: relative;
    display: block;
    width: 11.2rem;
    height: 11.2rem;
    border-radius: @borderRadius;
    object-fit: cover;

    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  &__Reactions {
    margin-top: 1.2rem;

    & > div {
      height: auto;
      padding: 0;
    }
  }

  &__Footer {
    display: flex;
    align-items: center;
    padding: 0.8rem 0;

    & > div:first-child {
      display: flex;
      align-items: center;
      color: @grey1;
    }
  }

  &__ReplyBtn {
    margin-left: 1.2rem;
  }

  &__Time {
    margin-left: auto;
    font-size: 1.2rem;
    color: @grey3;
  }

  &__SubComments {
    margin-top: 0.8rem;
  }

  &__ShowMore {
    margin-top: 0.8rem;
  }
}
