@import 'styles/variables.less';
@import 'styles/mixins.less';

.ag-theme-box-scores {
  height: 100%;
  width: 100%;
  .hideScrollbar();

  .ag-theme-base-table {
    --ag-background-color: @transparentLight2;
    --ag-odd-row-background-color: @black;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ag-pinned-left-header .ag-header-cell {
    padding-left: 0;
  }

  .ag-header-row {
    border: none;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    text-align: left;
  }

  .ag-pinned-left-cols-container {
    border-right: 0.1rem solid @transparentLight3;
  }

  .ag-cell-value[col-id='minutes']:has(> div) {
    // need to overwrite inline styles
    width: 100% !important;
    justify-content: flex-start;
  }

  .ag-header-cell-sortable:not([aria-sort='none']) {
    color: @grey3;
  }

  .ag-cell-sorted {
    // use !important due to library restrictions
    background-color: @transparentLight3 !important;
    color: @grey1;
  }

  .ag-header-cell-text {
    color: @grey3;
  }

  .ag-players-stats-left {
    margin-left: 1.6rem;
    text-align: start;
    justify-content: flex-start;
    display: flex;

    @media @desktop {
      padding-left: 0.5rem;
    }
  }

  .ag-players-stats-header-name:first-child .ag-header-cell-label {
    justify-content: start;
    padding-left: 1.6rem;

    @media @desktop {
      padding-left: 0.5rem;
    }
  }
}
