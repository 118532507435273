@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.PostCardFeeds {
  margin-left: auto;
  max-width: 100%;
  padding-left: 0.4rem;

  &--list-theme {
    position: static;
    transform: none;
    background-color: unset;
    padding-left: 0;
    margin-left: auto;

    .PostCardFeeds__Group {
      flex-wrap: wrap;
      max-width: none;
      overflow: unset;
    }
  }

  &--side-panel-theme {
    background-color: @grey8;
  }

  &--expanded {
    .PostCardFeeds__Group {
      .hideScrollbar();

      max-width: calc(100% - 6.4rem);
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &--without-source&--expanded {
    .PostCardFeeds__Group {
      max-width: calc(100% - 3.2rem);
    }
  }

  &__Inner {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
  }

  &__Group {
    display: flex;
    align-items: center;
    max-width: 8.8rem;
    width: 100%;
    gap: 0.8rem;
    overflow: hidden;
  }

  &__Source,
  &__Team,
  &__Player {
    min-width: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
    background-color: @black;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__Team,
  &__Source {
    border-radius: 0.4rem;
  }

  &__Player {
    border-radius: 50%;
  }

  &__Toggler {
    .comment();

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    background-color: @grey7;
    border-radius: 50%;
    color: @grey3;
    outline: none;
  }
}
