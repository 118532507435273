@import 'styles/variables.less';
@import 'styles/mixins.less';

.WelcomePopup {
  .hideScrollbar();

  position: fixed;
  width: 100%;
  height: 100svh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: @introPopupZIndex;
  padding-top: calc(0.8rem + @safeAreaTop);
  background-color: @transparentMobileModal;
  display: flex;

  &__Dialog {
    position: relative;
    width: 100%;
    background-color: @grey8;
    max-width: 56rem;
    margin: auto;
    border-radius: 1.6rem;
    overflow: hidden;
  }

  &__Header {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    z-index: 1;
  }
}
