@import 'styles/variables.less';

.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  z-index: @loaderWrapperZIndex;
  width: 100%;
  height: 100%;

  &--local-loader {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    height: fit-content;
    margin: 0.7rem 0;
  }

  &--with-background {
    border-radius: @borderRadius;
    background-color: @transparentLight2;
  }

  &--shift-left {
    margin-left: -2.8rem;
  }

  &--feed-refresh {
    width: fit-content;
    height: fit-content;
    padding: 1.2rem;
    border-radius: 50%;
    background-color: @black;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -20%);
    transition-duration: 200ms;

    &.Wrapper--show {
      transition-delay: 150ms;
      transform: translate(-50%, 20%);
      opacity: 1;
      visibility: visible;
    }
  }

  &__Loader {
    border: solid @transparentLight3;
    border-radius: 50%;
    animation: loader 1.3s linear infinite;

    &--theme-default {
      border-right-color: @grey5;
    }

    &--theme-blue {
      border-right-color: @normal;
    }

    &--size-m {
      width: 3.2rem;
      height: 3.2rem;
      border-width: 0.4rem;
    }

    &--size-s {
      width: 2.4rem;
      height: 2.4rem;
      border-width: 0.3rem;
    }

    &--size-xs {
      width: 1.6rem;
      height: 1.6rem;
      border-width: 0.2rem;
    }
  }
}

@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}
