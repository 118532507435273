@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';
@import 'styles/icon-variables.less';

.ag-theme-base-table {
  width: 100%;
  height: 100%;

  --ag-background-color: @black;
  --ag-foreground-color: @grey1;
  --ag-data-color: @grey3;
  --ag-odd-row-background-color: @grey8;
  --ag-row-height: 4rem;
  --ag-header-height: 3.2rem;
  --ag-font-family: @mainFont;
  --ag-font-size: 1.5rem;
  --ag-icon-font-family: @icomoon-font-family;
  --ag-icon-font-code-asc: @icon-marker-up;
  --ag-icon-font-code-desc: @icon-marker-expanded;
  --ag-icon-font-code-next: @icon-chevron-right;
  --ag-icon-font-code-previous: @icon-chevron-left;
  --ag-icon-font-code-last: @icon-move-right;
  --ag-icon-font-code-first: @icon-move-left;
  --ag-icon-size: 2rem;
  --ag-grid-size: 0;
  --ag-cell-horizontal-padding: 0;
  --ag-border-color: @grey6;
  --ag-borders: none;
  --ag-header-background-color: @black;
  --ag-row-hover-color: @transparentLight1;

  .ag-root-wrapper {
    overflow: visible;
  }

  .ag-header-cell {
    &-comp-wrapper,
    &-label {
      justify-content: center;
    }
    
    &--highlighted {
      background-color: @transparentLight3;
      color: @grey1;
    }
  }

  .ag-tooltip {
    // use !important due to library restrictions
    top: -3.5rem !important;
    background: @black;
    padding: 0.9rem 1.2rem;
    color: @grey1;
    border-radius: @borderRadius;
    border: 0.1rem solid @grey6;
  }

  .ag-row-highlighted {
    background-color: @transparentLight5;
  }

  .ag-pinned-left-header {
    .ag-header-cell {
      padding-left: 1.6rem;

      &-comp-wrapper,
      &-label {
        justify-content: start;
        color: @grey1;
      }
    }
  }

  .ag-header-cell-sortable .ag-cell-label-container {
    border-bottom: 0.1rem solid @grey5;
    width: min-content;
    padding: 0;
    height: auto;
  }

  .ag-header-row {
    .commentBold();

    color: @grey3;
    border: solid @grey6;
    border-width: 0.1rem 0;
  }

  .ag-header-cell-sortable:not([aria-sort='none']) {
    color: @grey1;
  }

  .ag-cell {
    font-size: 1.2rem;
    text-align: center;
    
    &--highlighted {
      color: @grey1;
      font-weight: 700;
      background-color: @transparentLight3;
    }
  }

  .ag-row {
    border: none;
  }

  .ag-floating-bottom {
    .commentBold();

    border-top: solid 0.1rem @transparentLight3;

    .ag-cell {
      color: @grey1;
    }
  }

  &-pagination {
    .hideScrollbar();

    overflow: auto;
    padding: 1.6rem 0 0 1.6rem;

    @media @desktop {
      padding: 1.6rem 0 0;
    }
  }
}

.ag-theme-base-table--sticky {
  .ag-root {
    overflow: visible;
  }

  .ag-header {
    position: sticky;
    top: 0;
    z-index: @tableHeaderZIndex;
  }

  @media @desktop {
    .ag-header {
      position: static;
    }
  }
}
