@import 'styles/variables.less';
@import 'styles/typography.less';

.Tab {
  position: static;

  &--size-m {
    button {
      .bodyBold();

      padding: 1.6rem 0;
    }
  }

  &--size-s {
    button {
      .commentBold();

      padding: 1.2rem 0;
    }
  }

  button:hover {
    color: @grey1;
  }

  button {
    color: @grey3;
    transition: color @smallElementDisappearTime @animationEasing;
  }

  &::after {
    background: none;
    transition: background @smallElementDisappearTime @animationEasing;
  }

  &--active {
    position: relative;

    &::after {
      display: inline-block;
      content: '';
      width: 100%;
      height: 0.2rem;
      background: @normal;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      transition: background @smallElementAppearTime @animationEasing;
    }

    button {
      color: @grey1;
      transition: color @smallElementAppearTime @animationEasing;
    }
  }
}
