@import 'styles/variables.less';

.QuickPinchZoom {
  position: absolute;
  z-index: 2;
  display: inline-block;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.MainImage {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;

  @media @desktop {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    object-fit: unset;
    object-position: unset;
    height: 100%;
  }
}
