@import 'styles/variables.less';

.GameInfo {
  display: grid;
  grid-template-columns: 13.8rem 1fr 13.8rem;
  background: @grey7;
  min-width: 27.6rem;
  height: 8rem;

  @media @desktop {
    grid-template-columns: 35% 1fr 35%;
    height: 7.2rem;
  }
}
