@import 'styles/variables.less';
@import 'styles/mixins.less';

.Layout {
  position: relative;
  display: flex;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 52rem;
  transform: translateX(@transformContainer) translateZ(0);
  transition: transform @animationEasing @sidebarSmoothDuration, opacity;

  @media @desktop {
    padding-top: @safeAreaTop;
  }

  &__BottomBar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: @stuckHeaderZIndex;
    transform: translateY(@transformMobileBottomBar) translateZ(0);
    transition: transform @scrollSmoothDuration;

    @media @desktop {
      position: static;
    }
  }
}

.LayoutContentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 0;
}

.LayoutTopInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: @stuckHeaderZIndex;
  transform: translateY(@transformMobileHeader) translateZ(0);
  background-color: @black;
  transition: transform @scrollSmoothDuration;
  opacity: @opacityMobileHeader;

  @media @desktop {
    position: static;
    transform: none;
    opacity: 1;
  }
}

.Backdrop {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: @backdropZIndex;
  width: 100%;
  height: 100%;
  background-color: @black;
  opacity: @mobileBackdropOpacity;
  transition: opacity @sidebarSmoothDuration;
  visibility: @mobileBackdropVisibility;

  @media @desktop {
    display: none;
  }
}
