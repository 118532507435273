@import 'styles/typography';
@import 'styles/variables';

.ReportList {
  padding: 1.6rem 2.4rem;

  &__Title {
    .h4();

    padding-bottom: 3.2rem;
    color: @white;
  }
}
