@import 'styles/variables.less';
@import 'styles/mixins.less';

.Player {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  cursor: pointer;

  &__Logo {
    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.4rem;
    object-fit: cover;
    background-color: @extra1;
  }

  &__Name {
    .body();
    color: @grey3;
  }

  &:hover {
    background: @transparentNormal;
  }
}
