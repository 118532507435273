@import 'styles/variables.less';
@import 'styles/typography.less';

.Information {
  display: flex;
  flex-direction: row;
  height: 12.8rem;
  width: 100%;
  align-items: center;
  background-color: @transparentLight2;
  padding: 1rem;

  @media @desktop {
    padding: 0;
    margin-bottom: 0.8rem;
  }

  &__Static {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media @desktop {
      padding: 2rem;
    }

    &--future {
      border-right: 0.1rem solid @transparentLight3;
    }

    .Row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 3.2rem;

      &__Label {
        .commentBold();
        color: @grey1;
        width: 10rem;
        display: flex;
        justify-content: flex-start;
      }

      &__Value {
        .comment();
        color: @grey3;
        justify-content: flex-start;
      }
    }
  }

  &__Dynamic {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;

    @media @desktop {
      padding: 0.8rem 2.4rem;
    }

    .Row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      gap: 1rem;
      height: 3.2rem;
      align-items: center;
      text-align: start;
      :last-child {
        text-align: end;
      }
    }

    .HeaderRow {
      .comment();
      color: @grey3;
      border-bottom: 0.1rem solid @transparentLight3;
    }

    .PointsRow {
      .commentBold();
      color: @grey3;
      :last-child {
        .h4();
      }

      &--winning {
        color: @grey1;
      }
    }
  }
}
