@import 'styles/variables.less';
@import 'styles/typography.less';

.SelectTeamAndPlayer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: @grey8;
  border-radius: 0.8rem;
  padding: 0.8rem 1.2rem;
  gap: 1.2rem;

  &__Title {
    .bodyBold();
    color: @grey1;
    margin-bottom: 1.2rem;
  }
  &__Text {
    .comment();
    color: @grey2;
    margin-bottom: 1.2rem;
  }
  &__DropDowns {
    display: flex;
    border-radius: 14.7rem;
    max-width: max-content;
  }
}
