@import 'styles/variables.less';

.WeekBar {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  column-gap: 0.8rem;

  &__Tabs {
    overflow: hidden;
    display: flex;

    ul {
      min-width: 100%;
      flex-grow: 1;
      transition: transform @animationEasing @middleElementAppearTime;
    }

    &--skip-transition {
      ul {
        transition: none;
      }
    }

    &--prev {
      ul {
        transform: translate(0);
      }
    }

    &--curr {
      ul {
        transform: translate(-100%);
      }
    }

    &--next {
      ul {
        transform: translate(-200%);
      }
    }
  }

  & > div:last-child {
    padding-left: 0.8rem;
    border-left: 0.1rem solid @grey7;
  }
}
