@import 'styles/typography.less';
@import 'styles/variables.less';

.VerticalRowsTable {
  position: relative;
  display: grid;
  padding: 0.8rem 0;
  width: 100%;
  grid-template-columns: 4fr 8.5rem;
  grid-column-gap: 1.2rem;

  &--live,
  &--halftime {
    background-color: @grey8;
  }

  &__Main {
    grid-column: 1;
    grid-row: 1/3;
  }

  &__Rows {
    cursor: pointer;
  }

  &__Links {
    grid-column: 2;
    grid-row: span 2;
    place-self: center;
    border-left: solid 0.1rem @transparentLight3;
    width: 100%;

    @media @desktop {
      grid-row: 2;
      border: none;
    }
  }

  @media @desktop {
    grid-template-columns: 10fr 1fr;
    grid-column-gap: 0;
    grid-template-rows: 2.5rem auto;
  }
}
