@import 'styles/variables.less';

.VectorIcon {
  display: inline-block;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &--size-m {
    height: @iconSizeM;
    width: @iconSizeM;
  }

  &--size-s {
    height: @iconSizeS;
    width: @iconSizeS;
  }

  &--size-xs {
    height: 1.6rem;
    width: 1.6rem;
  }
}
