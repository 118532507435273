@import 'styles/typography.less';
@import 'styles/variables.less';

.DeeplinkBannerWrapper {
  transition: opacity 100ms;

  &--smooth-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

.DeeplinkBannerBottomSheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;

  &__Avatar {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);

    & > div {
      border: 0.4rem solid @grey7;
    }
  }

  &__Title {
    .h4();

    font-weight: 700;
    color: @white;
    padding-bottom: 0.8rem;
  }

  &__Subtitle {
    .comment();

    color: @grey2;
    padding-bottom: 1.2rem;
  }

  button {
    width: 100%;
    margin-top: 1.2rem;
  }
}
