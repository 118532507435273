@import 'styles/variables.less';

.LeaderboardHeaderCell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @grey1;
  
  &__highlighted {
    background-color: @transparentLight3;
  }
  
  &__title {
    margin-left: 0.8rem;
  }
  
  &__asc {
    transform: rotate(180deg);
  }
}
