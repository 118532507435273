@import 'styles/variables.less';
@import 'styles/mixins.less';

.ag-theme-team-stats {
  width: 100%;
  height: 100%;
  .hideScrollbar();

  &--with-colors {
    .ag-header-row {
      border: none;
    }

    .ag-header {
      background-color: var(--team-primary-color);

      @media @desktop {
        border-top-left-radius: @borderRadius;
        border-top-right-radius: @borderRadius;
      }
    }

    .ag-header-cell-text {
      color: var(--team-secondary-color);
    }
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    text-align: left;
  }

  .ag-team-stats-left {
    padding-left: 1.6rem;
    text-align: start;

    @media @desktop {
      padding-left: 1.6rem;
    }
  }

  .ag-team-stats-header-name .ag-header-cell-label {
    justify-content: start;
  }
}
