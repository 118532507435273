@import 'styles/variables.less';
@import 'styles/mixins.less';

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--for-side-panel {
    .hideScrollbar();

    flex: 1;
    padding: 1.4rem;
    overflow-y: auto;

    @media @desktop {
      background-color: @grey8;
    }
  }

  &__Item {
    margin-bottom: 0.8rem;
    padding-bottom: 0.8rem;
    border-bottom: 0.1rem solid @transparentLight3;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    @media @desktop {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
