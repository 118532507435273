@import 'styles/variables.less';
@import 'styles/typography.less';

.BottomSheet {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100vw;
  visibility: hidden;
  z-index: @bottomSheetZIndex;
  pointer-events: auto;

  &--visible {
    visibility: visible;

    .BottomSheet__Backdrop {
      opacity: 1;
    }

    .BottomSheet__Content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &--backdrop {
    top: 0;
    bottom: unset;
    height: 100vh;
  }

  &--bottom-bar {
    bottom: calc(@bottomBarHeight + @safeAreaBottom);
    transform: translateY(@transformMobileBottomBar) translateZ(0);
    transition: transform @scrollSmoothDuration;
  }

  &--bottom-bar-hidden {
    bottom: @safeAreaBottom;
    transform: translateY(0);
  }

  &--container-shadow {
    .BottomSheet__Content {
      box-shadow: 0 -0.4rem 2rem rgba(0, 0, 0, 0.5);
    }
  }

  &__Backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @transparentMobileModal;
    opacity: 0;
    transition-duration: 200ms;
  }

  &__Content {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 0.8rem;
    padding: 1.6rem 1.6rem calc(1.6rem + @safeAreaBottom) 1.6rem;
    border-radius: 1.6rem 1.6rem 0 0;
    background-color: @grey8;
    transform: translateY(100%) translateZ(0);
    opacity: 0;
    transition-duration: 100ms;
  }
}
