@import 'styles/variables.less';

.Row {
  display: grid;
  margin: 0 auto;
  min-width: 0;
  width: 100%;
  height: 100%;

  &--main-content,
  &--main-content-extended {
    padding: @verticalSpacingMobile @smallHorizontalSpacingMobile;
    grid-template-columns: 1fr;

    @media @desktop {
      padding: @bigVerticalSpacingDesktop 0;
      grid-template-columns: 1fr 24rem;
      column-gap: @bigHorizontalSpacingDesktop;
    }
  }

  &--main-content {
    max-width: 86.4rem;
  }
  
  &--profile-content {
    max-width: 69.2rem;
  }

  &--main-content-extended {
    max-width: 100.4rem;
  }

  &--regular {
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.6rem;
    max-width: 100.4rem;

    @media @tablet {
      grid-template-columns: repeat(8, 1fr);
    }

    @media @desktop {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &--no-spacing {
    padding: 0;

    @media @desktop {
      padding: 0;
    }
  }
}

@media @desktop {
  .RowWrapper {
    margin: 0 @smallHorizontalSpacingDesktop;
  }
}
