@import 'styles/variables.less';
@import 'styles/mixins.less';

.PlayerSelect {
  &__container {
    position: relative;
    width: 12.3rem;
  }
  &__control {
    &--is-focused {
      outline-offset: -0.2rem;
      outline: 0.2rem solid @transparentLight4;
    }
  }
  &__menu {
    background-color: @grey8;
    border: 0.1rem solid @grey6;
    width: 20.6rem;
    border-radius: 0.4rem;
    max-height: 29.2rem;
    overflow-y: hidden;

    &-list {
      .hideScrollbar();

      // due to library restrictions
      max-height: 20rem !important;

      @media @desktop {
        max-height: 28rem !important;
      }
    }
  }

  &__indicator {
    // use !important due to library restrictions
    padding: 0 !important;

    button {
      color: @grey1;
    }
  }
  &__value-container {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__input {
    // use !important due to library restrictions
    .commentBold() !important;
    color: @grey1;
    padding-left: 1.2rem;
  }
  &__input-container {
    max-width: 8rem;
    position: absolute;
  }
  &__placeholder {
    .commentBold();
    color: @grey1;
    font-weight: 700;
  }
}
