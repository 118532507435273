@import 'styles/variables.less';
@import 'styles/mixins.less';

.EditablePublicLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 1.6rem;
  
  @media @desktop {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.LinkWrapper {
  text-decoration: none;
  min-height: 5.6rem;
  padding: 0.8rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: @grey8;
  border-radius: 5.6rem;
  color: @white;
  
  &--athlete {
    background-color: var(--player-primary-color);
  }
  
  &__Icon {
    min-width: 4rem;
    min-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    
    img {
      height: 4rem;
      width: 4rem;
      border-radius: 50%
    }
  }
  
  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 9rem);
    height: 100%;
  }
  
  &__Button {
    min-width: 4rem;
  }
}

.Title {
  .body();
  .truncateWithEllipsis();
  
  text-align: center;
  width: 100%;
  color: @grey1;
}

.Description {
  .comment();
  
  text-align: center;
  width: 100%;
  color: @grey4;
}
