@import 'styles/variables.less';
@import 'styles/mixins.less';

.TeamSelect {
  &__container {
    position: relative;
    box-sizing: border-box;
    width: 9.2rem;
    z-index: 1;
  }
  &__control {
    &--is-focused {
      outline-offset: -0.1rem;
      outline: 0.2rem solid @transparentLight4;
    }
  }
  &__menu {
    background-color: @grey8;
    border: 0.1rem solid @grey6;
    width: 29.2rem;
    border-radius: 0.4rem;
    overflow-y: hidden;

    &-portal {
      // use !important due to library restrictions
      z-index: @selectTeamMenuZIndex !important;
    }

    &-list {
      .hideScrollbar();
      // due to library restrictions
      max-height: 20rem !important;

      @media @desktop {
        max-height: 28rem !important;
      }
    }
  }
  &__indicator {
    // use !important due to library restrictions
    padding: 0 !important;

    button {
      color: @grey1;
    }
  }
  &__value-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &--has-value .TeamSelect__input[value=''] {
      // use !important due to library restrictions
      padding-left: 3.8rem !important;
    }
  }
  &__input {
    // use !important due to library restrictions
    .commentBold() !important;
    color: @grey1;
    // use !important due to library restrictions
    padding-left: 1.2rem !important;
  }
  &__input-container {
    max-width: 6rem;
    position: absolute;
  }
  &__placeholder {
    .commentBold();
    color: @grey1;
    font-weight: 700;
    margin-left: 1.2rem;
    white-space: nowrap;
  }
}
