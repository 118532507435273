@import 'styles/variables.less';

.TeamStatsFilters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.2rem;
  row-gap: 2.4rem;

  @media @desktop {
    grid-template-columns: @seasonsSelectWidth @positionSelectWidth;
    column-gap: 1.6rem;
  }
}
