@import 'styles/typography.less';

.Row {
  display: flex;
  flex-direction: row-reverse;

  &--reverse {
    flex-direction: row;
  }
}

.Dot {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: @white;
  margin: 0 0.2rem;
}
