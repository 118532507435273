@import 'styles/typography.less';
@import 'styles/variables.less';

.StandingsTableHeader {
  .bodyBold();

  color: @grey1;
  padding: 2.4rem 0 0.8rem 1.6rem;

  @media @desktop {
    padding: 2.4rem 0 0.8rem 0;
  }
}
