@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ReputationTransactionItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.2rem 1.6rem;
  width: 100%;
  height: 6.2rem;
  border-bottom: 0.1rem solid @transparentLight3;
  text-decoration: none;
  
  &__Description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
  
  &__Values {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1.2rem;
    width: 6rem;
  }
  
  &__Title {
    .body();
    color: @white;
  }
  
  &__Date {
    .comment();
    color: @grey4;
  }
  
  &__Points {
    .body();
    color: @grey3;
    
    &--win {
      color: @success;
    }
    
    &--loss {
      color: @alert;
    }
  }
  
  &__Icon {
    color: @grey3;
    
    &--win {
      color: @success;
    }
    
    &--loss {
      color: @alert;
    }
    
    &--athlete {
      color: @gold;
    }
  }
  
}
