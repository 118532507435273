@import 'styles/variables';

.PlayIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3.2rem;
  height: 3.2rem;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: @white;
  background-color: @black;
  opacity: 0.8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &--for-gallery {
    background-color: unset;
    width: 2rem;
    height: 2rem;
  }
}

.Video {
  display: block;
  border-radius: 0.8rem;
  object-fit: cover;
  position: relative;

  &:fullscreen {
    object-fit: contain;
  }

  &--extra-small {
    width: 3.8rem;
    height: 3.8rem;
  }

  &--small {
    width: 6.4rem;
    height: 6.4rem;
  }

  &--medium {
    width: 11.2rem;
    height: 11.2rem;
  }
}
