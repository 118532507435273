@import 'styles/mixins.less';
@import 'styles/variables.less';
@import 'styles/typography.less';

.UserDetails {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media @desktop {
    padding: 2.4rem 0;
  }
}
