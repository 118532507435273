@import 'styles/variables.less';
@import 'styles/mixins.less';

.ShortcutBarLoader {
  position: relative;
  width: 100%;
  height: 19.2rem;
  
  @media @desktop {
    height: 10.4rem;
  }
}
