@import 'styles/variables.less';
@import 'styles/mixins.less';

.FeedCollaboration {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  &__Header {
    display: flex;
    height: 3.2rem;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  &__MediaItems {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  
  &__MediaAndTextItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  
  &__TextItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  
  &__Actions {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;
    min-height: 6.8rem;
    
    button {
      height: 4.8rem;
      font-weight: initial !important; // to override initial styles
      
    }
  }
  
  &__Approved {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;
    pointer-events: none;
    
    button {
      border-color: @grey7 ;
      color: rgba(253, 185, 39, 1);
      height: 4.8rem;
      
      font-weight: initial !important; // to override initial styles
    }
  }
}

.CollaborationItem {
  display: flex;
  width: 8rem;
  height: 8rem;
  flex-direction: row;
  position: relative;
  
  &__Image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  img {
    width: 8rem;
    height: 8rem;
    border-radius: @borderRadius;
    object-fit: cover;
  }
  
  &--selected {
    border-radius: @borderRadius;
    border: 0.2rem solid @normal;
    
    img {
      width: 7.6rem;
      height: 7.6rem;
      border-radius: @borderRadius;
      object-fit: cover;
    }
  }
}

.CollaborationMediaAndTextItem {
  display: flex;
  width: 100%;
  height: 6.4rem;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: @transparentLight3;
  
  &__Image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  img {
    width: 6.4rem;
    height: 6.4rem;
    border-radius: @borderRadius;
    object-fit: cover;
  }
  
  &--selected {
    border-radius: @borderRadius;
    border: 0.2rem solid @normal;
    
    img {
      width: 6rem;
      height: 6rem;
      border-radius: @borderRadius;
      object-fit: cover;
    }
  }
}

.CollaborationTextItem {
  display: flex;
  width: 100%;
  height: 4rem;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: @transparentLight3;
  border-radius: @borderRadius;
  
  &__Image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.8rem;
  }
  
  &--selected {
    border-radius: @borderRadius;
    border: 0.2rem solid @normal;
  }
}

.TotalItems {
  .comment();
  color: @grey3;
}

.LabelSelected {
  color: @white;
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  z-index: 1;
  background-color: @normal;
  width: 2rem;
  height: 2rem;
  border-bottom-left-radius: @borderRadius;
  border-top-right-radius: @borderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoLabel {
  display: flex;align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.2rem;
  height: 3.2rem;
  opacity: 0.8;
  background-color: @grey8;
  border-radius: 50%;
  color: @white;
}

.ResponseText {
  padding: 0 1.2rem;
  color: @grey1;
  text-align: left;
}

.Hidden {
  display: none;
}
