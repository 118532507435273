@import 'styles/typography.less';
@import 'styles/variables.less';

.SwitcherSliderItem {
  .bodyBold();

  position: relative;
  color: @grey3;
  flex-grow: 1;
  height: 4rem;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__Button {
    background: none;
    color: inherit;
    width: 100%;
  }

  &--active {
    color: @white;
    background-color: @grey5;
    border-radius: 6.4rem;
  }

  &--disabled {
    color: @grey4;
    cursor: not-allowed;
  }
}
