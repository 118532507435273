@import 'styles/variables.less';

.SubmitSection {
  margin: 0 0 @smallVerticalSpacingDesktop;
  padding: 1.6rem @smallVerticalSpacingDesktop;
  border-top: 0.1rem solid @transparentLight3;
  width: 100%;
  
  &--extended {
    display: grid;
    grid-template-columns: 7rem 17rem;
    justify-content: space-between;
  }
}
