@import 'styles/variables.less';

.Arrows {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1.6rem;

  :active {
    animation: bounceAnimation 0.1s 1;
  }

  & > button  {
    color: @grey2;
  }
}

@keyframes bounceAnimation {
  from, to { transform: scale(1, 1); }
  50% { transform: scale(1.1, 1.15); }
}
