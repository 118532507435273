@import 'styles/variables.less';
@import 'styles/mixins.less';

.Point {
  display: flex;
  min-width: 4.3rem;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0.7rem;

  @media @desktop {
    padding-bottom: 0.3rem;
  }

  &--compact {
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
    min-width: 5.8rem;
    padding-bottom: 0.2rem;

    @media @desktop {
      padding-bottom: 0;
    }
  }

  &__Value {
    .h4();

    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: @grey1;
    font-weight: 700;
    text-align: left;

    &--positive {
      color: @success;
    }

    &--negative {
      color: @alert;
    }

    &--for-header {
      .h4();

      color: var(--player-text-color);
    }

    &--compact {
      .bodyBold();
    }

    &--muddy {
      color: @grey3;
    }
  }

  &__Label {
    .comment();

    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: @grey3;

    &--for-header {
      color: var(--player-secondary-text-color);
    }
  }
}
