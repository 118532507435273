@import 'styles/typography.less';
@import 'styles/variables.less';

.LeaderboardUser {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  width: 100%;
  height: 100%;
  
  & > div {
    flex-shrink: 0;
  }
  
  &__pinned {
    justify-content: center;
    
    @media @desktop {
      justify-content: left;
      padding-left: 3.5rem;
    }
  }
  
  &__pinned &__Username {
    @media @desktop {
      .body();
    }
  }
  
  &__Username {
    .comment();
    
    color: @normal;
  }
}
