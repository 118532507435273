@import 'styles/variables.less';

.BottomBar {
  width: 100%;
  height: calc(@bottomBarHeight + @safeAreaBottom);
  padding-bottom: @safeAreaBottom;
  background-color: @black;

  &__ItemsList {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    margin: 0;
    padding: 0.6rem 1.6rem;
    list-style: none;
    position: relative;
  }
}
