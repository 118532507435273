@import 'styles/typography';
@import 'styles/variables';

.Poll__Attachments {
  display: grid;
  margin-bottom: 0.8rem;
  gap: 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));

  &__ImageWrapper {
    position: relative;
    
    span {
      .commentBold();
      
      color: @black;
    }
    
    img {
      border-radius: @borderRadius;
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: middle;
      aspect-ratio: 1 / 1;
    }
  }
  
  &__Marker {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.4rem;
    height: 2rem;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: @borderRadius;
    border: 0.2rem solid @black;
    background: @grey1;
    
    &__Voted {
      background: @normal;
      padding: 0 0.6rem 0 0.2rem;
      
      span {
        color: @white;
      }
      
      i {
        font-size: 1.6rem;
      }
    }
  }
}
