@import 'styles/mixins.less';
@import 'styles/variables.less';
@import 'styles/typography.less';

.SidebarDesktop {
  .sidebar();

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: @smallVerticalSpacingDesktop 1.2rem;
  background: @grey7;
  gap: 1.6rem;

  &__List {
    .hideScrollbar();
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
    width: 7.2rem;
    align-items: center;
  }

  &__Communities {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__Separator {
    display: block;
    width: 3.2rem;
    background: @grey5;
    margin: auto;
  }

  &__SectionLabel {
    .bodyBold();
    color: @grey4;
  }

  &__CommunitiesListItem {
    .hideScrollbar();
    padding: 0;
  }

  &__Follow {
    display: flex;
    width: 100%;

    @media @desktop {
      max-width: 6.4rem;
    }
  }
}
