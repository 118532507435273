.BoxScore {
  display: flex;
  flex-direction: column;
  padding-top: 1.6rem;

  &__Team {
    display: flex;
    flex-direction: column;
  }
}
