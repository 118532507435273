@import 'styles/variables.less';
@import 'styles/typography.less';

.ShortcutHeadlines {
  padding: 0 1.6rem 1.6rem;
  border-top: 0.1rem solid @grey6;

  @media @desktop {
    padding: 0 0 1.6rem;
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0;
  }

  &__Title {
    .bodyBold();

    color: @white;
  }

  &__Body {
    padding: 0 1.6rem;
    border-radius: 0.8rem;
    background-color: @grey8;

    & > div {
      &:not(:last-of-type) {
        border-bottom: 0.1rem solid @grey6;
      }
    }
  }
}
