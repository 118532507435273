@import 'styles/variables.less';
@import 'styles/typography.less';

.EmptyState {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  min-height: 20rem;
  padding: 0 1.6rem;
  border-radius: @borderRadius;
  background-color: @grey8;
  background-image: url('assets/images/no-bookmarks-bg-left.svg'),
    url('assets/images/no-bookmarks-bg-right.svg');
  background-repeat: no-repeat;
  background-position: bottom left, top right;

  &--is-side-panel {
    border-radius: 0;
  }

  &--black {
    background-color: @black;
  }

  &--no-image {
    background-image: none;
  }

  @media @desktop {
    padding: 0;
    border-radius: @borderRadius;
  }

  &__Image {
    margin-bottom: 0.8rem;
  }

  &__Title {
    .bodyBold();
    color: @white;
    padding-bottom: 0.4rem;
    text-align: center;
  }

  &__Message {
    .body();
    color: @grey3;
    text-align: center;
  }

  button {
    margin-top: 2.4rem;
  }
}
