@import './icon-variables.less';

.icon-activity {
  &:before {
    content: @icon-activity;
  }
}
.icon-add-image {
  &:before {
    content: @icon-add-image;
  }
}
.icon-add-link {
  &:before {
    content: @icon-add-link;
  }
}
.icon-add-poll {
  &:before {
    content: @icon-add-poll;
  }
}
.icon-add-reaction {
  &:before {
    content: @icon-add-reaction;
  }
}
.icon-add-user {
  &:before {
    content: @icon-add-user;
  }
}
.icon-add {
  &:before {
    content: @icon-add;
  }
}
.icon-add2 {
  &:before {
    content: @icon-add2;
  }
}
.icon-apple {
  &:before {
    content: @icon-apple;
  }
}
.icon-applications {
  &:before {
    content: @icon-applications;
  }
}
.icon-archive {
  &:before {
    content: @icon-archive;
  }
}
.icon-arena-filled {
  &:before {
    content: @icon-arena-filled;
  }
}
.icon-arena {
  &:before {
    content: @icon-arena;
  }
}
.icon-arrow-down {
  &:before {
    content: @icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: @icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: @icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: @icon-arrow-up;
  }
}
.icon-article {
  &:before {
    content: @icon-article;
  }
}
.icon-attach {
  &:before {
    content: @icon-attach;
  }
}
.icon-auction {
  &:before {
    content: @icon-auction;
  }
}
.icon-ball-filled {
  &:before {
    content: @icon-ball-filled;
  }
}
.icon-ball {
  &:before {
    content: @icon-ball;
  }
}
.icon-basketball-field {
  &:before {
    content: @icon-basketball-field;
  }
}
.icon-block {
  &:before {
    content: @icon-block;
  }
}
.icon-blog {
  &:before {
    content: @icon-blog;
  }
}
.icon-bold {
  &:before {
    content: @icon-bold;
  }
}
.icon-bookmark-filled {
  &:before {
    content: @icon-bookmark-filled;
  }
}
.icon-bookmark {
  &:before {
    content: @icon-bookmark;
  }
}
.icon-broadcast {
  &:before {
    content: @icon-broadcast;
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar;
  }
}
.icon-call-start {
  &:before {
    content: @icon-call-start;
  }
}
.icon-call-stop {
  &:before {
    content: @icon-call-stop;
  }
}
.icon-camera {
  &:before {
    content: @icon-camera;
  }
}
.icon-chart {
  &:before {
    content: @icon-chart;
  }
}
.icon-chat-filled {
  &:before {
    content: @icon-chat-filled;
  }
}
.icon-chat {
  &:before {
    content: @icon-chat;
  }
}
.icon-chevron-big-down {
  &:before {
    content: @icon-chevron-big-down;
  }
}
.icon-chevron-big-up {
  &:before {
    content: @icon-chevron-big-up;
  }
}
.icon-chevron-down {
  &:before {
    content: @icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: @icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: @icon-chevron-right;
  }
}
.icon-chevron-small-right {
  &:before {
    content: @icon-chevron-small-right;
  }
}
.icon-chevron-up {
  &:before {
    content: @icon-chevron-up;
  }
}
.icon-chip {
  &:before {
    content: @icon-chip;
  }
}
.icon-clear {
  &:before {
    content: @icon-clear;
  }
}
.icon-close-filled {
  &:before {
    content: @icon-close-filled;
  }
}
.icon-close {
  &:before {
    content: @icon-close;
  }
}
.icon-close2 {
  &:before {
    content: @icon-close2;
  }
}
.icon-code {
  &:before {
    content: @icon-code;
  }
}
.icon-comment {
  &:before {
    content: @icon-comment;
  }
}
.icon-confirm {
  &:before {
    content: @icon-confirm;
  }
}
.icon-copy {
  &:before {
    content: @icon-copy;
  }
}
.icon-dao {
  &:before {
    content: @icon-dao;
  }
}
.icon-digits-menu {
  &:before {
    content: @icon-digits-menu;
  }
}
.icon-discord {
  &:before {
    content: @icon-discord;
  }
}
.icon-document {
  &:before {
    content: @icon-document;
  }
}
.icon-download {
  &:before {
    content: @icon-download;
  }
}
.icon-downvote-filled {
  &:before {
    content: @icon-downvote-filled;
  }
}
.icon-downvote {
  &:before {
    content: @icon-downvote;
  }
}
.icon-drag {
  &:before {
    content: @icon-drag;
  }
}
.icon-edit {
  &:before {
    content: @icon-edit;
  }
}
.icon-error {
  &:before {
    content: @icon-error;
  }
}
.icon-event-assist {
  &:before {
    content: @icon-event-assist;
  }
}
.icon-event-block {
  &:before {
    content: @icon-event-block;
  }
}
.icon-event-foul {
  &:before {
    content: @icon-event-foul;
  }
}
.icon-event-gain-posession {
  &:before {
    content: @icon-event-gain-posession;
  }
}
.icon-event-game-end {
  &:before {
    content: @icon-event-game-end;
  }
}
.icon-event-game-start {
  &:before {
    content: @icon-event-game-start;
  }
}
.icon-event-injury {
  &:before {
    content: @icon-event-injury;
  }
}
.icon-event-miss {
  &:before {
    content: @icon-event-miss;
  }
}
.icon-event-new-player {
  &:before {
    content: @icon-event-new-player;
  }
}
.icon-event-quarter-end {
  &:before {
    content: @icon-event-quarter-end;
  }
}
.icon-event-rebound {
  &:before {
    content: @icon-event-rebound;
  }
}
.icon-event-steal {
  &:before {
    content: @icon-event-steal;
  }
}
.icon-event-timeout {
  &:before {
    content: @icon-event-timeout;
  }
}
.icon-event-turnover {
  &:before {
    content: @icon-event-turnover;
  }
}
.icon-explore-filled {
  &:before {
    content: @icon-explore-filled;
  }
}
.icon-explore {
  &:before {
    content: @icon-explore;
  }
}
.icon-external-link {
  &:before {
    content: @icon-external-link;
  }
}
.icon-eye-filled {
  &:before {
    content: @icon-eye-filled;
  }
}
.icon-eye-off {
  &:before {
    content: @icon-eye-off;
  }
}
.icon-eye {
  &:before {
    content: @icon-eye;
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook;
  }
}
.icon-feedback-filled {
  &:before {
    content: @icon-feedback-filled;
  }
}
.icon-feedback {
  &:before {
    content: @icon-feedback;
  }
}
.icon-feedback2 {
  &:before {
    content: @icon-feedback2;
  }
}
.icon-feedback3 {
  &:before {
    content: @icon-feedback3;
  }
}
.icon-filter {
  &:before {
    content: @icon-filter;
  }
}
.icon-formatting {
  &:before {
    content: @icon-formatting;
  }
}
.icon-framescreen {
  &:before {
    content: @icon-framescreen;
  }
}
.icon-fullscreen {
  &:before {
    content: @icon-fullscreen;
  }
}
.icon-gif {
  &:before {
    content: @icon-gif;
  }
}
.icon-go-to-message {
  &:before {
    content: @icon-go-to-message;
  }
}
.icon-google {
  &:before {
    content: @icon-google;
  }
}
.icon-group-chat {
  &:before {
    content: @icon-group-chat;
  }
}
.icon-hand {
  &:before {
    content: @icon-hand;
  }
}
.icon-hash {
  &:before {
    content: @icon-hash;
  }
}
.icon-headphones {
  &:before {
    content: @icon-headphones;
  }
}
.icon-heat-map {
  &:before {
    content: @icon-heat-map;
  }
}
.icon-help {
  &:before {
    content: @icon-help;
  }
}
.icon-home-filled {
  &:before {
    content: @icon-home-filled;
  }
}
.icon-home {
  &:before {
    content: @icon-home;
  }
}
.icon-image {
  &:before {
    content: @icon-image;
  }
}
.icon-info {
  &:before {
    content: @icon-info;
  }
}
.icon-insta {
  &:before {
    content: @icon-insta;
  }
}
.icon-italic {
  &:before {
    content: @icon-italic;
  }
}
.icon-join-dao-user {
  &:before {
    content: @icon-join-dao-user;
  }
}
.icon-like-filled {
  &:before {
    content: @icon-like-filled;
  }
}
.icon-like {
  &:before {
    content: @icon-like;
  }
}
.icon-link {
  &:before {
    content: @icon-link;
  }
}
.icon-link2 {
  &:before {
    content: @icon-link2;
  }
}
.icon-list-all {
  &:before {
    content: @icon-list-all;
  }
}
.icon-list-numbered {
  &:before {
    content: @icon-list-numbered;
  }
}
.icon-list {
  &:before {
    content: @icon-list;
  }
}
.icon-location {
  &:before {
    content: @icon-location;
  }
}
.icon-lock {
  &:before {
    content: @icon-lock;
  }
}
.icon-logout {
  &:before {
    content: @icon-logout;
  }
}
.icon-mail {
  &:before {
    content: @icon-mail;
  }
}
.icon-manage-users {
  &:before {
    content: @icon-manage-users;
  }
}
.icon-marker-collapsed {
  &:before {
    content: @icon-marker-collapsed;
  }
}
.icon-marker-expanded {
  &:before {
    content: @icon-marker-expanded;
  }
}
.icon-marker-up {
  &:before {
    content: @icon-marker-up;
  }
}
.icon-media-files {
  &:before {
    content: @icon-media-files;
  }
}
.icon-mention {
  &:before {
    content: @icon-mention;
  }
}
.icon-menu {
  &:before {
    content: @icon-menu;
  }
}
.icon-message-request {
  &:before {
    content: @icon-message-request;
  }
}
.icon-mic-off {
  &:before {
    content: @icon-mic-off;
  }
}
.icon-mic {
  &:before {
    content: @icon-mic;
  }
}
.icon-middle-section {
  &:before {
    content: @icon-middle-section;
  }
}
.icon-minus {
  &:before {
    content: @icon-minus;
  }
}
.icon-missed-call {
  &:before {
    content: @icon-missed-call;
  }
}
.icon-more {
  &:before {
    content: @icon-more;
  }
}
.icon-more2 {
  &:before {
    content: @icon-more2;
  }
}
.icon-move-left {
  &:before {
    content: @icon-move-left;
  }
}
.icon-move-right {
  &:before {
    content: @icon-move-right;
  }
}
.icon-music {
  &:before {
    content: @icon-music;
  }
}
.icon-new-project {
  &:before {
    content: @icon-new-project;
  }
}
.icon-new-task {
  &:before {
    content: @icon-new-task;
  }
}
.icon-news {
  &:before {
    content: @icon-news;
  }
}
.icon-nft-success {
  &:before {
    content: @icon-nft-success;
  }
}
.icon-nft {
  &:before {
    content: @icon-nft;
  }
}
.icon-notifications-filled {
  &:before {
    content: @icon-notifications-filled;
  }
}
.icon-notifications-off {
  &:before {
    content: @icon-notifications-off;
  }
}
.icon-notifications-settings {
  &:before {
    content: @icon-notifications-settings;
  }
}
.icon-notifications {
  &:before {
    content: @icon-notifications;
  }
}
.icon-pause {
  &:before {
    content: @icon-pause;
  }
}
.icon-pin {
  &:before {
    content: @icon-pin;
  }
}
.icon-play-small {
  &:before {
    content: @icon-play-small;
  }
}
.icon-play {
  &:before {
    content: @icon-play;
  }
}
.icon-poll {
  &:before {
    content: @icon-poll;
  }
}
.icon-post {
  &:before {
    content: @icon-post;
  }
}
.icon-profile-blocked {
  &:before {
    content: @icon-profile-blocked;
  }
}
.icon-profile-filled {
  &:before {
    content: @icon-profile-filled;
  }
}
.icon-profile {
  &:before {
    content: @icon-profile;
  }
}
.icon-project-closed {
  &:before {
    content: @icon-project-closed;
  }
}
.icon-promo {
  &:before {
    content: @icon-promo;
  }
}
.icon-proposal {
  &:before {
    content: @icon-proposal;
  }
}
.icon-quote {
  &:before {
    content: @icon-quote;
  }
}
.icon-rankings-filled {
  &:before {
    content: @icon-rankings-filled;
  }
}
.icon-rankings {
  &:before {
    content: @icon-rankings;
  }
}
.icon-rating-filled {
  &:before {
    content: @icon-rating-filled;
  }
}
.icon-rating {
  &:before {
    content: @icon-rating;
  }
}
.icon-reaction {
  &:before {
    content: @icon-reaction;
  }
}
.icon-reddit {
  &:before {
    content: @icon-reddit;
  }
}
.icon-remove {
  &:before {
    content: @icon-remove;
  }
}
.icon-repeat {
  &:before {
    content: @icon-repeat;
  }
}
.icon-report-user {
  &:before {
    content: @icon-report-user;
  }
}
.icon-report {
  &:before {
    content: @icon-report;
  }
}
.icon-request {
  &:before {
    content: @icon-request;
  }
}
.icon-right-panel {
  &:before {
    content: @icon-right-panel;
  }
}
.icon-role {
  &:before {
    content: @icon-role;
  }
}
.icon-rotate {
  &:before {
    content: @icon-rotate;
  }
}
.icon-screen {
  &:before {
    content: @icon-screen;
  }
}
.icon-search {
  &:before {
    content: @icon-search;
  }
}
.icon-search2 {
  &:before {
    content: @icon-search2;
  }
}
.icon-send-filled {
  &:before {
    content: @icon-send-filled;
  }
}
.icon-send {
  &:before {
    content: @icon-send;
  }
}
.icon-settings {
  &:before {
    content: @icon-settings;
  }
}
.icon-share-screen {
  &:before {
    content: @icon-share-screen;
  }
}
.icon-share {
  &:before {
    content: @icon-share;
  }
}
.icon-shop {
  &:before {
    content: @icon-shop;
  }
}
.icon-smile {
  &:before {
    content: @icon-smile;
  }
}
.icon-snapchat {
  &:before {
    content: @icon-snapchat;
  }
}
.icon-social-feed {
  &:before {
    content: @icon-social-feed;
  }
}
.icon-sorting {
  &:before {
    content: @icon-sorting;
  }
}
.icon-sound-off {
  &:before {
    content: @icon-sound-off;
  }
}
.icon-sound {
  &:before {
    content: @icon-sound;
  }
}
.icon-speech {
  &:before {
    content: @icon-speech;
  }
}
.icon-spoiler {
  &:before {
    content: @icon-spoiler;
  }
}
.icon-star-filled {
  &:before {
    content: @icon-star-filled;
  }
}
.icon-star {
  &:before {
    content: @icon-star;
  }
}
.icon-sticker {
  &:before {
    content: @icon-sticker;
  }
}
.icon-stop {
  &:before {
    content: @icon-stop;
  }
}
.icon-strikethrough {
  &:before {
    content: @icon-strikethrough;
  }
}
.icon-super-like-filled {
  &:before {
    content: @icon-super-like-filled;
  }
}
.icon-telegram {
  &:before {
    content: @icon-telegram;
  }
}
.icon-thread {
  &:before {
    content: @icon-thread;
  }
}
.icon-tik-tok {
  &:before {
    content: @icon-tik-tok;
  }
}
.icon-time {
  &:before {
    content: @icon-time;
  }
}
.icon-timer {
  &:before {
    content: @icon-timer;
  }
}
.icon-treasure {
  &:before {
    content: @icon-treasure;
  }
}
.icon-trending {
  &:before {
    content: @icon-trending;
  }
}
.icon-twitter {
  &:before {
    content: @icon-twitter;
  }
}
.icon-unpin {
  &:before {
    content: @icon-unpin;
  }
}
.icon-upvote-filled {
  &:before {
    content: @icon-upvote-filled;
  }
}
.icon-upvote {
  &:before {
    content: @icon-upvote;
  }
}
.icon-user-accepted {
  &:before {
    content: @icon-user-accepted;
  }
}
.icon-users {
  &:before {
    content: @icon-users;
  }
}
.icon-video-camera-off {
  &:before {
    content: @icon-video-camera-off;
  }
}
.icon-video-camera {
  &:before {
    content: @icon-video-camera;
  }
}
.icon-view-cards {
  &:before {
    content: @icon-view-cards;
  }
}
.icon-wallet {
  &:before {
    content: @icon-wallet;
  }
}
.icon-whatsup {
  &:before {
    content: @icon-whatsup;
  }
}
.icon-window {
  &:before {
    content: @icon-window;
  }
}
.icon-winner-filled {
  &:before {
    content: @icon-winner-filled;
  }
}
.icon-winner {
  &:before {
    content: @icon-winner;
  }
}
.icon-write-message {
  &:before {
    content: @icon-write-message;
  }
}
.icon-youtube {
  &:before {
    content: @icon-youtube;
  }
}

