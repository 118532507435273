@import 'styles/variables.less';

.Tabs {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  gap: 0.8rem;
  padding: 1.6rem 1.6rem 0.4rem;

  @media @desktop {
    gap: 1.6rem;
  }
}
