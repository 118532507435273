@import 'styles/mixins.less';
@import 'styles/variables.less';
@import 'styles/typography.less';

.PlayerRankings {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - @bottomBarHeight - @smallDeeplinkBannerHeight - @headerHeight);

  @media @desktop {
    padding: 2.4rem 0;
  }

  &__Header {
    @media @desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 1.8rem;
    }
  }
}

.LeftSide {
  display: flex;
  align-items: flex-end;

  &__Title {
    .h3();

    color: @grey1;
    padding-right: 1.6rem;
  }

  &__Date {
    .body();

    color: @grey3;
  }
}

:global(.ios-platform),
:global(.android-platform) {
  .PlayerRankings {
    min-height: calc(100vh - @bottomBarHeight - @safeAreaBottom - @headerHeight);
  }
}

.CustomSection {
  display: flex;
  flex-direction: column;
  
  &__Title {
    .bodyBold();
  }
  
  &__Description {
    .comment();
    color: @grey3;
  }
}
