@import 'styles/variables.less';

.Header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1rem 2rem;

  &__Title {
    color: @white;
  }
  &__Button {
    margin: 0 0 0 0.8rem;
  }
}
