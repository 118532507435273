@import 'styles/mixins.less';

.MenuList {
  .hideScrollbar();

  position: relative;
  overflow: hidden;
  overflow-y: auto;
  max-height: 20rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
