@import 'styles/variables.less';

.LoaderWrapper {
  position: relative;
  height: @mobileProfileThumbnailHeight;

  @media @desktop {
    height: @desktopProfileThumbnailHeight;
  }
}

.TeamProfileThumbnail {
  width: 100%;
  height: @mobileProfileThumbnailHeight;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  @media @desktop {
    height: @desktopProfileThumbnailHeight;
  }
}
