@import 'styles/variables.less';
@import 'styles/typography.less';

.PlayerCard {
  height: 100%;
  background-color: @grey7;
  border-radius: @borderRadius;
  color: @white;
  min-width: unset;
  overflow: visible;

  &__Background {
    position: relative;
    display: flex;
    justify-content: center;
    border-bottom: solid 0.1rem @grey8;
    border-top-left-radius: @borderRadius;
    border-top-right-radius: @borderRadius;
    overflow: hidden;
  }

  &__JerseyPositionWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    position: absolute;
    top: 1.6rem;
    left: 1.2rem;

    @media @desktop {
      left: 1.6rem;
    }
  }

  &__Jersey {
    .bodyBold();

    display: flex;
    align-items: start;
    color: @grey3;
  }

  &__JerseyNumber {
    .h4();
  }

  &__Position {
    .comment();
  }

  &__Image {
    height: 14.4rem;
    max-width: 100%;
    object-fit: cover;
  }

  &__Info {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 1.6rem 1.2rem;
    height: calc(100% - 14.4rem);
    border-bottom-left-radius: @borderRadius;
    border-bottom-right-radius: @borderRadius;

    @media @desktop {
      padding: 1.6rem;
    }
  }

  &__Name {
    .bodyBold();

    text-align: start;
    white-space: normal;
  }

  &__Physical {
    display: flex;
    justify-content: space-between;

    &Item {
      .comment();

      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      align-items: center;

      @media @desktop {
        flex-direction: row;
        gap: 0.4rem;
      }

      &Label {
        color: @grey3;
      }
    }
  }

  &__Stats {
    margin-top: 0.8rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;

    @media @desktop {
      grid-template-columns: repeat(4, 1fr);
    }

    &--two-rows {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
