@import 'styles/typography.less';

.Home {
  .comment();

  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 1.6rem;
  gap: 0.8rem;

  svg {
    width: 100%;
    height: 8rem;
    right: 0;
    top: 0;
    position: absolute;
    transform: rotate(180deg);

    @media @desktop {
      height: 7.2rem;
    }
  }

  &__Items {
    display: flex;
    flex-direction: column;
    height: 6rem;
    justify-content: center;
    gap: 0.8rem;
    z-index: 1;

    .HomeSecondRow {
      display: flex;
      height: 1.2rem;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 0.4rem;
    }

    .HomeInfo {
      z-index: 1;
      display: flex;
      padding-right: 0.8rem;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      &__Row {
        display: flex;
        gap: 0.4rem;
      }

      &__Name {
        .bodyBold();
      }

      &__Results {
        .comment();
      }
    }
  }
}

.Visitor {
  .comment();

  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1.6rem;
  gap: 0.8rem;

  svg {
    width: 100%;
    height: 8rem;
    left: 0;
    top: 0;
    position: absolute;

    @media @desktop {
      height: 7.2rem;
    }
  }

  &__Items {
    display: flex;
    flex-direction: column;
    height: 6rem;
    justify-content: center;
    gap: 0.8rem;
    z-index: 1;

    .VisitorSecondRow {
      display: flex;
      height: 1.2rem;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.4rem;
    }

    .VisitorInfo {
      z-index: 1;
      display: flex;
      padding-left: 0.8rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &__Row {
        display: flex;
        gap: 0.4rem;
      }

      &__Name {
        .bodyBold();
      }

      &__Results {
        .comment();
      }
    }
  }
}

.Winner {
  padding: 0 0.4rem;

  @media @desktop {
    padding: 0 1.2rem;
  }
}

.TeamFirstRow {
  display: flex;
  align-items: center;
  height: 4rem;

  &--expand {
    height: 5.4rem;
  }
}
