@import 'styles/variables.less';
@import 'styles/typography.less';

.ScoreboardTableLinks {
  position: relative;
  display: grid;
  place-items: center;
  height: 7.6rem;

  @media @desktop {
    height: 100%;
  }

  &__Item {
    &:nth-child(n + 2) {
      margin: 1.1rem 0 0;
    }
  }

  &__Status {
    .commentBold();

    color: @grey1;
    white-space: nowrap;

    &--live {
      padding: 0.4rem 0.8rem;
      background-color: @alert;
      border-radius: 0.4rem;
    }
  }

  @media @desktop {
    padding: 0.8rem;
  }
}
