@import 'styles/variables.less';
@import 'styles/typography.less';

.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem 0;
  flex: 1;

  @media @desktop {
    background-color: @grey8;
    border-top: 0.1rem solid @grey6;
  }

  &__Filters {
    display: flex;
    flex-direction: column;
  }

  &__Search {
    padding: 0 1.6rem 1.6rem;
  }
}

.Button {
  width: 100%;
}

.Types {
  padding-top: 1rem;
}

.Buttons {
  border-top: 0.1rem solid @grey6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;

  & > div:first-child {
    padding-right: 0.8rem;
  }

  @media @desktop {
    padding: 0.8rem 1.6rem;
  }
}
