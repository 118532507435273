@import 'styles/variables.less';
@import 'styles/typography.less';

.CardWrapper {
  display: flex;
  flex-direction: column;
  min-width: 23.5rem;
  height: 8.8rem;
  border: 0.1rem solid @grey5;
  border-radius: @borderRadius;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1.2rem;

  &--last {
    margin: 0;
  }

  &__Label {
    .commentBold();
    color: @grey3;
    border-bottom: 0.1rem solid @grey5;
    text-align: start;
    width: 100%;
    padding: 0.3rem 0.8rem;
  }

  &__Profile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.8rem 1.2rem;
    width: 100%;
  }

  .PlayerInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0.8rem;

    &__FirstRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__Points {
      .bodyBold();
      color: @white;
    }

    .Name {
      .body();

      color: @normal;
    }

    .Position {
      .comment();
      color: @grey3;
      padding-left: 0.7rem;
    }
  }
}
