@import 'styles/variables.less';
@import 'styles/typography.less';

.ModalTitle {
  padding: 1.6rem 2.4rem 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  &__Logo {
    width: 4.8rem;
    height: 4.8rem;
    object-fit: cover;
    border-radius: 50%;
  }

  &__Name {
    .bodyBold();

    color: @grey1;
  }
}

.ModalContent {
  padding: 0 2.4rem 1.6rem 2.4rem;
  color: @grey2;
}

.ModalFooter {
  display: grid;
  column-gap: 1.6rem;
  grid-template-columns: 1fr 14.4rem 14.4rem;
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;

  button:first-of-type {
    grid-column: 2;
  }
}
