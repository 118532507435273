@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.RankingsPlayerItem {
  min-width: 5.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: visible;
  height: 10rem;

  @media @desktop {
    &--large {
      min-width: none;
      width: 8.2rem;
      height: 12.2rem;
    }
  }

  &__Avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5rem;
  }

  &__FullName {
    .comment();
    .truncateWithEllipsis();

    max-width: 8rem;
    padding-bottom: 0.3rem;
    text-align: center;
    color: @grey3;
  }

  &__Points {
    .commentBold();

    color: @white;
  }
}
