@import 'styles/variables.less';
@import 'styles/mixins.less';

.TeamControl {
  position: relative;
  display: flex;
  border-radius: 14.7rem;
  color: @grey1;
  align-items: center;

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &__InnerWrapper {
    display: flex;
    background-color: @grey7;
    border-radius: 14.7rem;
    width: 100%;
  }
}
