@import 'styles/variables.less';

.Wrapper {
  position: relative;
  margin-top: 2.4rem;
  min-height: 42rem;

  @media @desktop {
    min-height: 48rem;
  }
}
