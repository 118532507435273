@import 'styles/variables';
@import 'styles/typography';
@import 'styles/mixins';

.BookmarksFooter {
  .truncateWithEllipsis();

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.2rem 0 0.8rem;
  border-bottom-right-radius: @borderRadius;
  border-bottom-left-radius: @borderRadius;

  @media @desktop {
    padding: 1.2rem 0 0;
  }

  &--comment-theme {
    padding-left: 5.2rem;

    @media @desktop {
      padding-left: 4.8rem;
      padding-bottom: 0.8rem;
    }
  }

  &__Time {
    .comment();

    color: @grey4;
  }

  &__Title {
    .commentBold();
    .truncateWithEllipsis();

    color: @grey3;
    padding-left: 0.4rem;
  }
}
