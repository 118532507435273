@import 'styles/variables.less';

.SidebarCommunitiesList {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media @desktop {
    width: 7.2rem;
    align-items: center;
    margin: 0.5rem 0;
  }
}
