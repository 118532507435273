@import 'styles/variables.less';
@import 'styles/typography.less';

.StatsLeaders {
  margin-bottom: 2.4rem;

  @media @desktop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
  }

  &__Section {
    display: grid;
    row-gap: 1.6rem;
  }

  &__Header {
    .bodyBold();

    color: @grey1;
    margin-top: 1.6rem;
    padding-left: 1.6rem;

    @media @desktop {
      margin-top: 2.4rem;
      padding-left: 0;
    }
  }

  &__EmptyState {
    height: 35rem;
    margin: 1.6rem 1.6rem 0;

    @media @desktop {
      margin: 2.4rem 0;
    }
  }
}
