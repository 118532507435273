@import 'styles/variables.less';

.Iframe {
  width: 100%;
  height: 100%;
  z-index: 3;

  &--extra-small {
    width: 3.8rem;
    height: 3.8rem;
    pointer-events: none;
  }

  &--small {
    width: 6.4rem;
    height: 6.4rem;
    pointer-events: none;
  }

  &--medium {
    width: 11.2rem;
    height: 11.2rem;
    pointer-events: none;
  }

  &--full {
    max-width: 100%;
    max-height: 100%;

    @media @desktop {
      max-width: 90%;
      max-height: 90%;
    }
  }
}
