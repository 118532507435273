@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.IntroPopup {
  .hideScrollbar();

  position: fixed;
  width: 100%;
  height: 100svh;
  padding-top: calc(0.8rem + @safeAreaTop);
  background-color: @black;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: @introPopupZIndex;

  @media @desktop {
    display: flex;
    background-color: @transparentMobileModal;
    padding-bottom: calc(0.8rem + @safeAreaBottom);
  }

  &__Dialog {
    position: relative;
    width: 100%;
    padding-top: 3.2rem;
    background-color: @black;
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    overflow: hidden;

    @media @desktop {
      max-width: 56rem;
      margin: auto;
      padding-top: 6.4rem;
      border-radius: 1.6rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 55vh;
      background: linear-gradient(180deg, #0493ec 0%, @black 100%);
      z-index: 1;
    }
  }

  &__Content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    min-height: calc(100svh - 4rem - @safeAreaTop);

    @media @desktop {
      min-height: 62.4rem;
    }
  }

  &__Picture {
    padding-bottom: 5.6rem;

    @media @desktop {
      padding-bottom: 4.8rem;
    }
  }

  &__Image {
    width: 100%;
  }

  &__Title {
    .h2();

    padding-bottom: 2.4rem;
    text-align: center;
  }

  &__Text {
    .body();

    max-width: 35rem;
    padding-bottom: 2.4rem;
    text-align: center;
    margin: 0 auto;

    @media @desktop {
      max-width: 43rem;
    }
  }

  &__Buttons {
    margin-top: auto;
    padding: 0 1.6rem calc(0.8rem + @safeAreaBottom);

    @media @desktop {
      padding: 0 2.4rem calc(1.6rem + @safeAreaBottom);
    }

    button {
      width: 100%;

      &:first-of-type {
        margin-bottom: 1.6rem;

        @media @desktop {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}
