@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.AuthorCardMore {
  padding: 0.8rem 1.2rem 1.2rem;

  &__Heading {
    .commentBold();

    margin-bottom: 0.8rem;
    color: @grey3;

    &:not(:first-child) {
      margin-top: 1.6rem;
    }
  }

  &__About {
    .comment();

    color: @grey1;
  }

  &__Friends {
    .hideScrollbar();

    display: flex;
    overflow: auto;

    & > * {
      flex-shrink: 0;
    }

    & *:not(:first-child) {
      outline: 0.2rem solid @grey7;
      margin-left: -0.8rem;
    }
  }

  &__Communities {
    .hideScrollbar();

    display: flex;
    overflow: auto;
    gap: 0.8rem;

    & > * {
      flex-shrink: 0;
    }

    img {
      width: 3.2rem;
      height: 3.2rem;
      object-fit: cover;
      border-radius: 50%;
    }

    &--common {
      display: flex;
      gap: 0.8rem;
      overflow: hidden;

      & > div {
        height: 3.3rem;
        width: 3.3rem;
        background: @gradient;
        border-radius: 50%;
      }

      img {
        transform: translate(0.05rem, 0.05rem);
      }
    }

    &--other {
      display: flex;
      gap: 0.8rem;

      &:not(:first-child):before {
        content: '';
        height: 100%;
        width: 0.1rem;
        background: @grey6;
      }
    }
  }
}
