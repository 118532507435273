@import 'styles/typography.less';
@import 'styles/variables.less';
@import 'styles/mixins.less';

.BaseModal {
  .hideScrollbar();

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  padding: 4rem 0.8rem;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &__Tone {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: @black;
    opacity: 0.6;
  }

  &__Window {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: @grey8;
    border-radius: 1.6rem;
    margin: auto;

    &--s {
      max-width: 35rem;
    }

    &--m {
      max-width: 56rem;
    }

    &--l {
      max-width: 80rem;
    }
  }

  &__Header {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    padding: 1.6rem 2.4rem;
    border-bottom: 0.1rem solid @transparentLight3;

    &--transparent {
      padding: 1.6rem 2.4rem 0 0;
      border-bottom: none;
    }

    &__Button {
      width: 3.2rem;
      height: 3.2rem;
    }
    
    &__Title {
      .bodyBold();

      flex: 1;
      text-align: center;

      @media @desktop {
        .h4();
      }
    }
  }

  &__Content {
    .hideScrollbar();

    position: relative;
    max-height: 68.3rem;
    overflow: auto;

    &--scroll-disabled {
      overflow: unset;
    }
    
    &--l {
      max-height: 74rem;
    }
  }

  &--fullscreen {
    padding: 0;
    display: block;

    .BaseModal__Window {
      display: grid;
      grid-template-rows: auto 1fr;
      max-width: none;
      min-height: 100%;
      margin: 0;
      background-color: @black;
      border-radius: 0;
    }

    .BaseModal__Header {
      grid-row: 1;
      padding: calc(@safeAreaTop + 1.6rem) 2.4rem 1.6rem 2.4rem;
    }

    .BaseModal__Content {
      position: static;
      display: flex;
      flex-direction: column;
      max-height: none;
      height: 100%;
    }
  }
}

:global(.ios-platform),
:global(.android-platform) {
  .BaseModal {
    &--fullscreen {
      &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: @safeAreaTop;
        background-color: @black;
      }
    }
  }
}

:global(.is-keyboard-open) {
  .BaseModal {
    &__Content {
      padding-bottom: @keyboardHeight;
    }
  }
}
