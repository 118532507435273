@import 'styles/variables.less';

.SelectTeamAndPlayerForm {
  padding-top: 1.6rem;
  padding-bottom: 0.8rem;

  @media @desktop {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__Form {
    display: flex;
    border-radius: 14.7rem;
    max-width: max-content;
    border: solid 0.1rem @grey7;
  }
}
