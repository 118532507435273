@import 'styles/variables.less';
@import 'styles/typography.less';

.AuthTooltipContent {
	background-color: @white;
	border-radius: @defaultBorderRadius;
	padding: 0.8rem 1.2rem;
	max-width: 11.4rem;
	display: grid;
	gap: 0.9rem;
	position: relative;
	margin-bottom: 0.9rem;
	
	&::after,
	&::before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
	}
	
	&::after {
		z-index: 1;
	}
	
	&--top {
		&::after,
		&::before {
			transform: translate(-50%, 100%);
			bottom: 0;
			left: 50%;
		}
		
		&::after {
			z-index: 1;
			border-top: 0.6rem solid @white;
			border-left: 0.5rem solid transparent;
			border-right: 0.5rem solid transparent;
		}
		
		&::before {
			z-index: 0;
			border-top: 0.8rem solid @white;
			border-left: 0.7rem solid transparent;
			border-right: 0.7rem solid transparent;
		}
	}
	
	&--alignLeft {
		&::after,
		&::before {
			transform: translate(-13%, 100%);
			left: 13%;
		}
	}
	
	& > p {
		color: @grey8;
		.bodyBold();
	}
	
	&__Button {
		color: @grey2;
		background-color: @grey6;
		border-radius: @defaultBorderRadius;
		.comment();
		width: 100%;
		height: 3.2rem;
		padding: 0 0.8rem;
	}
}
