@import 'styles/typography';
@import 'styles/variables';

.ReportSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 4rem 2.4rem 1.6rem;

  &--modal-theme {
    .ReportSuccess__Footer {
      padding-bottom: 0;
      margin-top: 0;

      button {
        position: absolute;
        right: 2.4rem;
        bottom: 2.4rem;
        width: fit-content;
        margin-left: auto;
      }
    }
  }

  &__Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 1.6rem;
    border-radius: 50%;
    border: 0.3rem solid @success;

    i {
      font-size: 6.4rem;
      color: @success;
    }
  }

  &__Title {
    .h4();

    padding-bottom: 3.7rem;
    font-weight: 700;
    color: @success;
  }

  &__Text {
    .body();

    color: @grey2;
  }

  &__Footer {
    width: 100%;
    margin-top: auto;
    padding-bottom: 3rem;

    button {
      width: 100%;
    }
  }
}
