@import 'styles/variables';

.ImageWithBadge {
  position: relative;
  display: flex;
  width: 100%;

  &__ImageWrapper {
    width: 100%;
    padding-top: 1.2rem;
  }

  &__Image {
    display: block;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    margin-left: 0;
    border-radius: 1.6rem;
  }

  &__Badge {
    position: absolute;
    bottom: 0.8rem;
    left: 2.4rem;

    @media @desktop {
      bottom: 1.6rem;
      left: 3.2rem;
    }
  }
}
