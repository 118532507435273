@import 'styles/variables.less';

.Logger {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100%;
  width: 24rem;
  overflow-y: auto;
  background: @grey1;
  color: @black;
  font-family: Consolas, monospace;
  font-size: rem(12);
  box-shadow: @lightShadow;

  table {
    width: 100%;
    border-collapse: collapse;
    border: none;
    margin-bottom: 6rem;

    td,
    th {
      padding: 0.2rem 0.4rem;
      text-align: left;
    }

    th {
      color: @black;

      &:first-child {
        width: 1%;
        white-space: nowrap;
      }
    }

    tr {
      border: 0.1rem solid @dark;
    }
  }

  .Buttons {
    position: fixed;
    display: flex;
    width: 24rem;
    justify-content: space-between;
    padding: 1rem;
    bottom: 0;
    background-color: @grey1;

    button {
      background-color: @normal;
      color: @white;
      padding: 1rem;
    }
  }
}
