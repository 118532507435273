@import 'styles/variables.less';
@import 'styles/mixins.less';

.FeedsSelectSlider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4rem;
  border-radius: 4.8rem;
  background: @grey7;
  margin: 0;
  max-width: 28rem;
  width: 100%;

  &--full-width {
    max-width: unset;
  }

  &__Item {
    .bodyBold();

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 9.2rem;
    background-color: @grey7;
    color: @grey3;

    &--selected {
      color: @white;
      background-color: @grey5;
    }
  }
}

.FeedsSliderIcon {
  overflow: hidden;
  width: 0rem;

  &--selected {
    width: 3.6rem;
  }

  &--animated {
    animation-name: smoothout;
    animation-duration: 300ms;
  }

  &--animated&--selected {
    animation-name: smoothin;
    animation-duration: 300ms;
  }

  &__Inner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.8rem;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    border: 0.1rem solid @transparentLight4;

    @media @desktop {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  i {
    font-size: 1.5rem;
  }
}

@keyframes smoothin {
  0% {
    width: 0rem;
    opacity: 0;
  }
  100% {
    width: 3.6rem;
    opacity: 1;
  }
}

@keyframes smoothout {
  0% {
    width: 3.6rem;
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}
