@import 'styles/variables.less';
@import 'styles/typography.less';

.Title {
  .comment();

  display: inline-block;
  padding: 0.4rem 0;
  color: @grey4;
}

.Button {
  .body();

  width: 100%;
  padding: 0.4rem 0;
  color: @grey3;
  text-align: left;
}
