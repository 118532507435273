@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.SidebarItem {
  position: relative;
  height: 4.8rem;
  width: 100%;
  flex-shrink: 0;

  .SidebarItem__Clickable {
    @media @desktop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;
    }
  }

  @media @desktop {
    width: 6.4rem;
    height: 6.4rem;

    &:active {
      animation: bounceAnimation 0.5s 1;
    }
  }

  &--has-notification::after {
    .commentBold();

    content: attr(data-numnotifications);
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: @alert;
    border-radius: 3.2rem;
    border: 0.3rem solid @grey8;
    padding: 0.2rem 0.6rem;
    text-align: center;
  }

  &--theme-image {
    img {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
      object-fit: cover;

      @media @desktop {
        width: 6.4rem;
        height: 6.4rem;
      }
    }

    @media @desktop {
      &:hover {
        img {
          position: relative;
          border: 0.3rem solid @grey7;
          z-index: 1;
        }

        &::before {
          content: '';
          position: absolute;
          border-radius: 50%;
          width: 7.2rem;
          height: 7.2rem;
          background: @grey5;
          top: -0.4rem;
          left: -0.4rem;
        }
      }

      .SidebarItem__Clickable:hover::after {
        border-radius: 50%;
      }
    }
  }

  &--theme-image&--is-root {
    position: relative;

    img {
      position: relative;
      width: 4.8rem;
      height: 4.8rem;
      border: 0.3rem solid @grey7;

      @media @desktop {
        width: 6.4rem;
        height: 6.4rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      border-radius: 50%;
      width: 5.6rem;
      height: 5.6rem;
      background: @gradient;
      top: -0.4rem;
      left: -0.4rem;

      @media @desktop {
        width: 7.2rem;
        height: 7.2rem;
      }
    }
  }

  &--is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--theme-vector {
    .bodyBold();

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
    background-image: url(assets/images/svg/rectangle-grey.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .SidebarItem__Clickable:hover::after {
      border-radius: 0.8rem;
    }
  }

  &--theme-vector&--is-root {
    background-image: url(assets/images/svg/rectangle-black.svg);

    img {
      height: 3.2rem;
      width: 3.6rem;
      filter: invert(1);
    }
  }

  &--theme-vector&--is-active {
    color: @black;
    background-image: url(assets/images/svg/polygon-blue.svg);
    background-color: transparent;
    width: 6.4rem;
    height: 7.2rem;

    .SidebarItem__Clickable:hover::after {
      background-color: transparent;
      background-image: url(assets/images/svg/polygon-transparent.svg);
      width: 6.4rem;
      height: 7.2rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &--theme-vector&--is-active&--is-root {
    background-color: transparent;
    background-image: url(assets/images/svg/polygon-blue.svg);

    img {
      filter: none;
    }
  }

  &--is-nojoined {
    height: 4rem;

    .SidebarItemInfo {
      align-items: start;
      &__Name {
        .commentBold();
        color: @grey2;
        &--is-active {
          color: @white;
        }
      }
    }
  }

  &--is-nojoined&--theme-image {
    img {
      width: 4rem;
      height: 4rem;
    }
  }

  &--is-nojoined&--theme-image&--is-root {
    &::before {
      width: 4.4rem;
      height: 4.4rem;
      top: -0.2rem;
      left: -0.2rem;
    }
  }

  &--is-nojoined > &__Clickable {
    gap: 0.8rem;
  }

  &__Clickable {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: @smallHorizontalSpacingMobile;
    height: 100%;
    width: 100%;
    cursor: pointer;

    @media @desktop {
      &:hover::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: @transparentLight2;
        z-index: 2;
      }
    }
  }
}

.SidebarItemInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.2rem;
  min-width: 0;
  align-items: start;

  &__Name {
    .commentBold();
    .truncateWithEllipsis();

    color: @grey2;

    &--is-active {
      color: @white;
    }
  }

  &__Status {
    .body();
    .truncateWithEllipsis();

    color: @grey3;
  }

  &__Followers {
    .comment();

    color: @grey4;
  }
}

@keyframes bounceAnimation {
  from,
  to {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
}

.PlusIcon {
  position: absolute;
  height: 3.2rem;
  width: 3.2rem;
  right: -0.5rem;
  bottom: -0.5rem;
  z-index: 2;
  border-radius: 50%;
  border: 0.2rem solid @grey7;
  background-color: @normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
