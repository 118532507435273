@import 'styles/variables.less';
@import 'styles/mixins.less';

.FeedFiltersTabs {
  display: flex;
  gap: 0.8rem;
  padding: 2.4rem 0;
}

.Tab {
  .bodyBold();
  height: 4rem;
  border-radius: @borderRadius;
  text-align: center;
  background-color: @grey8;
  color: @grey3;
  padding: 0.8rem;
  text-wrap: nowrap;
  display: inline-block;
  white-space: nowrap;
  flex: 1;
  
  &--active {
    background-color: @grey1;
    color: @black;
  }
}
