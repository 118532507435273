@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.GifPicker {
  .hideScrollbar();

  padding: 2rem 2rem 0;
  height: 48rem;
  width: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: @grey6;
  border-radius: @borderRadius;
  margin: auto;
  z-index: 100;
  position: fixed;
  bottom: 8rem;

  @media @desktop {
    top: calc(50% - 24rem);
    left: calc(50% - 17rem);
  }

  &__Footer {
    height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('assets/images/svg/powered-by-giphy.svg');
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
