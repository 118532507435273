@import 'styles/mixins.less';
@import 'styles/variables.less';

.RecentSearch {
  .hideScrollbar();

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: @safeAreaBottom;

  @media @desktop {
    position: absolute;
    top: @headerHeight;
    background-color: @grey8;
    width: 26rem;
    max-height: 28rem;
    z-index: 1;
  }
}

&:global(.is-keyboard-open) {
  .RecentSearch {
    margin-bottom: calc(@keyboardHeight);
    padding-bottom: 1.2rem;
  }
}
