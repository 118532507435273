@import 'styles/variables.less';
@import 'styles/mixins.less';

.Input__container {
  // use !important due to library restrictions
  display: flex !important;
  gap: 0.8rem;
  width: 100%;

  & input {
    .basicInput();

    text-align: center;

    &:disabled {
      // use !important due to library restrictions
      background: none !important;
    }
  }

  &--invalid input {
    border: 0.1rem solid @alert;
  }
}
