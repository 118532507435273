@import 'styles/variables.less';
@import 'styles/typography.less';

.Points {
  border-radius: @borderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6.6rem;
  background-color: var(--team-primary-color);

  &__Label {
    .body();
    color: var(--team-secondary-text-color);
  }

  &__Value {
    .h3();
    color: var(--team-secondary-color);
  }
}
