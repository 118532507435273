@import './variables.less';

.typography() {
  margin: 0;
  font-family: @mainFont;
  font-style: normal;
}

.h1() {
  .typography();

  font-size: 3.8rem;
  line-height: 3.6rem;
}

.h2() {
  .typography();

  font-size: 3rem;
  line-height: 3.2rem;
}

.h3() {
  .typography();

  font-size: 2.4rem;
  line-height: 2.8rem;
}

.h4() {
  .typography();

  font-size: 1.9rem;
  line-height: 2.4rem;
}

.body() {
  .typography();

  font-size: 1.5rem;
  line-height: 2.2rem;
}

.bodyBold() {
  .body();

  font-weight: 600;
}

.comment() {
  .typography();

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
}

.commentBold() {
  .comment();

  font-weight: 700;
}

.button() {
  .typography();

  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.buttonSmallSecondary {
  .typography();
  
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
}

.tooltip() {
  .typography();

  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;
}
