@import 'styles/variables';

.Video {
  width: 100%;
  height: 100%;
  z-index: 3;

  &--small {
    max-width: 6.4rem;
    max-height: 6.4rem;
  }

  &--medium {
    max-width: 44rem;
    max-height: 25.6rem;
  }

  &--large {
    max-width: 60rem;
    max-height: 33.8rem;
  }

  &--full {
    max-width: 100%;
    max-height: 100%;

    @media @desktop {
      max-width: 90%;
      max-height: 90%;
    }
  }
}
