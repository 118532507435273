@import 'styles/variables.less';
@import 'styles/typography.less';

.StatsBox {
  border-radius: @borderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.2rem;

  &--clickable {
    cursor: pointer;
  }

  .StatsBox__Rank {
    .commentBold();

    min-width: 3.4rem;
    height: 2rem;
    width: fit-content;
    padding: 0 0.6rem 0.2rem 0.6rem;
    border-radius: 0.4rem;
    align-items: center;
    justify-content: center;
    display: flex;

    &--strong {
      background-color: @success;
      color: @white;
    }

    &--middle {
      background-color: @grey8;
      color: @grey1;
    }

    &--weak {
      background-color: @alert;
      color: @grey1;
    }
  }

  &--theme-transparent {
    border: solid 0.1rem @grey5;
    gap: 0.4rem;

    .StatsBox__Title {
      color: @grey2;
    }

    .StatsBox__Content {
      .commentBold();

      @media @desktop {
        .h4();
      }
    }
  }

  &--theme-dark {
    background-color: @grey8;
    gap: 0.8rem;

    .StatsBox__Main {
      display: grid;
      grid-template-columns: 3.2rem 1fr;
      column-gap: 0.8rem;

      :first-child {
        grid-row: span 2;
        align-self: center;
      }
    }

    .StatsBox__Side {
      grid-column: 2;
    }

    .StatsBox__Content {
      .bodyBold();
    }
  }

  &--theme-light {
    background-color: @grey6;

    .StatsBox__Title {
      .comment();
    }

    .StatsBox__Content {
      .commentBold();
    }
  }

  &--theme-team {
    background-color: var(--team-primary-color);
    background-image: linear-gradient(rgba(29, 31, 33, 0.4), rgba(29, 31, 33, 0.4));
    color: var(--team-secondary-color);
    gap: 0.8rem;

    .StatsBox__Title {
      color: @grey1;
    }

    .StatsBox__Content {
      .bodyBold();
    }
  }

  &__Title {
    .commentBold();

    color: @grey3;
  }

  &__Side {
    .comment();

    color: @grey3;
    text-align: left;
  }
}
