@import 'styles/variables.less';

.ScoreboardGrid {
  @media @desktop {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-column-gap: 0;
    align-items: center;
    justify-items: start;

    &__Column:last-child {
      justify-self: end;
    }
  }
}
