@import 'styles/variables.less';

.Filters {
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  width: 100%;
  height: 4rem;
  padding: 0 1.6rem;

  @media @desktop {
    height: 3.2rem;
    padding: 0;
  }

  &__RightSide {
    @media @desktop {
      display: flex;
      gap: 1.6rem;
    }
  }

  &__Seasons {
    min-width: 0;
  }

  &__Teams {
    margin-left: auto;
  }
}
