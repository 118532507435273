@import 'styles/variables.less';

.VerticalRowsTableGrid {
  display: grid;
  align-items: center;
  grid-column-gap: 3rem;
  grid-template-columns: 8fr auto auto;

  @media @desktop {
    grid-column-gap: 4rem;
    grid-template-columns: 5fr 6fr 5fr;
  }
}
