@import 'styles/variables.less';

.HeaderFeed {
  display: flex;
  justify-content: center;
  min-height: 5.6rem;

  @media @desktop {
    display: flex;
    justify-content: space-between;
    gap: 3.2rem;
    min-height: 3.2rem;
    margin: @bigVerticalSpacingDesktop 0 @smallVerticalSpacingDesktop;
  }

  & > button {
    flex-shrink: 0;
  }
}

.HeaderFeedSlider {
  max-width: 70.8rem;
  width: 100%;
  padding: 0.8rem 1.6rem;
  margin: auto;
  
  @media @desktop {
    padding: 1.6rem;
  }
}
