@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.CollaborationItem {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin: 0 auto;
  padding: 0.8rem 1.6rem 1.2rem;
  user-select: auto;
  overflow: hidden;

  @media @desktop {
    padding: 1.2rem 0 1.6rem;
  }
  
  &--single-title {
    .PostCard__Title {
      margin-bottom: 0;
    }
  }

  &--freezed {
    .PostCard__Wrapper {
      pointer-events: none;
    }
  }

  &__Wrapper {
    display: block;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    width: 100%;

    a {
      user-select: auto;
    }
  }

  &__TextContent {
    .body();
    padding-bottom: 1.2rem;
    text-align: left;
    
    div {
      padding: 0;
    }
  }
  
  &__Image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
    }
  }
  
   &__Video {
     video {
       width: 100%;
       height: 100%;
       object-fit: cover;
     }
  }
}
