@import 'styles/variables.less';

.ColumnCharts {
  display: flex;
  height: 100%;
  align-items: end;
  gap: 0.8rem;

  @media @desktop {
    gap: 0.4rem;
  }
}
