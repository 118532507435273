@import 'styles/typography.less';
@import 'styles/variables.less';

.PlayerProfile {
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;

  @media @desktop {
    margin-bottom: 2.4rem;
  }

  &__Section {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-bottom: 2rem;
    
    &--links {
      padding: 0 2.4rem 2rem;
      
      @media @desktop {
        padding: 0;
      }
    }
  }

  &__Header {
    .bodyBold();

    padding: 0.5rem 0 0.5rem 1.6rem;

    @media @desktop {
      padding: 0.5rem 0;
    }
  }

  &__HeaderWrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    z-index: 1;
    padding-right: 1.6rem;

    @media @desktop {
      padding-right: 0;
    }
  }
}

.Socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
  width: 100%;
  
  @media @desktop {
    margin: 0;
  }
}
