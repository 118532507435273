@import 'styles/variables.less';
@import 'styles/mixins.less';

.TeamProfileWrapper {
  grid-column: 1/-1;
}

.TabSlider {
  max-width: 70.8rem;
  width: 100%;
  padding: 1.6rem;
  margin: auto;
}
