@import 'styles/variables.less';
@import 'styles/mixins.less';

.TeamProfileInfoWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 @smallHorizontalSpacingMobile;

  @media @desktop {
    margin-bottom: 2.4rem;
    padding: 1.6rem 0 0;
  }

  .TeamProfileInfo {
    &__Stats {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
 
    &__Socials {
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
    }
    
    &__Stats {
      display: grid;
      column-gap: 1.2rem;
      grid-template-columns: repeat(5, 1fr);
      
      @media @desktop {
        column-gap: 1.6rem;
      }
    }
  }

  
}
