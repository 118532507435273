@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.AuthorCard {
  width: 100%;
  background: @black;
  border-radius: 0.8rem;
  min-height: 40vh;
  margin-top: -4rem;
  
  @media @desktop {
    margin-top: 0;
    background-color: @grey8;
  }
  
  &__Header {
    position: relative;
    width: 100%;
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    
    @media @desktop {
      border-radius: 0;
    }
  }
  
  &__Avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    
    & > div {
      border: 0.4rem solid @grey8;
    }
    
    &__Frame {
      position: absolute;
    }
    
    &__Badge {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      position: absolute;
      bottom: -0.5rem;
      right: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      z-index: 1;
      
      img {
        width: 100%;
        height: auto;
      }
    }
    
    &__withFrame {
      transform: translateY(-55%) translateX(100%);
    }
  }
  
  &__HeaderThumbnail {
    position: relative;
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    height: 12.8rem;
    width: 100%;
    overflow: hidden;
    
    @media @desktop {
      border-radius: 0;
    }
  }
  
  &__Background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  &__Details {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 8rem 1.2rem 0;
    justify-content: center;
    align-items: center;
  }
  
  &__Names {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1.6rem;
    
    h4 {
      .truncateWithEllipsis();
      .h4();
      text-decoration: none;
      color: @white;
  
    }
    
    div {
      .comment();
      .truncateWithEllipsis();
      cursor: pointer;
      color: @grey2;
    }
  }
}

.ReputationPoints {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1.2rem;
  
  @media @desktop {
    padding: 0 1.2rem 1.2rem;
  }
  
  &__ReputationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 1.6rem;
    height: 5rem;
    
    @media @desktop {
      margin-top: 0;
    }
  }
  
  &__Table {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.8rem;
  }
  
  &__Title {
    .bodyBold();
    color: @grey1;
  }
  
  &__Action {
    .button();
    
    color: @normal;
  }
  
  &__Achievements {
    display: grid;
    gap: 0.8rem;
    padding: 0 1.2rem 2.4rem;
  }
}
