@import 'styles/variables';
@import 'styles/typography.less';

.ContactUsForm {
  display: flex;
  flex-direction: column;

  @media @desktop {
    margin: 0;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__Fields {
    display: flex;
    flex-direction: column;

    @media @desktop {
      gap: 0.8rem;
    }
  }

  &__Subject {
    position: relative;
  }

  &__Optional {
    position: absolute;
    left: 5.5rem;
    color: @grey4;
  }

  &__Button {
    padding: 1.6rem 0 0;
  }
}
