@import 'styles/variables';

.PollOptionInput {
  display: flex;

  @media @desktop {
    margin-bottom: 1.6rem;
  }

  &__MediaPreview,
  &__MediaBox {
    width: 4.8rem;
    height: 4.8rem;
  }
  
  &__MediaBox {
    background: @grey7;
    border-radius: @borderRadius;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 0.8rem;
    overflow: hidden;
    
    &__WithImage {
      background: none;
    }
    
    i {
      color: @normal;
    }
  }
  
  &__MediaPreview {
    position: absolute;
    left: 0;
    top: 0;
  }
  
  &__InputBox {
    position: relative;
    flex: 1 1 0;
    
    &__RemoveIcon {
      i {
        font-size: 1.6rem;
      }
    }
  }
}

