@import 'styles/variables.less';

.HomeFeedActions {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - @headerHeight * 2 - @bigVerticalSpacingDesktop);
  gap: 1rem;
  margin-top: 2.4rem;
}
