@import 'styles/variables.less';
@import 'styles/mixins.less';

.CollaborationSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  
  p {
    padding: 0;
    margin: 0;
  }
  
  &__SeeAll {
    padding-right: 0 !important; // need override default styles
  }
  
  &__Header {
    display: flex;
    height: 3.2rem;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  &__MediaItems {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  
  &__MediaAndTextItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  
  &__TextItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  
  &__Actions {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;
  }
  
  &__Approved {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;
    pointer-events: none;
    
    button {
      border-color: @grey7 ;
      color: rgba(253, 185, 39, 1);
    }
  }
}

.CollaborationItem {
  display: flex;
  width: 8rem;
  height: 8rem;
  flex-direction: row;
  position: relative;
  overflow: unset;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: @borderRadius;
  }
  
  &--selected {
    border-radius: @borderRadius;
    border: 0.2rem solid @normal;
  }
}

.CollaborationMediaAndTextItem {
  display: flex;
  width: 100%;
  height: 6.4rem;
  flex-direction: row;
  position: relative;
  gap: 1.2rem;
  align-items: center;
  justify-content: flex-start;
  background-color: @transparentLight3;
  border-radius: @borderRadius;
  overflow: unset;
  
  &__Image {
    height: 6rem;
    min-width: 6rem;
    position: relative;
    margin-left: 0.2rem;
    
    &--selected {
      margin-left: 0;
      
    }
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: @borderRadius;
  }
  
  &--selected {
    border-radius: @borderRadius;
    border: 0.2rem solid @normal;
  }
}

.CollaborationTextItem {
  display: flex;
  width: 100%;
  height: 4rem;
  gap: 1.2rem;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: @transparentLight3;
  border-radius: @borderRadius;
  overflow: unset;
  
  p {
    margin: 0 !important;
  }
  
  &--selected {
    border-radius: @borderRadius;
    border: 0.2rem solid @normal;
  }
  
  &__Image {
    padding: 0.8rem 0 0.8rem 0.8rem;
  }
}

.TotalItems {
  .comment();
  color: @grey3;
}

.LabelSelected {
  color: @white;
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
  z-index: 1;
  background-color: @normal;
  width: 2rem;
  height: 2rem;
  border-bottom-left-radius: @borderRadius;
  border-top-right-radius: @borderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoLabel {
  display: flex;align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.2rem;
  height: 3.2rem;
  opacity: 0.8;
  background-color: @grey8;
  border-radius: 50%;
  color: @white;
}

.ResponseText {
  .comment();
  
  text-align: left;
  padding-right: 1.2rem;
  color: @grey1;
  width: 100%;
}

.Hidden {
  display: none;
}
