@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.FloatingLink {
  position: absolute;
  top: 13rem;
  left: 50%;
  z-index: 100;
  max-width: 30rem;
  width: 100%;
  margin-top: -0.6rem;
  border-radius: 0.8rem;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  background-color: @grey7;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;

  @media @desktop {
    top: 10rem;
  }

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &__InputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4.8rem;
  }

  &__LinkInput {
    .basicInput();

    display: flex;
    align-items: center;
    width: 100%;
    height: 4.8rem;
    border-radius: @borderRadius;
    color: @grey2;

    a {
      .truncateWithEllipsis();

      display: block;
      margin-right: 3rem;
      color: @normal;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__LinkWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4.8rem;
    padding-left: 1rem;

    a {
      .truncateWithEllipsis();

      display: block;
      margin-right: 3rem;
      color: @normal;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__Button {
    padding-right: 0.5rem;
  }
}
