@import 'styles/mixins';
@import 'styles/variables.less';
@import 'styles/mixins.less';

.UserPublicFeed {
  display: flex;
  flex-direction: column;

  @media @desktop {
    margin-bottom: 2.4rem;
    padding: 0;
  }
}
