@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.ModalContentWrapper {
  min-height: 14.4rem;
}

.ModalContent {
  padding: 1.6rem 2.4rem 1.6rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 1.6rem;
    left: 2.4rem;
    bottom: 1.6rem;
    width: 0.4rem;
    border-radius: 0.2rem;
    background: @extra1;
  }

  &__Title {
    .bodyBold();
    height: 6.4rem;
    width: 100%;
    border-radius: @borderRadius;
    background-color: @grey7;
    color: @grey1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
  }
}
