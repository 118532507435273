@import 'styles/variables.less';
@import 'styles/typography.less';

.MainSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &--reverse {
    flex-direction: row-reverse;
  }

  &__Value {
    .bodyBold();
    color: @grey1;
  }

  &__Percent {
    .comment();
    color: @grey3;
    padding: 0 1rem;
  }
}

.AdditionalSection {
  display: flex;
  justify-content: flex-start;

  &--reverse {
    justify-content: flex-end;
  }

  &__Value {
    .commentBold();
    color: @grey1;

    &--big {
      .bodyBold();
    }
  }
}
