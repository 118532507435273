@import 'styles/variables.less';

.Toolbar {
  display: flex;
  gap: 0.8rem;

  &--top {
    margin: 0.8rem 0 1.2rem 0;
  }

  &--bottom {
    margin: 1.2rem 0 0.8rem 0;
  }

  &__SubmitButton {
    margin-left: auto;
  }
  
  &--single-media {
    justify-content: space-between;
    align-items: center;
  }
}

.Video {
  display: none;
}


.SingleImage {
  border-radius: @borderRadius;
  position: relative;
  width: 6.4rem;
  height: 6.4rem;
  object-fit: cover;
}

.SingleVideo {
  border-radius: @borderRadius;
  width: 6.4rem;
  height: 6.4rem;
  object-fit: cover;
  position: relative;
}

.VideoToolbar {
  padding: 2.4rem 0 ;
  gap: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
