@import 'styles/variables.less';

.PlayerRankingItem {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0.4rem;
  height: 6.8rem;

  &--stretched {
    width: 100%;
  }

  &:not(:last-of-type) {
    border-bottom: 0.1rem solid @transparentLight3;
  }

  @media @desktop {
    height: 8.8rem;
    padding-bottom: 1.9rem;
  }
}
