@import 'styles/variables.less';
@import 'styles/typography.less';

.Buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  padding: 0 1.6rem  0.8rem 1.6rem;
  color: @grey1;
  margin-bottom: 3rem;
}

.Button {
  .comment();

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.8rem;
  height: 100%;
  min-height: 11.2rem;
  background-color: @grey7;
  border-radius: @borderRadius;
  
  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
