@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.DropdownButton {
  .bodyBold();

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
  height: 100%;
  padding: 0.9rem 0.8rem 0.9rem 1.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid @grey5;
  color: @grey1;
  background-color: @black;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  span {
    .truncateWithEllipsis();
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &--teamBadge {
    .commentBold();

    border: none;
    background-color: @grey7;
    color: @white;
  }

  @media @desktop {
    &:not(:disabled):active {
      border: 0.1rem solid @white;
    }

    &:not(:disabled):hover {
      background-color: @transparentLight2;
    }

    &--teamBadge {
      &:not(:disabled):active {
        border: none;
      }
    }
  }
}
