@import 'styles/typography.less';
@import 'styles/variables.less';

.ScoreboardDay {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__Header {
    .bodyBold();

    margin: 0 0 @smallVerticalSpacingDesktop;
    color: @grey1;
    padding: 0 @verticalSpacingMobile;

    @media @desktop {
      padding: 0;
    }
  }

  &__Game {
    min-width: 32rem;
    border-top: 0.1rem solid @grey7;

    @media @desktop {
      min-width: 77.4rem;
    }

    &--first-not-finished ~ &:nth-child(odd) {
      background-color: @grey8;
    }
  }
}

.NoGames {
  color: @grey4;
  padding: 0.8rem;
  border-top: 0.1rem solid @grey6;
}
