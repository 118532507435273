@import 'styles/variables.less';

.PostsFeed {
  &__Cards {
    position: relative;

    &--is-height {
      min-height: calc(
        100vh - @headerHeight - @bottomBarHeight - @tabsHeight - @shortcutBarHeight -
          @toggleTabsHeight - @safeAreaTop - @safeAreaBottom
      );

      @media @desktop {
        min-height: calc(
          100vh - @headerHeight - @tabsHeight - @shortcutBarHeight - @toggleTabsHeight - 1.6rem
        );
      }
    }

    &--player-feed {
      @media @desktop {
        padding-top: 2.4rem;
      }
    }
  }

  &__Card {
    max-width: 74rem;
    margin: 0 auto;
    border-bottom: 0.1rem solid @grey6;

    @media @desktop {
      padding: 0;
    }
  }

  &__Footer {
    margin: @bigVerticalSpacingDesktop 0;
    padding: 0 1.6rem;
  }
}
