@import 'styles/variables.less';

.Filter {
  padding: 0 1.6rem;

  & > div {
    width: calc(50% - 0.8rem);
  }

  @media @desktop {
    padding: 0;

    & > div {
      width: fit-content;
    }
  }
}
