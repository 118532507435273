@import 'styles/variables.less';
@import 'styles/typography.less';

.StandingsFilters {
  display: grid;
  padding: 1.6rem 1.6rem 0 1.6rem;
  grid-template-columns: 1fr auto;
  column-gap: 1.6rem;
  row-gap: 2.4rem;

  @media @desktop {
    display: flex;
    column-gap: 1.2rem;
    padding: 0;
  }

  &__Season {
    width: @seasonsSelectWidth;
  }

  &__Switcher {
    grid-column: span 2;

    @media @desktop {
      margin-left: auto;
      width: 24.8rem;
    }
  }
}

.Mobile {
  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(@safeAreaTop + 5.2rem);
    padding: calc(@safeAreaTop + 1rem) 1.6rem 1rem;
    border-bottom: 0.1rem solid @transparentLight3;
    background-color: @grey6;
  }

  &__Content {
    .bodyBold();

    padding: 2.1rem 1.6rem;
  }

  &Content__Type {
    .bodyBold();

    color: @grey3;
    margin-bottom: 0.5rem;
  }

  &Header__Title {
    .bodyBold();

    color: @grey1;
  }
}

.Buttons {
  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 0.1rem solid @grey6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.8rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.6rem calc(@safeAreaBottom + 0.8rem);
}
