@import 'styles/variables.less';
@import 'styles/typography.less';

.Item {
  display: flex;
  height: 4rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 2.4rem;

  @media @desktop {
    width: 3.2rem;
  }

  &__Value {
    .h4();
    color: @white;
    font-weight: 700;

    @media @desktop {
      .h3();
    }
  }

  &__Label {
    .comment();
    color: @grey4;
  }
}

.Divider {
  .bodyBold();
  color: @grey3;
  width: 0.5rem;
}
