@import 'styles/variables';

.PostCreateForm {
  &__Editor {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    
    @media @desktop {
      padding-top: 0;
    }
  }

  &__EditorLabel {
    color: @grey3;
    margin-bottom: 0.8rem;
  }

  &__Footer {
    display: flex;
    margin-top: 3.2rem;
  }

  &__CreateBtn {
    position: fixed;
    bottom: @safeAreaBottom;
    right: 0;
    padding: @verticalSpacingMobile @bigHorizontalSpacingMobile;
    border-top: 0.1rem solid @transparentLight3;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: @black;

    @media @desktop {
      position: relative;
      padding: @smallVerticalSpacingDesktop 0 0 0;
      background-color: transparent
    }
  }

  &__Settings {
    display: flex;
    flex-direction: column;
  }
}
