@import 'styles/variables.less';

.FeedEmptyState {
  & > div {
    min-height: calc(
      100vh - @headerHeight - @bottomBarHeight - @shortcutBarHeight -
        @toggleTabsHeight - @safeAreaTop - @safeAreaBottom
    );

    @media @desktop {
      min-height: calc(
        100vh - @headerHeight - @tabsHeight - @shortcutBarHeight - @toggleTabsHeight - 1.6rem
      );
      padding: 0 2.4rem;
    }
  }

  &--player-feed {
    & > div {
      min-height: calc(
        100vh - @headerHeight - @bottomBarHeight - @tabsHeight - @safeAreaTop - @safeAreaBottom -
          2.4rem
      );

      @media @desktop {
        min-height: calc(
          100vh - @headerHeight - @tabsHeight - @safeAreaTop - @safeAreaBottom - 4.8rem
        );
      }
    }
  }
}
