@import 'styles/variables.less';
@import 'styles/typography.less';

.IconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition-property: color, background-color;
  transition-duration: @smallElementAppearTime;
  transition-timing-function: @animationEasing;
  box-sizing: border-box;

  &--theme-primary {
    padding: 0.4rem;
    border-radius: 0.4rem;
    background-color: @normal;
    color: @white;

    &:hover,
    &:active {
      color: @white;
      cursor: pointer;
    }
  }

  &--theme-tertiary {
    height: 3.2rem;
    padding: 0 0.8rem;
    border-radius: 0.4rem;
    border: 0.2rem solid @grey7;
    background-color: @grey7;
    color: @grey2;

    &:hover {
      background-color: @grey7;
      color: @grey2;
    }

    &:active {
      background-color: @grey7;
      color: @grey2;
      border: 0.2rem solid @white;
    }

    &:disabled {
      background-color: @grey8;
    }
  }

  &--small {
    .comment();
  }

  &:disabled,
  &--pseudo-disabled {
    opacity: 0.6;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: @black;
  }

  &__Text {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
