@import 'styles/variables.less';
@import 'styles/mixins.less';

.ShortcutBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 0;
  overflow: hidden;
  
  @media @desktop {
    padding: 1.2rem 0;
  }
}

.ShortcutBarFavorites {
  .hideScrollbar();

  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding-inline-start: 1.6rem;
  transition-duration: @shortcutDuration;
  transition-timing-function: @shortcutTimingFunction;
  cursor: pointer;
  width: 100%;
  overflow: auto;

  &__Item {
    position: relative;
    min-width: unset;
    border: 0.2rem solid @black;
    border-radius: 50%;
    overflow: unset;
    background-color: @grey8;
    pointer-events: unset;
  }

  &__Button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    color: @white;
    border: 0.15rem solid @grey7;
    background-color: @black;
  }
}

.ShortcutBarGames {
  position: relative;
  display: flex;
  padding-left: 1.6rem;
  gap: 0.8rem;
  width: 100%;
  min-height: @statisticsHeight;
  transition-duration: @shortcutDuration;
  transition-timing-function: @shortcutTimingFunction;

  @media @desktop {
    width: 100%;
    padding: 1.2rem 0 1.2rem 1.2rem;
    border-bottom: 0.1rem solid @transparentLight3;
  }
}
