@import 'styles/typography';
@import 'styles/variables';

.StopNow {
  .commentBold();
  
  color: @alert;
}

.Popup {
  &__Body {
    padding: 1.6rem 2.4rem 6.0rem 2.4rem;
    
    p {
      .body();
      
      color: @grey2;
    }
  }
  
  &__Footer {
    display: flex;
    padding: 1.6rem 2.4rem 2.4rem 2.4rem;
    gap: 1.6rem;
  }
}


.BottomSheet {
  &__Header {
    border-bottom: 0.1rem solid @transparentLight3;
    padding-bottom: 1.2rem;
    text-align: center;
    
    h4 {
      .h4();
      
      color: @grey1;
    }
  }
  
  &__Body {
    padding: 1.6rem 2.4rem 0 2.4rem;
    
    p {
      .body();
      
      color: @grey2;
    }
  }
  
  &__Footer {
    padding: 2.4rem 2.4rem 2.1rem 2.4rem;
    
    &__Buttons {
      display: flex;
      gap: 0.8rem;
    }
    
    &__Buttons:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }
}
