@import 'styles/variables.less';
@import 'styles/typography.less';

.InGameStats {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
  row-gap: 1.6rem;
  padding: 1.2rem 1.6rem;
}
