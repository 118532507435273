@import 'styles/variables';
@import 'styles/typography';
@import 'styles/mixins';

.ConfirmationBottomSheet {
  height: fit-content;

  &__Header {
    padding: 1rem 2.4rem 1.4rem;
    border-bottom: 0.1rem solid @transparentLight3;
  }

  &__Title {
    .h4();

    width: 100%;
    text-align: center;
    color: @grey1;
  }

  &__Text {
    width: 100%;
    padding: 1.6rem 2.4rem 0;
    color: @grey2;
  }

  &__Footer {
    display: flex;
    gap: 0.8rem;
    padding: 2.4rem 2.4rem 7.2rem;
  }
}
