@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.SearchHeader {
  display: flex;
  height: 5.5rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.6rem 1.6rem;

  &__Label {
    .h3();
    .truncateWithEllipsis();

    padding-left: 1.6rem;
    color: @grey1;
  }
}
