@import 'styles/variables';
@import 'styles/mixins';

.Comment {
  display: flex;
  padding: 0.8rem 0;
  width: 100%;

  .LeftSide {
    display: grid;
    gap: 0.4rem;
    width: 100%;
  }

  &__UserAvatar {
    position: relative;
    margin-right: 1.2rem;
    z-index: 1;
  }

  &__Main {
    width: 100%;
    min-width: 0;
  }

  &__Body {
    position: relative;
    width: 100%;
    background-color: @grey6;
    padding: 1.2rem;
    border-radius: @borderRadius;

    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border-left: @borderRadius solid transparent;
      border-right: @borderRadius solid @grey6;
      border-top: @borderRadius solid @grey6;
      border-bottom: @borderRadius solid transparent;
      left: -0.8rem;
      top: 0;
    }

    &:hover:before {
      display: none;
    }

    &:hover:after {
      display: none;
    }
    &:after {
      content: '';
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      left: -1.8rem;
      top: 0;
      border-radius: 50%;
      background: @black;

      @media @desktop {
        background: @grey8;
      }
    }

    &--highlighted {
      background-color: @transparentNormal;
      &::after,
      &::before {
        display: none;
      }
    }
  }

  &__Content {
    color: @grey1;

    .ImageButton {
      display: flex;
      padding-right: 0.8rem;
    }
  }

  &__UserInfo {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.6rem;
    justify-content: space-between;

    .TopSection {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      &__UserNick {
        .truncateWithEllipsis();
        font-weight: 600;
        margin-right: 0.8rem;
        max-width: 15rem;

        @media @desktop {
          max-width: 60%;
        }
      }

      &__UserName {
        .truncateWithEllipsis();
        font-size: 1.2rem;
        color: @grey3;
        max-width: 15rem;

        @media @desktop {
          max-width: 35%;
        }
      }

      &__More {
        margin-left: auto;
      }
    }
  }

  &__Footer {
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    @media @desktop {
      margin-top: 1.2rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
      color: @grey1;
    }
  }

  &__Time {
    margin-left: auto;
    font-size: 1.2rem;
    color: @grey3;
  }
}
