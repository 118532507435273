@import 'styles/mixins.less';
@import 'styles/typography.less';
@import 'styles/variables.less';

.FollowSection {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  
  &__PlayersList {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__Expander {
    position: absolute;
    bottom: 0;
    z-index: 5;

    button {
      border-radius: 50%;
    }
  }
}
