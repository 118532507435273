@import 'styles/variables';

.EventsWrapper {
  border-radius: @borderRadius;
  background-color: unset;

  @media @desktop {
    background-color: @grey8;
  }

  flex: 1;
  display: flex;
  flex-direction: column;
}

.EventsList {
  padding: 0.8rem 1.6rem;

  @media @desktop {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    padding: 1.6rem 1.6rem 0;
  }
}

.EventsLoaderWrapper {
  position: relative;
  min-height: 10rem;
}
