@import 'styles/variables.less';

.Loader {
  position: relative;
  min-height: calc(100 * var(--vh) - @headerHeight * 2 - @bigVerticalSpacingDesktop * 2);

  @media @desktop {
    margin-top: 2.4rem;
  }
}

.PostDetailed {
  display: flex;
  flex-direction: column;
  max-width: 74rem;
  min-height: calc(100vh - @headerHeight - 6.2rem);

  @media @desktop {
    min-height: calc(100vh - @headerHeight - @tabsHeight - 4.8rem);
    margin: 0;
    padding-top: 2.4rem;
  }
}
