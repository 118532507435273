@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.GuideTooltip {
  position: relative;

  &__Content {
    .bodyBold();

    position: absolute;
    bottom: 100%;
    z-index: 100;
    padding: 0.8rem 1.2rem;
    border-radius: 0.8rem;
    color: @grey8;
    background-color: @white;
    text-align: center;
    filter: @lightShadow;

    &::after {
      content: '';
      position: absolute;
      bottom: -1rem;
      width: 1.4rem;
      height: 1.4rem;
      transform: rotate(45deg) translateX(-50%);
      border-radius: 0.4rem;
      background-color: @white;
    }
  }

  &--position-left {
    .GuideTooltip {
      &__Content {
        right: -25%;

        &::after {
          right: 25%;
        }
      }
    }
  }

  &--position-right {
    .GuideTooltip {
      &__Content {
        left: -5%;

        &::after {
          left: 25%;
        }
      }
    }
  }

  &--position-center {
    .GuideTooltip {
      &__Content {
        left: 50%;
        transform: translateX(-50%);

        &::after {
          left: 50%;
        }
      }
    }
  }
}
