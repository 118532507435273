@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.BoardItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.6rem;
  padding: 0.8rem 1.2rem;
  cursor: pointer;

  &--active {
    background-color: @transparentLight2;
  }

  &__Info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    .Avatar {
      width: 4rem;
      height: 4rem;
    }
  }

  &__Actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .Visited {
      .commentBold();

      width: 2.6rem;
      height: 1.6rem;
      color: @normal;
    }

    .Joined {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 2.4rem;
      background-color: @grey6;

      &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: @grey3;
      }
    }

    .JoinedWithText {
      display: flex;
      align-items: center;
      background-color: @grey6;
      width: 7.9rem;
      height: 2.4rem;
      border-radius: 2.4rem;

      &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        padding: 0.2rem 0 0.2rem 0.2rem;
        color: @grey3;
      }

      &--text {
        .commentBold();

        display: flex;
        color: @grey3;
      }
    }
  }
}

.Details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0.8rem;

  &__Name {
    .commentBold();
    .truncateWithEllipsis();

    display: block;
    text-align: left;
    max-width: 14rem;
    color: @grey1;

    &--active {
      max-width: 9rem;
    }
  }

  &__Followers {
    .comment();
    .truncateWithEllipsis();

    display: block;
    text-align: left;
    width: 9rem;
    color: @grey4;
  }
}
