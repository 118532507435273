@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.GameContent {
  width: 100%;
  padding: 1.2rem 0.8rem 1.6rem;
  border-radius: 0.8rem;
  background-color: @grey8;

  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
  
  &:last-of-type {
    margin-bottom: 2rem;
  }

  @media @smallMobile {
    padding: 1.2rem 1.2rem 1.6rem;
  }
  
  &--default {
    padding-bottom: 0;
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media @desktop {
      margin-bottom: 0.8rem;
    }
    
    &--expand {
      padding-bottom: 1.2rem;
    }
  }
  
  &__Expand {
    padding-left: 0 !important; // need override native button styles
  }

  &__Row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
  }

  &__Text {
    .comment();

    color: @grey4;
  }

  &__Date {
    .comment();

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: @grey4;
  }

  &__SeeMore {
    .commentBold();

    color: @normal;
  }

  &__Content {
    display: flex;
    gap: 0.8rem;
  }

  &__Items {
    width: calc(50% - 0.4rem);
  }
}

.GameContentTeam {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__Avatar {
    position: relative;
  }

  &__Icon {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background-color: @grey8;

    img {
      width: 1.6rem;
      height: 1.6rem;
      object-fit: contain;
    }
  }

  &__Name {
    .h4();

    font-weight: 700;
    color: @white;
  }
}
