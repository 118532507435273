@import 'styles/variables.less';
@import 'styles/typography.less';

.StatsBox {
  border-radius: @borderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.8rem 0;
  border: solid 0.1rem @grey5;
  height: 9.8rem;
  overflow: hidden;
  
  &--clickable {
    cursor: pointer;
  }

  &__Content {
    .bodyBold();
    color: @white;
    
    &--total {
      color: @gold;
    }
    
    &--prediction-low {
      color: @alert;
    }
    
    &--prediction-high {
      color: @success;
    }
  }
  
  &__Title {
    .commentBold();
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    color: @grey2;
    
    &--total {
      color: @gold;
    }
  }
}
