@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.PlayerBoard {
  width: 100%;
  padding: 0.4rem;
  border-radius: 0.4rem;
  background-color: var(--player-board-primary-color);

  @media @smallMobile {
    padding: 0.4rem 0.8rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 0.8rem;
  }

  &--detail-theme {
    .PlayerBoard__Header {
      display: flex;
      gap: 0.8rem;
    }

    .PlayerBoard__Name {
      margin-right: auto;
    }

    .PlayerBoardPoint__Label {
      .comment();
    }
  }

  &__Header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.8rem;
    margin-bottom: 0.4rem;
  }

  &__Group {
    display: flex;
    gap: 0.8rem;
  }

  &__Avatar {
    grid-row: span 2;
  }

  &__Name {
    .commentBold();
    .truncateWithEllipsis();

    max-width: fit-content;
    color: @white;

    @media @desktop {
      margin-right: auto;
    }
  }

  &__Jersey,
  &__Position {
    .comment();

    color: @white;
    opacity: 0.5;
  }

  &__Content {
    display: flex;
    justify-content: space-between;
  }
}

.PlayerBoardPoint {
  display: flex;
  flex-direction: column;

  &__Value {
    .commentBold();

    color: @white;
  }

  &__Label {
    .comment();

    color: @white;
    opacity: 0.5;
  }
}
