@import 'styles/variables.less';

.IconButton {
  transition-property: color, background-color;
  transition-duration: @smallElementAppearTime;
  transition-timing-function: @animationEasing;
  
  &--fluid {
    width: 100%;
  }

  &--theme-primary,
  &--theme-secondary {
    padding: 0.4rem;
    border-radius: 0.4rem;
  }

  &--theme-primary {
    background-color: @grey6;
    color: @grey3;

    @media @desktop {
      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background: @transparentLight2;
      }
    }
    
    &:disabled {
      background: @transparentLight3;
    }
  }

  &--theme-tertiary {
    border-radius: 0.8rem;
    padding: 0.4rem;
    background-color: @grey8;
    color: @grey3;

    &:hover,
    &:active {
      color: @grey1;
    }
  }

  &--theme-secondary {
    background-color: transparent;
    color: @grey3;

    &:hover:not(:disabled),
    :active:not(:disabled) {
      background-color: @transparentLight2;
    }
  }

  &--theme-primary&--selected,
  &--theme-secondary&--selected,
  &--theme-transparent&--selected {
    color: @normal;
  }
  
  &--theme-secondary&--selected {
    background-color: @transparentLight2;
  }
  
  &--theme-alert&--selected {
    background-color: @lightAlert;
  }
  
  &--theme-alert,
  &--theme-alert-big {
    background-color: @alert;
    color: @white;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: @lightAlert;
    }
    
    &:disabled {
      background-color: @transparentLight3;
    }
  }

  &--theme-alert {
    padding: 0.4rem;
    border-radius: 50%;
  }

  &--theme-navigation,
  &--theme-alert-big {
    padding: 0.7rem;
    border-radius: 50%;
  }

  &--theme-navigation {
    background-color: @grey7;
    color: @grey1;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: @transparentLight2;
    }
    
    &:disabled {
      background-color: @transparentLight3;
    }
  }

  &--theme-navigation&--selected {
    background-color: @grey1;
    color: @black;
  }

  &--theme-attachment-action {
    padding: 0;
    background-color: @grey1;
    color: @black;
    border-radius: 50%;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: @white;
    }
    
    :disabled {
      background-color: @grey1;
    }
  }

  &--theme-transparent {
    padding: 0.4rem;
    border-radius: 0.4rem;
    background-color: transparent;
    color: @grey1;

    &:not(:disabled):hover,
    &:not(:disabled):active {
      color: @grey3;
    }
  }

  &--with-dot {
    position: relative;
  }

  &:disabled,
  &--pseudo-disabled {
    i {
      opacity: 0.3;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &--active {
    color: @normal;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      color: @normal;
    }
  }

  &--menu-active {
    &,
    &:hover,
    &:active {
      color: @grey1;
    }
  }

  &__Dot {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    content: '';
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    border: solid @dotBorderColor 0.3rem;

    &--red {
      background-color: @alert;
    }

    &--blue {
      background-color: @normal;
    }
  }
}
