@import 'styles/variables.less';
@import 'styles/mixins.less';


.Wrapper {
  &--processing {
    filter: blur(5px); // required for blur
  }
}

.Title {
  .h4();
  
  height: 4.8rem;
  border-bottom: 0.1rem solid @grey6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @grey1;
}

&:global(.is-keyboard-open) {
  .Wrapper {
    &--keyboard {
      margin-bottom: calc(@keyboardHeight - var(--cap-safe-area-bottom));
    }
  }
}

&:global(.is-keyboard-open.android-platform) {
  .Wrapper {
    &--keyboard {
      margin-bottom: @keyboardHeight;
    }
  }
}
