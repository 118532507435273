@import 'styles/variables';
@import 'styles/typography';
@import 'styles/mixins';

.CollaborationItemHeader {
  white-space: nowrap;
  padding-bottom: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 8.6rem;
  
  &--bookmark-theme {
    height: 5rem;
  }

  &__Wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.4rem;
    justify-content: center;
    padding-left: 0.8rem;
    
    @media @desktop {
      gap: 0.8rem;
    }
  }
}

.CollaborationItemHeaderInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 2.9rem);
  gap: 0.4rem;

  &__Top {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__Bottom {
    display: flex;
    align-items: center;
    padding-bottom: 0.4rem;
  }

  &__Username {
    .commentBold();
    .truncateWithEllipsis();

    max-width: 16rem;
    line-height: 2.4rem;
    flex-shrink: 0;
  }

  &__Date {
    .comment();

    color: @grey4;
  }
}
