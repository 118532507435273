@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.Poll {
  .comment();
  color: @grey4;
  display: flex;
  align-items: center;
  border: 0.1rem solid @grey6;
  border-radius: @borderRadius;
  position: relative;
  overflow: hidden;
  width: 100%;

  &:before {
    content: '';
    width: 0.4rem;
    background: @normal;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  &__Title {
    background-color: @grey7;
    padding: 0.8rem 1.2rem;
    flex: 0 0 auto;
  }

  &__Options {
    display: flex;
    margin: 0 auto;
    padding: 0;
    flex: 1 1 0;
  }

  &__Option {
    .truncateWithEllipsis();
    border-left: 0.1rem solid @grey6;
    padding: 0.8rem 1.2rem;
    list-style-position: inside;
    flex: 1 1 0;
    width: 2vw;

    & > div {
      .truncateWithEllipsis();

      span {
        .truncateWithEllipsis();
      }
    }
  }
}

.ShareModal {
  width: 48.4rem;
}
