@import 'styles/mixins.less';
@import 'styles/variables.less';

.TeamStatsTopPerformers {
  .hideScrollbar();

  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;

  & > :first-child {
    margin-left: 1.6rem;

    @media @desktop {
      margin-left: 0;
    }
  }

  & > :last-child {
    margin-right: 1.6rem;

    @media @desktop {
      margin-right: 0;
    }
  }
}
