@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.UserPublicAchievements {
  
  &__Filter {
    margin: 0.8rem 0 1.6rem 0;
    
    @media @desktop {
      margin: 2.4rem 0 2.4rem 0;
      max-width: 18rem;
    }
  }
}
