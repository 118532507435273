@import 'styles/variables.less';

.PostToolTip {
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  border: 0.1rem solid @grey5;
  background-color: @grey7;
  z-index: 2;
  position: absolute;
  right: 1rem;
}
