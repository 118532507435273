@import 'styles/typography.less';
@import 'styles/variables.less';

.HeaderMobile {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: @smallHorizontalSpacingMobile;
  padding: @safeAreaTop @smallHorizontalSpacingMobile 0 @smallHorizontalSpacingMobile;
  height: calc(@headerHeight + @safeAreaTop);
  background-color: @black;

  button {
    background-color: transparent;
  }

  &__LeftItem {
    display: flex;
    gap: 1.6rem;
  }

  &__TitleInner {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__Title {
    .bodyBold();

    align-self: center;
    color: @grey1;
  }
}

.CustomSection {
  display: flex;
  flex-direction: column;

  &__Title {
    .bodyBold();
  }

  &__Description {
    .comment();
    color: @grey3;
  }
}
