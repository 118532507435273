@import 'styles/variables.less';
@import 'styles/typography.less';

.Button {
  .button();
  position: relative;

  &--fluid {
    width: 100%;
  }

  border-radius: 0.8rem;
  border: none;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &--size-big {
    padding: 1.2rem 2.4rem;
    gap: 0.8rem;
  }

  &--size-small {
    padding: 0.4rem 1.6rem;
    gap: 0.8rem;
  }

  &--size-smallSecondary {
    .buttonSmallSecondary();

    padding: 0.6rem 0.8rem;
    gap: 0.4rem;
  }


  &:not(:disabled):active {
    box-shadow: 0 0 0 0.2rem @white inset;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &--primary:hover:not(:disabled) {
    opacity: 0.9;
  }

  &--primary&--size-big,
  &--primary&--size-small,
  &--primary&--size-smallSecondary {
    background: @normal;
    color: @white;
  }

  &--primary-alert {
    background: @alert;
    color: @white;

    &:hover:not(:disabled) {
      background: @lightAlert;
    }
  }

  &--primary-dark {
    background: @dark;
    color: @white;

    &:hover:not(:disabled) {
      opacity: 0.9;
    }
  }


  &--primary:disabled,
  &--primary-alert:disabled {
    background: @transparentLight3;
    color: @grey4;
  }

  &--secondary {
    background: transparent;
    border: 0.2rem solid @normal;
    color: @grey1;

    &:hover {
      background: @transparentLight2;
    }

    &:not(:disabled):active {
      border: 0.2rem solid @white;
      box-shadow: none;
    }
  }

  &--secondary&--size-smallSecondary {
    border: 0.1rem solid @normal;
    color: @grey3;

    &:not(:disabled):active {
      border: 0.1rem solid @white;
      box-shadow: none;
    }
  }

  &--secondary:disabled {
    border: 0.2rem solid @grey5;
    color: @grey5;
  }

  &--secondary&--size-smallSecondary:disabled {
    border: 0.1rem solid @grey5;
  }

  &--tertiary:not(:disabled) {
    background: @grey6;
    color: @grey2;

    &:hover {
      background: @grey5;
    }
  }
  
  &--tertiary2:not(:disabled) {
    .body();
    color: @white;
    border: 0.15rem solid @grey7;
    border-radius: 5.6rem;
    gap: 0.8rem;
    background-color: transparent;
    
    &:hover {
      background: @transparentLight2;
    }
    
    &:focus {
      border: 0.15rem solid @white;
    }
  }
  
  &--tertiary2:disabled {
    border: 0.15rem solid @grey7;
    pointer-events: none;
    color: @grey5;
    border-radius: 5.6rem;
    padding: 1.6rem 2rem;
    gap: 0.8rem;
  }

  &--tertiary&--size-small,
  &--tertiary&--size-smallSecondary {
    .comment();
  }

  &--tertiary&--size-small:not(:disabled) {
    color: @grey2;
  }

  &--tertiary&--size-smallSecondary:not(:disabled) {
    box-shadow: 0 0 0 0.1rem @grey5 inset;
    color: @grey3;
    background: @black;

    &:hover {
      background: @grey8;
    }

    &:active {
      box-shadow: 0 0 0 0.2rem @white inset;
    }
  }

  &--tertiary&--size-smallSecondary:disabled {
    background: @black;
  }

  &--tertiary:disabled {
    background: @grey8;
    color: @grey5;
  }

  &--text,
  &--text-secondary,
  &--text-alert {
    background: none;
  }

  &--text&--size-big {
    padding: 0.6rem;
    gap: 0.8rem;
  }
  
  &--text&--size-small,
  &--text&--size-smallSecondary,
  &--text-alert {
    .commentBold();
  }

  &--text-secondary {
    .comment();
  }

  &--text&--size-small,
  &--text&--size-smallSecondary,
  &--text-secondary,
  &--text-alert {
    padding: 0.6rem 0.8rem;
  }

  &--text&--size-big,
  &--text&--size-small,
  &--text-secondary {
    color: @normal;
  }

  &--text&--size-big,
  &--text&--size-small,
  &--text-secondary,
  &--text-alert {
    &:not(:disabled):hover {
      background: @transparentLight2;
    }

    &:disabled {
      color: @grey5;
    }
  }

  &--text&--size-smallSecondary {
    color: @grey3;

    &:not(:disabled):hover {
      color: @normal;
      background: @transparentLight2;
    }

    &:disabled {
      color: @grey5;
    }
  }

  &--text-alert {
    color: @alert;
  }

  &--tertiary:not(:disabled)&--selected {
    color: @normal;
  }

  &--selected {
    color: @normal;
  }
}
