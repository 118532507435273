@import 'styles/variables.less';
@import 'styles/mixins.less';

.AboutThePlayerSection {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin: 0 1.6rem;

  & > div {
    width: calc(50% - 0.6rem);

    &:nth-child(n + 3) {
      display: none;
    }
  }

  button {
    width: 100%;
    height: 3.2rem;
    border: 0.1rem solid @grey5;
  }

  &--extant {
    & > div:nth-child(n + 3) {
      display: flex;
    }
  }

  @media @desktop {
    gap: 1.6rem;
    margin: 0;

    & > div {
      width: calc(25% - 1.2rem);

      &:nth-child(n + 3) {
        display: flex;
      }
    }
  }
}

.RecentGamesSection {
  width: 100%;

  & > div {
    @media @desktop {
      padding: 0;
    }
  }
}

.BiographySection {
  .body();
  color: @grey1;
  text-wrap: balance;
  text-align: justify;
  padding: 0 1.6rem;
}

