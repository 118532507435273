@import 'styles/variables.less';
@import 'styles/typography.less';

.Item {
  height: 3.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__Value {
    .bodyBold();
    height: 2.2rem;
    color: @grey1;

    &--plus-minus-positive {
      color: @success;
    }

    &--plus-minus-negative {
      color: @alert;
    }
  }

  &__Label {
    .comment();
    height: 1.6rem;
    color: @grey3;
  }
}
