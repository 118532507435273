@import 'styles/variables.less';
@import 'styles/mixins.less';

.Tabs {
  .hideScrollbar();

  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  border-bottom: 0.1rem solid @transparentLight3;

  &--size-m {
    padding: 0 @smallHorizontalSpacingMobile;

    @media @desktop {
      padding: 0 @bigVerticalSpacingDesktop;
    }
  }

  &--size-s {
    padding: 0 @smallVerticalSpacingDesktop;
  }

  &--for-profile {
    padding: 0;
  }

  &__Menu {
    margin: 0;
    list-style: none;
    display: flex;

    &--full-width {
      width: 100%;

      &>li {
        flex: 1;
        text-align: center;
      }
    }

    &--for-player-profile {
      margin: 0 auto;
    }
  }

  &--size-m &__Menu {
    gap: 2.4rem;

    @media @desktop {
      gap: 3.2rem;
    }
  }

  &--size-s &__Menu {
    gap: 1.6rem;
  }

  &--freezed {
    pointer-events: none;
  }

  &__RightCorner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--for-game {
    gap: 2.4rem;

    @media @desktop {
      padding: 0;
    }
  }
}