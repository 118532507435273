@import 'styles/variables.less';
@import 'styles/typography.less';

.Wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .Content {
    display: grid;
    width: 100%;

    @media @desktop {
      border: solid 0.8rem @grey8;
      border-radius: @borderRadius;
      grid-template-columns: 30rem 30rem;
      align-items: center;
      width: auto;
    }

    &__Side {
      display: flex;
      flex-direction: column;
      padding: 0 5.6rem 5.6rem;

      @media @desktop {
        padding: 0 4.8rem 0 2.4rem;
      }

      button {
        margin-top: 3.2rem;

        @media @desktop {
          margin-top: 1.6rem;
          width: fit-content;
        }
      }
    }

    &Side {
      &__Heading {
        color: @grey3;
      }

      &__Message {
        .body();

        color: @grey3;
        margin-top: 0.8rem;

        @media @desktop {
          .comment();
        }
      }
    }

    img {
      justify-self: end;
      grid-row: 1;

      @media @desktop {
        justify-self: auto;
        grid-row: auto;
      }
    }
  }
}
