@import 'styles/mixins.less';
@import 'styles/variables.less';
@import 'styles/typography.less';

.Avatar {
  .avatar();
  color: @white;

  &__Image {
    object-fit: cover;
  }
  
  &--size-144 {
    .h4();
    
    width: 14.4rem;
    height: 14.4rem;
  }
  
  &--size-mega {
    .h4();

    width: 12.4rem;
    height: 12.4rem;
  }

  &--size-xxxl {
    .h4();

    width: 11.2rem;
    height: 11.2rem;
  }

  &--size-xxl {
    width: 7.2rem;
    height: 7.2rem;
  }

  &--size-xl {
    width: 6.4rem;
    height: 6.4rem;
  }

  &--size-l {
    .bodyBold();

    width: 4.8rem;
    height: 4.8rem;
  }

  &--size-m {
    .bodyBold();

    width: 4rem;
    height: 4rem;
  }

  &--size-s {
    .commentBold();

    width: 3.2rem;
    height: 3.2rem;
  }

  &--size-xs {
    .commentBold();

    width: 2.4rem;
    height: 2.4rem;
  }

  &--size-xxs {
    .commentBold();

    width: 1.6rem;
    height: 1.6rem;
  }

  &--size-20 {
    width: 2rem;
    height: 2rem;
  }

  &--size-80 {
    width: 8rem;
    height: 8rem;
  }

  &--theme-user {
    border-radius: 50%;
  }

  &--theme-team {
    &,
    & > img {
      border-radius: 0;
    }
  }

  &--without-background {
    background: none;
  }

  &__FontIcon,
  &__VectorIcon {
    position: absolute;
  }

  &__FontIcon--m,
  &__VectorIcon {
    right: (-1 * @iconSizeM / 3);
    bottom: (-1 * @iconSizeM / 3);
  }

  &__FontIcon--s {
    right: -0.2rem;
    bottom: -0.2rem;
    color: @grey1;
    background-color: @grey7;

    i {
      font-size: 1.4rem;
    }
  }

  &__FontIcon {
    border-radius: 50%;
  }

  &__FontIcon--m {
    padding: 0.2rem;
    background-color: @black;
    color: @grey3;
  }
  
  &__Letter {
    margin-top: -0.2rem;
  }
}
