@import 'styles/variables.less';

.IconBadge {
  display: flex;
  align-items: center;
  padding: 0.6rem 1.1rem;
  gap: 0.2rem;
  border-radius: 1.4rem;

  &--theme-default {
    background-color: @black;
    color: @white;
    opacity: 0.6;
  }

  &--clickable {
    cursor: pointer;
  }
}
