@import 'styles/variables.less';
@import 'styles/typography.less';
@import 'styles/mixins.less';

.SelectTeam {
  .hideScrollbar();

  padding: 0 2.4rem calc(2.4rem + @safeAreaBottom);
  max-height: 80vh;
  overflow-y: auto;

  &__List {
    background: @grey7;
    border-radius: 1.6rem;
  }

  &__Item {
    .body();

    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid @transparentLight3;
    color: @grey1;

    &:last-child {
      border-bottom: none;
    }

    &--active {
      .bodyBold();

      color: @white;
    }
  }
}
