@import 'styles/variables.less';
@import 'styles/typography.less';

.ScoreboardTableDetails {
  & + & {
    margin: 0.1rem 0 0;
  }

  &__Game {
    padding: 0.4rem 0.8rem 0.8rem 0.8rem;

    & > div:first-child {
      background-color: @grey7;
      border-radius: @borderRadius;
    }
  }
}
