@import 'styles/variables.less';
@import 'styles/typography.less';

.Slider {
  position: relative;
  display: flex;
  align-items: center;
  height: 4rem;
  margin-bottom: 0.8rem;
  overflow: visible;

  &__Input {
    width: 100%;
    appearance: none;
    outline: 0;
    height: 0.9rem;
    border-radius: 4rem;
    background: @grey5;
    cursor: pointer;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 2rem;
      height: 2rem;
      background: white;
      border-radius: 50%;
    }
  }

  &__Bubble {
    position: absolute;
    top: -4.05rem;
    width: max-content;
    transform: translateX(-50%);
  }

  &__Value {
    .bodyBold();

    position: relative;
    color: @grey8;
    padding: 0.8rem 1.2rem;
    background-color: @white;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);

    &::before {
      content: ' ';
      position: absolute;
      left: 48%;
      bottom: -0.8rem;
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 0.2rem;
      background-color: @white;
      transform: rotate(45deg) translateX(-50%);
    }
  }
}
